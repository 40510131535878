import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
    loading: null,
    profile: null,
    profileUpdate: null,
    changePasswordUpdate: null,
};

export const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

export const getProfile = (state, data) => {
    return updateObject(state, {
        profile: data
    })
}

export const profileUpdate = (state, data) => {
    return updateObject(state, {
        profileUpdate: data
    })
}

export const changePasswordUpdate = (state, data) => {
    return updateObject(state, {
        changePasswordUpdate: data
    })
}


const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.CHANGE_PASSWORD_UPDATE:
            return changePasswordUpdate(state, data);
        case actionTypes.PROFILE_UPDATE:
            return profileUpdate(state, data);
        case actionTypes.GET_PROFILE:
            return getProfile(state, data);
        case actionTypes.GET_PROFILE:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
