import React, { useEffect, useState } from 'react'
import { Button, Tab, Tabs } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../../components/Cards/Basic';
import ModalConfirm from '../../../../components/ModalConfirm';
import { setMenuActive } from '../../../../redux/actions';
import {
    deleteMenuDelete, getMenus, getStructureMenus, postStructureMenus, setDeleteMenuDelete, setGetMenu,
    setGetStructureMenus, setPostMenuCreate, setPostStructureMenus, setPutMenuUpdate
} from '../../../../redux/actions/web-menu';
import MenuForm from '../Form';
import Nestable from 'react-nestable';
import 'react-nestable/dist/styles/index.css';
import { AuthWrapper } from '../../../AuthWrapper';
import Progressbar from '../../../../components/Progressbar';
import PageHeading from '../../../../components/PageHeading';
import { useParams } from 'react-router';
import { capEachWord, notify } from '../../../../constants/utility';

const MainMenu = (props) => {
    const { name, text, path, title, parent } = props.data
    const { id } = useParams()
    const dispatch = useDispatch();
    const [tabKey, setTabKey] = useState(`${id}-menu`);
    const {
        menus,
        menuCreate,
        menuUpdate,
        menuDelete,
        structureMenus,
        structureMenuUpdate,
        loading
    } = useSelector(state => state.webMenu)

    const [data, setData] = useState([])
    const [menuStructure, setMenuStructure] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})
    const [modalData, setModalData] = useState({})


    useEffect(() => {
        if (menus) setData(menus.result)
    }, [menus])

    useEffect(() => {
        if (structureMenus) setMenuStructure(structureMenus.result)
    }, [structureMenus])

    useEffect(() => {
        const e = menuCreate || menuUpdate || menuDelete || structureMenuUpdate
        if (e) {
            notify(e.message, e.success ? 'success' : 'error')

            onHide()
            dispatch(getMenus(id))
            dispatch(getStructureMenus(id))
            setIsChange(false)
        }
        dispatch(setPostMenuCreate(null))
        dispatch(setPutMenuUpdate(null))
        dispatch(setDeleteMenuDelete(null))
        dispatch(setPostStructureMenus(null))
        return () => {
        }
    }, [menuCreate, menuUpdate, menuDelete, structureMenuUpdate])

    useEffect(() => {
        dispatch(getMenus(id))
        dispatch(getStructureMenus(id))
        dispatch(setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [])

    const onHide = () => {
        setModalShow(false)
        setModalShowConfirm(false)
        dispatch(setGetMenu(null))
    }

    const tambahMenu = () => {
        setModalData(null)
        setModalData({
            id: null,
            action_title: 'Tambah Menu',
            type: `${id}`,
            value: null
        })
        setModalShow(true)
    }

    const editMenu = (item) => {
        setModalData({
            id: item.id,
            action_title: 'Edit Menu',
            type: `${id}`,
            value: item,
            post_id: item.post_id
        })
        setModalShow(true)
    }

    const columns = [
        {
            dataField: 'id',
            text: 'No',
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            formatter: (val, row, index) => {
                return index + 1
            }
        },
        {
            dataField: 'name',
            text: 'Nama',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            }
        },
        {
            dataField: 'link',
            text: 'Link',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            }
        },
        {
            dataField: 'id',
            text: '',
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            formatter: (val, row, index) => {
                return (
                    <>
                        <button onClick={() => editMenu(row)} className="btn btn-warning btn-sm"><i className="fas fa-edit"></i></button> &nbsp;
                        <button onClick={() => onDelete(row)} className="btn btn-danger btn-sm"><i className="fas fa-trash"></i></button>
                    </>
                )
            }
        },
    ]

    const onDelete = (data) => {

        setModalShowConfirm(true)
        setModalOption({
            title: 'Konfirmasi hapus',
            content: (
                <div>
                    <p>Anda yakin akan menghapus <strong>{data?.name}</strong> ?</p>

                    <hr mb={2} mt={2} />
                    <Button className='float-left' size='sm' variant={`outline-secondary`} onClick={() => setModalShowConfirm(false)}>Batal</Button>
                    <Button className='float-right' variant={`danger`} size='sm'
                        onClick={() => dispatch(deleteMenuDelete(data.id))}>Hapus</Button>
                </div>
            ),
            color: 'danger',
            size: 'md',
            data: data,
        })

    }

    const renderItem = ({ item }) => item.text;

    const saveStructure = () => {
        if (!isChange) return;
        console.log('acc', JSON.stringify(menuStructure))
        dispatch(postStructureMenus({ structures: menuStructure }))
    }

    const onNestableChange = (data) => {
        setMenuStructure(data.items)
        setIsChange(true)
    }

    const [isChange, setIsChange] = useState(false)

    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} />
            <CardBasic title={`${capEachWord(id)} Menu`} >
                <Tabs
                    id="controlled-tab-example"
                    activeKey={tabKey}
                    onSelect={(k) => setTabKey(k)}
                    className="mb-3"
                >
                    <Tab eventKey={`${id}-structure`} title="Struktur Menu" className="pb-4">
                        <button disabled={!isChange} onClick={() => saveStructure()} className="btn btn-primary btn-md mb-2">Simpan Struktur</button>
                        <Nestable
                            onChange={onNestableChange}
                            items={menuStructure}
                            renderItem={renderItem}
                        />
                    </Tab>
                    <Tab eventKey={`${id}-menu`} title="Menu">
                        <button onClick={() => tambahMenu()} className="btn btn-primary btn-md mb-2">Tambah Menu</button>
                        <BootstrapTable
                            className="bt-table"
                            bootstrap4
                            hover
                            bordered
                            striped
                            condensed
                            noDataIndication={indication}
                            // bordered={false}
                            keyField="id"
                            defaultSorted={defaultSorted}
                            data={data}
                            columns={columns}
                        // onTableChange={onTableChange}
                        />
                        {modalShow ?
                            <MenuForm
                                show={modalShow}
                                data={modalData}
                                onHide={() => onHide()}
                            />
                            : ""}

                    </Tab>
                </Tabs>
            </CardBasic>
            <ModalConfirm
                show={modalShowConfirm}
                modalOption={modalOption}
                onHide={() => setModalShowConfirm(false)}
            />
        </div>
    )
}

const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
}];


const indication = () => {
    return 'No data'
}
export default AuthWrapper(MainMenu)
