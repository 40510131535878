import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../components/Cards/Basic'
import Progressbar from '../../../../components/Progressbar'
import SiaHeader from '../../../../components/SiaHeader'
import TextInput from '../../../../components/TextInput'
import { notify } from '../../../../constants/utility'
import { setMenuActive } from '../../../../redux/actions'
import { getRoleOne, postCreateRole, putUpdateRole, setGetRole, setRoleCreate, setRoleUpdate, getAccessAll } from '../../../../redux/actions/master/role'
import { AuthWrapper } from '../../../AuthWrapper'

const RoleMasterForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, role, roleCreate, roleUpdate, accesses } = useSelector((state) => state.role);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: parent || name, subMenuActive: name }))
        dispatch(setGetRole(null))
        dispatch(getAccessAll())
    }, [])

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            dispatch(getRoleOne(id))
        }
    }, [id])

    const [accessOption, setAccessOption] = useState([])
    useEffect(() => {
        // setValue('1', true)
        setValue('name', role?.result?.name || '')

        const access_owned = role?.result?.role_accesses?.map(item => item.access.name, [])
        if (accesses?.result) {
            const accs = accesses.result.reduce((acc, item) => {
                if (access_owned?.includes(item.name)) {
                    item['checked'] = true
                    setValue(`access.${item.name}`, true)
                } else {
                    setValue(`access.${item.name}`, false)
                }
                // if parent_id is 0, add to acc.
                if (item.parent_id === 0) {
                    return [
                        ...acc,
                        item,
                    ];
                }

                // else find the parent.
                const parentIndex = acc.findIndex(parent => parent.id === item.parent_id);
                const parent = acc[parentIndex];
                const children = parent.children ?? [];

                // update the array item at index.
                return Object.assign(
                    [],
                    acc,
                    {
                        [parentIndex]: {
                            ...parent,
                            children: [...children, item], // add the item as a child.
                        },
                    },
                );
            }, []);
            setAccessOption(accs)
        }

    }, [role, accesses])

    useEffect(() => {
        console.log(accessOption)
    }, [accessOption])
    // useEffect(() => {
    //     effect
    //     return () => {
    //         cleanup
    //     }
    // }, [input])

    useEffect(() => {
        if (roleCreate) {
            notify(roleCreate.message, roleCreate.success ? 'success' : 'error')
            navigate("/user-roles/edit/" + roleCreate.result?.id, { replace: true })
        }

        return () => {
            dispatch(setRoleCreate(null))
        }
    }, [roleCreate])

    useEffect(() => {
        if (roleUpdate) {
            notify(roleUpdate.message, roleUpdate.success ? 'success' : 'error')

            dispatch(getRoleOne(id))
            dispatch(setRoleUpdate(null))
        }

    }, [roleUpdate])

    const submitForm = (data) => {
        // data.access = data.access.map(item => {if()})
        let accesses = []
        for (var x in data.access) {
            if (data.access[x] === true) accesses.push(x)
        }
        let newData = {
            name: data.name,
            accesses: accesses
        }
        console.log(newData)
        if (loading === true) return;
        if (id) dispatch(putUpdateRole(id, newData))
        else dispatch(postCreateRole(newData))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama Role"
                                    type="text"
                                    name="name"
                                    placeholder="Nama Role"
                                    register={register}
                                    errors={formState.errors}
                                />

                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-2">
                                        <label className="font-weight-bold">Akses</label>
                                    </div>
                                    <div className="col-md-10">
                                        {accessOption?.map((item, key) => {
                                            return (
                                                <div className="" key={key}>
                                                    <div className="d-block mr-3">
                                                        <TextInput
                                                            // defaultValue={watch(`access.${item.name}`)}
                                                            layout="vertical"
                                                            type="checkbox"
                                                            name={`access.${item.name}`}
                                                            placeholder={item.label}
                                                            register={register}
                                                            errors={formState.errors}
                                                        />
                                                    </div>
                                                    <div className="pl-4">
                                                        {item.children?.map((item, key) => {
                                                            return (
                                                                <div className="d-inline-flex mr-3">
                                                                    <TextInput
                                                                        key={key}
                                                                        // defaultValue={watch(`access.${item.name}`)}
                                                                        layout="vertical"
                                                                        type="checkbox"
                                                                        name={`access.${item.name}`}
                                                                        placeholder={item.label}
                                                                        register={register}
                                                                        errors={formState.errors}
                                                                    />
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(RoleMasterForm)
