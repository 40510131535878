import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetStudentClass = (payload) => {
    return {
        type: actionTypes.GET_STUDENT_CLASS,
        data: payload,
    };
};

export const getStudentClassOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/master/student/` + data);
        dispatch(setGetStudentClass(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetStudentClasss = (payload) => {
    return {
        type: actionTypes.GET_STUDENT_CLASSES,
        data: payload,
    };
};

export const getStudentClassAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/activity/student-class`, { params });
        dispatch(setGetStudentClasss(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setStudentClassCreate = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_CREATE,
        data: payload,
    };
}

export const postCreateStudentClass = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/student-class`, data);
        dispatch(setOnloading(null))
        dispatch(setStudentClassCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setStudentClassUpdate = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_UPDATE,
        data: payload,
    };
}

export const putUpdateStudentClass = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/master/student/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setStudentClassUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setStudentClassDelete = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_DELETE,
        data: payload,
    };
}

export const deleteStudentClass = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/activity/student-class/${id}`);
        dispatch(setOnloading(null))
        dispatch(setStudentClassDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetStudentClassOptForm = (payload) => {
    return {
        type: actionTypes.GET_STUDENT_CLASS_OPTFORM,
        data: payload,
    };
};

export const getStudentClassOptForm = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/sia/activity/student-class-opt`);
        dispatch(setGetStudentClassOptForm(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setGetStudentClassByClassYears = (payload) => {
    return {
        type: actionTypes.GET_STUDENT_CLASS_BY_CLASS_YEAR,
        data: payload,
    };
};

export const getStudentClassByClassYears = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        console.log("awowo xx", data)
        const params = {
            classroom_id: data.classroom_id,
            eduyear_id: data.eduyear_id,
        }
        const result = await http.get(`/sia/activity/student-class/by-class-year`, { params });
        dispatch(setGetStudentClassByClassYears(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setPostStudentClassStatusUpdate = (payload) => {
    return {
        type: actionTypes.STUDENT_CLASS_STATUS_UPDATE,
        data: payload,
    };
}

export const postStudentClassStatusUpdate = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/student-class/status`, data);
        dispatch(setOnloading(null))
        dispatch(setPostStudentClassStatusUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}