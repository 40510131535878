import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetFromOpt = (payload) => {
    return {
        type: actionTypes.FORM_OPT,
        data: payload,
    };
};

export const getFormOpt = () => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/graduation/form-opt`);
        dispatch(setGetFromOpt(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetGraduation = (payload) => {
    return {
        type: actionTypes.GET_GRADUATION,
        data: payload,
    };
};

export const getGraduation = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/activity/graduation/` + data);
        dispatch(setGetGraduation(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetGraduations = (payload) => {
    return {
        type: actionTypes.GET_GRADUATIONS,
        data: payload,
    };
};

export const getGraduations = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/graduation`, data);
        dispatch(setGetGraduations(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPutGraduation = (payload) => {
    return {
        type: actionTypes.GRADUATION_UPDATE,
        data: payload,
    };
};

export const putGraduation = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/activity/graduation`, data);
        dispatch(setPutGraduation(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setDeleteGraduation = (payload) => {
    return {
        type: actionTypes.GET_GRADUATIONS,
        data: payload,
    };
};

export const deleteGraduation = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/activity/graduation/${id}`);
        dispatch(setDeleteGraduation(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostBulkGraduationUpload = (payload) => {
    return {
        type: actionTypes.BULK_GRADUATION_UPLOAD,
        data: payload,
    };
};

export const postBulkGraduationUpload = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/activity/graduation/bulk-upload`, data);
        dispatch(setPostBulkGraduationUpload(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
