import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import CardBasic from '../../../components/Cards/Basic'
import PageHeading from '../../../components/PageHeading'
import Progressbar from '../../../components/Progressbar'
import TextInput from '../../../components/TextInput'
import { capEachWord, notify } from '../../../constants/utility'
import { getGeneral, postGeneralUpdate, setPostGeneralUpdate } from '../../../redux/actions/general'
import { AuthWrapper } from '../../AuthWrapper'
import * as actions from '../../../redux/actions';

const GeneralSetting = (props) => {
    const { name, text, path, title, parent } = props.data
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [dispatch])


    const { general, loading, generalUpdate } = useSelector(state => state.general)

    useEffect(() => {
        dispatch(getGeneral())
    }, [])

    useEffect(() => {
        if (generalUpdate) {
            notify(generalUpdate.message, generalUpdate.success ? 'success' : 'error')
        }
        return () => {
            setPostGeneralUpdate(null)
        }
    }, [generalUpdate])

    const handleFormSubmit = (data) => {
        let array = []
        for (var key in data) {
            array.push({ name: key, value: data[key] })
        }
        const dataPost = { 'generals': array }
        dispatch(postGeneralUpdate(dataPost))
    }


    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} />
            <CardBasic>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                    <div className="row">
                        <div className="col-md-10">
                            {
                                general?.result.map(item => {
                                    return (
                                        <TextInput
                                            // validations={{ required: true }}
                                            defaultValue={item.value}
                                            layout="horizontal"
                                            label={capEachWord(item.name)}
                                            type="text"
                                            name={item.name}
                                            placeholder={capEachWord(item.name)}
                                            register={register}
                                            errors={formState.errors}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <hr />
                    <button type="submit" className="btn btn-md btn-primary-f">Simpan</button>
                </form>
            </CardBasic>
        </div>
    )
}

export default AuthWrapper(GeneralSetting)
