import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
    loading: null,
    uploadImage: null,
    slideCreate: null,
    slideUpdate: null,
    slideDelete: null,
    slide: null,
    slides: null,
    slideOrdering: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

const slideUploadImage = (state, data) => {
    return updateObject(state, {
        uploadImage: data,
    });
}

const slideCreate = (state, data) => {
    return updateObject(state, {
        slideCreate: data,
    });
}

const getPost = (state, data) => {
    return updateObject(state, {
        slide: data,
    });
}

const getPosts = (state, data) => {
    return updateObject(state, {
        slides: data,
    });
}

const slideUpdate = (state, data) => {
    return updateObject(state, {
        slideUpdate: data,
    });
}

const slideDelete = (state, data) => {
    return updateObject(state, {
        slideDelete: data,
    });
}

const slideOrdering = (state, data) => {
    return updateObject(state, {
        slideOrdering: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.SLIDE_ORDERING:
            return slideOrdering(state, data);
        case actionTypes.SLIDE_DELETE:
            return slideDelete(state, data);
        case actionTypes.SLIDE_UPDATE:
            return slideUpdate(state, data);
        case actionTypes.GET_SLIDES:
            return getPosts(state, data);
        case actionTypes.GET_SLIDE:
            return getPost(state, data);
        case actionTypes.SLIDE_CREATE:
            return slideCreate(state, data);
        case actionTypes.UPLOAD_IMAGE:
            return slideUploadImage(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
