import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
    loading: null,
    uploadImage: null,
    postCreate: null,
    postUpdate: null,
    postDelete: null,
    post: null,
    posts: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

const postUploadImage = (state, data) => {
    return updateObject(state, {
        uploadImage: data,
    });
}

const postCreate = (state, data) => {
    return updateObject(state, {
        postCreate: data,
    });
}

const getPost = (state, data) => {
    return updateObject(state, {
        post: data,
    });
}

const getPosts = (state, data) => {
    return updateObject(state, {
        posts: data,
    });
}

const postUpdate = (state, data) => {
    return updateObject(state, {
        postUpdate: data,
    });
}

const postDelete = (state, data) => {
    return updateObject(state, {
        postDelete: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.POST_DELETE:
            return postDelete(state, data);
        case actionTypes.POST_UPDATE:
            return postUpdate(state, data);
        case actionTypes.GET_POSTS:
            return getPosts(state, data);
        case actionTypes.GET_POST:
            return getPost(state, data);
        case actionTypes.POST_CREATE:
            return postCreate(state, data);
        case actionTypes.UPLOAD_IMAGE:
            return postUploadImage(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
