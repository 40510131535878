import * as actionTypes from "../../../constants/actionTypes";
import { LOCAL_USER_KEY } from "../../../constants/keys";
import {
  getLocalStorage,
  removeLocalStorage,
  storeLocalStorage,
} from "../../../services/localServices";
import { authLogin } from "../api";

import * as actions from "../../actions";
import { Navigate } from "react-router";
import { notify } from "../../../constants/utility";

const loginStart = () => {
  return {
    type: actionTypes.LOGIN_START,
  };
};

const loginSuccess = (data) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    data: data,
  };
};

const loginFail = (error) => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error: error,
  };
};

const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

const setUserData = (data) => {
  return {
    type: actionTypes.AUTH_USER_DATA,
    data,
  };
};

export const persistAuth = () => async (dispatch) => {
  try {
    const userData = JSON.parse(getLocalStorage(LOCAL_USER_KEY));
    dispatch(setUserData(userData));
  } catch (err) {
    //
  }
};

export const login = (body) => async (dispatch) => {
  dispatch(loginStart());

  try {
    const { result, success, message } = await authLogin(body);

    if (!success) {
      dispatch(
        notify(message, 'error')
      );
      throw new Error(message);
    }

    // Save access token
    storeLocalStorage(LOCAL_USER_KEY, JSON.stringify(result));

    // Login success
    dispatch(loginSuccess(result));
    notify(message, 'success')

    return window.location = "/";
    // <Navigate to="/" />
  } catch (err) {
    // Login error!
    dispatch(loginFail({ message: err.message }));
  }
};

export const logout = () => async (dispatch) => {
  removeLocalStorage(LOCAL_USER_KEY);
  dispatch(logoutSuccess());
};
