import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
    loading: null,
    uploadImage: null,
    galleryCreate: null,
    galleryUpdate: null,
    galleryDelete: null,
    gallery: null,
    galleries: null,
    albumCreate: null,
    albumUpdate: null,
    albumDelete: null,
    album: null,
    albums: null,
    albumGallery: null,
    albumGalleries: null,

};

const loading = (state, data) => { return updateObject(state, { loading: data }) }
const galleryUploadImage = (state, data) => { return updateObject(state, { uploadImage: data }); }

const galleryCreate = (state, data) => { return updateObject(state, { galleryCreate: data }); }
const galleryUpdate = (state, data) => { return updateObject(state, { galleryUpdate: data }); }
const galleryDelete = (state, data) => { return updateObject(state, { galleryDelete: data }); }
const gallery = (state, data) => { return updateObject(state, { gallery: data }); }
const galleries = (state, data) => { return updateObject(state, { galleries: data }); }

const albumCreate = (state, data) => { return updateObject(state, { albumCreate: data }); }
const albumUpdate = (state, data) => { return updateObject(state, { albumUpdate: data }); }
const albumDelete = (state, data) => { return updateObject(state, { albumDelete: data }); }
const album = (state, data) => { return updateObject(state, { album: data }); }
const albums = (state, data) => { return updateObject(state, { albums: data }); }

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {

        case actionTypes.GALLERY_DELETE:
            return galleryDelete(state, data);
        case actionTypes.GALLERY_UPDATE:
            return galleryUpdate(state, data);
        case actionTypes.GALLERIES:
            return galleries(state, data);
        case actionTypes.GALLERY:
            return gallery(state, data);
        case actionTypes.GALLERY_CREATE:
            return galleryCreate(state, data);

        case actionTypes.ALBUM_DELETE:
            return albumDelete(state, data);
        case actionTypes.ALBUM_UPDATE:
            return albumUpdate(state, data);
        case actionTypes.ALBUMS:
            return albums(state, data);
        case actionTypes.ALBUM:
            return album(state, data);
        case actionTypes.ALBUM_CREATE:
            return albumCreate(state, data);

        case actionTypes.UPLOAD_IMAGE:
            return galleryUploadImage(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
