import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    oneStudentClassLessons: null,

    studentClassLesson: null,
    studentClassLessons: null,
    studentClassLessonStudents: null,

    studentClassLessonTasks: null,
    studentClassLessonTaskSubmit: null,
    studentClassLessonTaskSubmitCreate: null,
    studentClassLessonTaskSubmitUpdate: null,

    studentClassLessonMaterials: null,
    studentClassLessonAttachmentUpload: null,
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

const getOneStudentClassLessons = (state, data) => {
    return updateObject(state, {
        oneStudentClassLessons: data,
    });
}

const getStudentClassLesson = (state, data) => {
    return updateObject(state, {
        studentClassLesson: data,
    });
}

const getStudentClassLessons = (state, data) => {
    return updateObject(state, {
        studentClassLessons: data,
    });
}

const getStudentClassLessonStudents = (state, data) => {
    return updateObject(state, {
        studentClassLessonStudents: data,
    });
}

// task
const getStudentClassLessonTasks = (state, data) => {
    return updateObject(state, {
        studentClassLessonTasks: data,
    });
}

const getStudentClassLessonTaskSubmitCreate = (state, data) => {
    return updateObject(state, {
        studentClassLessonTaskSubmitCreate: data,
    });
}

const getStudentClassLessonTaskSubmitUpdate = (state, data) => {
    return updateObject(state, {
        studentClassLessonTaskSubmitUpdate: data,
    });
}

const getStudentClassLessonTaskSubmit = (state, data) => {
    return updateObject(state, {
        studentClassLessonTaskSubmit: data,
    });
}

// //task

const getStudentClassLessonMaterials = (state, data) => {
    return updateObject(state, {
        studentClassLessonMaterials: data,
    });
}

const studentClassLessonAttachmentUpload = (state, data) => {
    return updateObject(state, {
        studentClassLessonAttachmentUpload: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.STUDENT_CLASS_LESSON_ATTACHMENT_UPLOAD:
            return studentClassLessonAttachmentUpload(state, data);
        case actionTypes.STUDENT_CLASS_LESSON_MATERIALS:
            return getStudentClassLessonMaterials(state, data);

        case actionTypes.STUDENT_CLASS_LESSON_TASKS:
            return getStudentClassLessonTasks(state, data);
        case actionTypes.STUDENT_CLASS_LESSON_TASK_SUBMIT:
            return getStudentClassLessonTaskSubmit(state, data);
        case actionTypes.STUDENT_CLASS_LESSON_TASK_SUBMIT_CREATE:
            return getStudentClassLessonTaskSubmitCreate(state, data);
        case actionTypes.STUDENT_CLASS_LESSON_TASK_SUBMIT_UPDATE:
            return getStudentClassLessonTaskSubmitUpdate(state, data);

        case actionTypes.STUDENT_CLASS_LESSON_STUDENTS:
            return getStudentClassLessonStudents(state, data);
        case actionTypes.ONE_STUDENT_CLASS_LESSONS:
            return getOneStudentClassLessons(state, data);
        case actionTypes.STUDENT_CLASS_LESSONS:
            return getStudentClassLessons(state, data);
        case actionTypes.STUDENT_CLASS_LESSON:
            return getStudentClassLesson(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;