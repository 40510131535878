import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    lessonClassCreate: null,
    lessonClassUpdate: null,
    lessonClassDelete: null,
    lessonClass: null,
    lessonClasses: null,
    lessonClassOptForm: null,
    lessonClassByClassYear: null,
    lessonClassByClassYearCreate: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const lessonClassCreate = (state, data) => {
    return updateObject(state, {
        lessonClassCreate: data,
    });
}

const getLessonClass = (state, data) => {
    return updateObject(state, {
        lessonClass: data,
    });
}

const getLessonClasses = (state, data) => {
    return updateObject(state, {
        lessonClasses: data,
    });
}

const lessonClassUpdate = (state, data) => {
    return updateObject(state, {
        lessonClassUpdate: data,
    });
}

const lessonClassDelete = (state, data) => {
    return updateObject(state, {
        lessonClassDelete: data,
    });
}

const setGetLessonClassOptForm = (state, data) => {
    return updateObject(state, {
        lessonClassOptForm: data,
    });
}

const setLessonClassByClassYear = (state, data) => {
    return updateObject(state, {
        lessonClassByClassYear: data,
    });
}

const setLessonClassByClassYearCreate = (state, data) => {
    return updateObject(state, {
        lessonClassByClassYearCreate: data,
    });
}



const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.POST_LESSON_CLASS_BY_CLASS_YEAR:
            return setLessonClassByClassYearCreate(state, data);
        case actionTypes.GET_LESSON_CLASS_BY_CLASS_YEAR:
            return setLessonClassByClassYear(state, data);
        case actionTypes.GET_LESSON_CLASS_OPTFORM:
            return setGetLessonClassOptForm(state, data);
        case actionTypes.LESSON_CLASS_DELETE:
            return lessonClassDelete(state, data);
        case actionTypes.LESSON_CLASS_UPDATE:
            return lessonClassUpdate(state, data);
        case actionTypes.GET_LESSON_CLASSES:
            return getLessonClasses(state, data);
        case actionTypes.GET_LESSON_CLASS:
            return getLessonClass(state, data);
        case actionTypes.LESSON_CLASS_CREATE:
            return lessonClassCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
