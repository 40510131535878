import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from '../../../AuthWrapper';
import * as actions from '../../../../redux/actions';
import PostForm from '../../../../components/PostForm';
import PageHeading from '../../../../components/PageHeading';
import CardBasic from '../../../../components/Cards/Basic';
import { getPostOne, postCreatePost, putUpdatePost, setGetPost, setPostCreate, setPostUpdate } from '../../../../redux/actions/post';
// import { ProgressBar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import Progressbar from '../../../../components/Progressbar';
import { getAlbum, setAlbumCreate, setGetAlbum } from '../../../../redux/actions/gallery';
import { useForm } from 'react-hook-form';
import TextInput from '../../../../components/TextInput';
import { image_path } from '../../../../constants/utility';

const AlbumGallery = (props) => {
    const { name, label, path, title, parent } = props.data
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [dispatch])

    const { id } = useParams()
    const { album, loading, albumCreate, albumUpdate, albumDelete } = useSelector(state => state.gallery)

    const [initValue, setInitValue] = useState({})
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const handleSubmitForm = (data) => {
        console.log('acc', data)
        // if (loading === true) return;
        // if (!id) dispatch(postCreatePost(data))
        // else dispatch(putUpdatePost(id, data))
    }

    useEffect(() => {
        setValue('title', album?.result?.title)
        setValue('image', album?.result?.image || '')
        setValue('description', album?.result?.description || '')
    }, [album])

    useEffect(() => {
        if (id) {
            dispatch(getAlbum(id))
            dispatch(setAlbumCreate(null))
        }
        return () => {
            dispatch(setGetAlbum(null))
        }
    }, [id])


    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} />
            <CardBasic>
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <h4 className="border-bottom pb-1 mb-2">Data Album</h4>
                    <div className="row">
                        <div className="col-md-4">
                            <TextInput
                                layout="vertical"
                                label="Cover Album"
                                type="file"
                                name="upload"
                                register={register}
                                errors={formState.errors}
                            />

                            <TextInput
                                hidden
                                layout="vertical"
                                label="ID Video Youtube"
                                type="text"
                                name="image"
                                register={register}
                                errors={formState.errors}
                            />
                            {watch('image') ?
                                <img src={image_path + watch('image')} className="img-fluid img-thumbnail" />
                                : ''}
                        </div>
                        <div className="col-md-8">
                            <TextInput
                                validations={{ required: true }}
                                layout="vertical"
                                label="Judul"
                                type="text"
                                name="title"
                                placeholder="Judul"
                                register={register}
                                errors={formState.errors}
                            />

                            <TextInput
                                layout="vertical"
                                label="description"
                                type="textarea"
                                name="description"
                                placeholder="Keterangan"
                                register={register}
                                errors={formState.errors}
                            />
                        </div>
                    </div>
                    <div className="border-bottom pb-2 mb-2 mt-4 d-flex justify-content-between">
                        <h4>Data gallery gambar/vidio</h4>
                        <button type="button" className="btn btn-primary btn-sm">Tambah</button>
                    </div>
                    <div className="d-flex">
                        {
                            album?.result?.galleries.map((item, key) => {
                                return (
                                    <div className="card card-item-gall" style={{ width: "140px" }}>
                                        <img className="card-img-top" src={`${image_path}/${item.gallery.image}`} alt="Card image cap" />
                                        <div className="card-body p-1">
                                            {item.gallery.title}
                                            <div className="row-delete">
                                                <button type="button"
                                                    className="btn btn-block btn-sm btn-danger"><i className="fas fa-times"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </form>
            </CardBasic>
            {/* <PostForm
                loading={loading}
                initValue={initValue}
                handleSubmitForm={handleSubmitForm}
                fieldOptions={fieldOptions} /> */}
        </div>
    )
}

export default AuthWrapper(AlbumGallery)
