import React from "react";
import Profile from "./pages/Account/Profile";
import Dashboard from "./pages/Dashboard";
import ChangePassword from "./pages/Account/ChangePassword";
import * as actions from "./redux/actions";
import ArticleForm from "./pages/Contents/Articles/Form";
import Articles from "./pages/Contents/Articles";
import Pages from "./pages/Contents/Pages";
import PageForm from "./pages/Contents/Pages/Form";
import News from "./pages/Contents/News";
import NewsForm from "./pages/Contents/News/Form";
import Agendas from "./pages/Contents/Agendas";
import AgendasForm from "./pages/Contents/Agendas/Form";
import Preface from "./pages/Contents/Preface";
import PrefaceForm from "./pages/Contents/Preface/Form";
import Slide from "./pages/Contents/Slides";
import Gallery from "./pages/Contents/Gallery";
import AlbumGallery from "./pages/Contents/Gallery/Album-gallery";
import GeneralSetting from "./pages/Setting/General";
import WebMenu from "./pages/Setting/Menu";
import MainMenu from "./pages/Setting/Menu/MainMenu";
import Sia from "./pages/Sia";
import LessonMaster from "./pages/Sia/Master/Lesson";
import LessonMasterForm from "./pages/Sia/Master/Lesson/From";
import ClassroomMaster from "./pages/Sia/Master/Classroom";
import ClassroomMasterForm from "./pages/Sia/Master/Classroom/From";
import EduyearMaster from "./pages/Sia/Master/Eduyear";
import EduyearMasterForm from "./pages/Sia/Master/Eduyear/From";
import TeacherMaster from "./pages/Sia/Master/Teacher";
import TeacherMasterForm from "./pages/Sia/Master/Teacher/From";
import StudentMaster from "./pages/Sia/Master/Student";
import StudentMasterForm from "./pages/Sia/Master/Student/From";
import SiaSettingForm from "./pages/Sia/Master/Setting";
import LessonClass from "./pages/Sia/Activity/Lesson-class";
import LessonClassForm from "./pages/Sia/Activity/Lesson-class/Form";
import StudentClassActivity from "./pages/Sia/Activity/Student-class";
import StudentClassForm from "./pages/Sia/Activity/Student-class/Form";
import LessonClassStudent from "./pages/Sia/Activity/Lesson-class-student";
import LessonClassStudentForm from "./pages/Sia/Activity/Lesson-class-student/Form";
import RaporActivity from "./pages/Sia/Activity/Rapor";
import GraduationActivity from "./pages/Sia/Activity/Graduation";

import UserMaster from "./pages/Master/User";
import UserMasterForm from "./pages/Master/User/From";
import RoleMaster from "./pages/Master/Role";
import RoleMasterForm from "./pages/Master/Role/From";

export const menus = [
  {
    name: "dashboard",
    label: "Dashbord",
    title: "Dashboard",
    path: "/",
    icon: "fa-tachometer-alt",
    access: ['dashboard'],
    component: (data) => <Dashboard data={data} />,
  },

  {
    name: "profile",
    label: "profile",
    path: "/account/profile",
    title: "Profil User",
    hidden: true,
    access: ['manage-profile'],
    component: (data) => <Profile data={data} />,
  },
  {
    name: "change-password",
    label: "Ubah Password",
    path: "/account/change-password",
    title: "Ubah Password",
    hidden: true,
    access: ['manage-profile'],
    component: (data) => <ChangePassword data={data} />,
  },

  {
    name: "contents",
    label: "Konten",
    path: "/contents",
    icon: "fa-exchange-alt",
    access: ['manage-content'],
    subs: [
      {
        name: "pages",
        label: "Halaman",
        path: "/pages",
        title: "Semua Halaman",
        parent: "contents",
        access: ['manage-content'],
        component: (data) => <Pages data={data} />,
      },
      {
        name: "pages",
        label: "Tambah Halaman",
        path: "/pages/new",
        title: "Tambah Halaman Baru",
        parent: "contents",
        hidden: true,
        access: ['add-content'],
        component: (data) => <PageForm data={data} />,
      },
      {
        name: "pages",
        label: "Edit Halaman",
        path: "/pages/edit/:id",
        title: "Edit Halaman",
        parent: "contents",
        hidden: true,
        access: ['manage-content', 'edit-content'],
        component: (data) => <PageForm data={data} />,
      },
      {
        name: "articles",
        label: "Tulisan",
        path: "/articles",
        title: "Semua Tulisan",
        parent: "contents",
        access: ['manage-content'],
        component: (data) => <Articles data={data} />,
      },
      {
        name: "articles",
        label: "Tambah Tulisan",
        path: "/articles/new",
        title: "Tambah Tulisan",
        parent: "contents",
        hidden: true,
        access: ['add-content'],
        component: (data) => <ArticleForm data={data} />,
      },
      {
        name: "articles",
        label: "Edit Tulisan",
        path: "/articles/edit/:id",
        title: "Edit Tulisan",
        parent: "contents",
        hidden: true,
        access: ['manage-content'],
        component: (data) => <ArticleForm data={data} />,
      },

      {
        name: "news",
        label: "Pengumuman/Informasi",
        path: "/news",
        title: "Semua Pengumuman/Informasi",
        parent: "contents",
        access: ['manage-content'],
        component: (data) => <News data={data} />,
      },
      {
        name: "news",
        label: "Tambah Pengumuman/Informasi",
        path: "/news/new",
        title: "Tambah Pengumuman/Informasi",
        parent: "contents",
        hidden: true,
        access: ['add-content'],
        component: (data) => <NewsForm data={data} />,
      },
      {
        name: "news",
        label: "Edit Pengumuman/Informasi",
        path: "/news/edit/:id",
        title: "Edit Pengumuman/Informasi",
        parent: "contents",
        hidden: true,
        access: ['manage-content'],
        component: (data) => <NewsForm data={data} />,
      },
      {
        name: "agendas",
        label: "Agenda",
        path: "/agendas",
        title: "Agenda Sekolah",
        parent: "contents",
        access: ['manage-content'],
        component: (data) => <Agendas data={data} />,
      },
      {
        name: "agendas",
        label: "Tambah Agenda",
        path: "/agendas/new",
        title: "Tambah Agenda Sekolah",
        parent: "contents",
        hidden: true,
        access: ['add-content'],
        component: (data) => <AgendasForm data={data} />,
      },
      {
        name: "agendas",
        label: "Edit Agenda",
        path: "/agendas/edit/:id",
        title: "Edit Agenda Sekolah",
        parent: "contents",
        hidden: true,
        access: ['manage-content'],
        component: (data) => <AgendasForm data={data} />,
      },

      {
        name: "prefaces",
        label: "Sambutan",
        path: "/prefaces",
        title: "Sambutan Kepala Sekolah",
        parent: "contents",
        access: ['manage-content'],
        component: (data) => <Preface data={data} />,
      },
      {
        name: "prefaces",
        label: "Tambah Sambutan",
        path: "/prefaces/new",
        title: "Tambah Sambutan Kepala Sekolah",
        parent: "contents",
        hidden: true,
        access: ['add-content'],
        component: (data) => <PrefaceForm data={data} />,
      },
      {
        name: "prefaces",
        label: "Edit Sambutan",
        path: "/prefaces/edit/:id",
        title: "Edit Sambutan Kepala Sekolah",
        parent: "contents",
        hidden: true,
        access: ['manage-content'],
        component: (data) => <PrefaceForm data={data} />,
      },
      {
        name: "slides",
        label: "Slides",
        path: "/slides",
        title: "Slides Halaman Utama",
        parent: "contents",
        access: ['manage-content'],
        component: (data) => <Slide data={data} />,
      },
      {
        name: "galleries",
        label: "Galeri Kegiatan",
        path: "/galleries",
        title: "Galeri Kegiatan",
        parent: "contents",
        access: ['manage-content'],
        component: (data) => <Gallery data={data} />,
      },
      {
        name: "album-gallery",
        label: "Album Gallery",
        path: "/album-gallery/:id",
        title: "Album Gallery",
        parent: "contents",
        hidden: true,
        access: ['manage-content'],
        component: (data) => <AlbumGallery data={data} />,
      },
    ],
  },
  {
    name: "settings",
    label: "Pengaturan",
    path: "/settings",
    icon: "fa-exchange-alt",
    access: ['general-setting', 'menu-setting'],
    subs: [
      {
        name: "general",
        label: "Umum",
        path: "/setting/general",
        title: "Umum",
        parent: "settings",
        access: ['general-setting'],
        component: (data) => <GeneralSetting data={data} />,
      },
      {
        name: "menu",
        label: "Menu",
        path: "/menus",
        title: "Menu",
        parent: "settings",
        access: ['menu-setting'],
        component: (data) => <WebMenu data={data} />,
      },
      {
        name: "menu",
        label: "Menu",
        path: "/menus/:id",
        title: "Menu",
        parent: "settings",
        hidden: true,
        access: ['menu-setting'],
        component: (data) => <MainMenu data={data} />,
      },

    ]
  },
  {
    name: "sia",
    label: "SIA",
    path: "/sia",
    icon: "fa-exchange-alt",
    access: ['sia-dashboard'],
  },

  {
    name: "sia",
    label: "Dashboard",
    path: "/sia",
    icon: "fa-exchange-alt",
    is_sia: true,
    access: ['sia-dashboard'],
    component: (data) => <Sia data={data} />
  },

  {
    name: "sia-master",
    label: "Master",
    path: "/sia/master",
    icon: "fa-exchange-alt",
    is_sia: true,
    access: ['sia-master'],
    component: (data) => <Dashboard data={data} />,
    subs: [
      {
        name: "sia-master-lesson",
        label: "Pelajaran",
        path: "/sia/master/lesson",
        title: "Master Pelajaran",
        parent: "sia-master",
        access: ['sia-master'],
        component: (data) => <LessonMaster data={data} />,
      },
      {
        name: "sia-master-lesson",
        label: "Pelajaran",
        path: "/sia/master/lesson/new",
        title: "Tambah Pelajaran",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <LessonMasterForm data={data} />,
      },
      {
        name: "sia-master-lesson",
        label: "Pelajaran",
        path: "/sia/master/lesson/edit/:id",
        title: "Edit Pelajaran",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <LessonMasterForm data={data} />,
      },
      //classroom
      {
        name: "sia-master-classroom",
        label: "Kelas",
        path: "/sia/master/classroom",
        title: "Master Kelas",
        parent: "sia-master",
        access: ['sia-master'],
        component: (data) => <ClassroomMaster data={data} />,
      },
      {
        name: "sia-master-classroom",
        label: "Kelas",
        path: "/sia/master/classroom/new",
        title: "Tambah Kelas",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <ClassroomMasterForm data={data} />,
      },
      {
        name: "sia-master-classroom",
        label: "Kelas",
        path: "/sia/master/classroom/edit/:id",
        title: "Edit Kelas",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <ClassroomMasterForm data={data} />,
      },
      //eduyear
      {
        name: "sia-master-eduyear",
        label: "Tahun Ajaran",
        path: "/sia/master/eduyear",
        title: "Master Tahun Ajaran",
        parent: "sia-master",
        access: ['sia-master'],
        component: (data) => <EduyearMaster data={data} />,
      },
      {
        name: "sia-master-eduyear",
        label: "Tahun Ajaran",
        path: "/sia/master/eduyear/new",
        title: "Tambah Tahun Ajaran",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <EduyearMasterForm data={data} />,
      },
      {
        name: "sia-master-eduyear",
        label: "Tahun Ajaran",
        path: "/sia/master/eduyear/edit/:id",
        title: "Edit Tahun Ajaran",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <EduyearMasterForm data={data} />,
      },
      //teacher
      {
        name: "sia-master-teacher",
        label: "Guru",
        path: "/sia/master/teacher",
        title: "Master Guru",
        parent: "sia-master",
        access: ['sia-master'],
        component: (data) => <TeacherMaster data={data} />,
      },
      {
        name: "sia-master-teacher",
        label: "Guru",
        path: "/sia/master/teacher/new",
        title: "Tambah Guru",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <TeacherMasterForm data={data} />,
      },
      {
        name: "sia-master-teacher",
        label: "Guru",
        path: "/sia/master/teacher/edit/:id",
        title: "Edit Guru",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <TeacherMasterForm data={data} />,
      },
      //student
      {
        name: "sia-master-student",
        label: "Siswa",
        path: "/sia/master/student",
        title: "Master Siswa",
        parent: "sia-master",
        access: ['sia-master'],
        component: (data) => <StudentMaster data={data} />,
      },
      {
        name: "sia-master-student",
        label: "Siswa",
        path: "/sia/master/student/new",
        title: "Tambah Siswa",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <StudentMasterForm data={data} />,
      },
      {
        name: "sia-master-student",
        label: "Siswa",
        path: "/sia/master/student/edit/:id",
        title: "Edit Siswa",
        parent: "sia-master",
        hidden: true,
        access: ['sia-master'],
        component: (data) => <StudentMasterForm data={data} />,
      },
      {
        name: "sia-master-setting",
        label: "Pengaturan",
        path: "/sia/master/setting",
        title: "Pengaturan",
        parent: "sia-master",
        access: ['sia-master'],
        component: (data) => <SiaSettingForm data={data} />,
      },
    ],

  },
  {
    name: "activity",
    label: "Aktivitas",
    path: "/sia/activity",
    icon: "fa-exchange-alt",
    is_sia: true,
    access: ['sia-activity'],
    component: (data) => <Dashboard data={data} />,
    subs: [
      // student class
      {
        name: "sia-activity-student-class",
        label: "Peserta Kelas",
        path: "/sia/activity/student-class",
        title: "Peserta Kelas",
        parent: "activity",
        access: ['sia-student-class-activity'],
        component: (data) => <StudentClassForm data={data} />,
      },
      {
        name: "sia-activity-student-class",
        label: "Tambah Peserta Kelas",
        path: "/sia/activity/student-class/new",
        title: "Tambah Peserta Kelas",
        parent: "activity",
        hidden: true,
        access: ['sia-student-class-activity'],
        component: (data) => <StudentClassForm data={data} />,
      },
      {
        name: "sia-activity-student-class",
        label: "Edit Peserta Kelas",
        path: "/sia/activity/student-class/edit/:id",
        title: "Edit Peserta Kelas",
        parent: "activity",
        hidden: true,
        access: ['sia-student-class-activity'],
        component: (data) => <StudentClassForm data={data} />,
      },

      // lesson-class
      {
        name: "sia-activity-lesson-class",
        label: "Pelajaran Kelas",
        path: "/sia/activity/lesson-class",
        title: "Aktivitas Pelajaran Kelas",
        parent: "activity",
        access: ['sia-lesson-class-acivity'],
        component: (data) => <LessonClass data={data} />,
      },
      {
        name: "sia-activity-lesson-class",
        label: "Tambah Pelajaran Kelas",
        path: "/sia/activity/lesson-class/new",
        title: "Tambah Aktivitas Pelajaran Kelas",
        parent: "activity",
        hidden: true,
        access: ['sia-lesson-class-acivity'],
        component: (data) => <LessonClassForm data={data} />,
      },
      {
        name: "sia-activity-lesson-class",
        label: "Edit Pelajaran Kelas",
        path: "/sia/activity/lesson-class/edit/:id",
        title: "Edit Aktivitas Pelajaran Kelas",
        parent: "activity",
        hidden: true,
        access: ['sia-lesson-class-acivity'],
        component: (data) => <LessonClassForm data={data} />,
      },
      {
        name: "sia-activity-lesson-class",
        label: "Pelajaran Kelas Per Kelas",
        path: "/sia/activity/lesson-class/perclass",
        title: "Aktivitas Pelajaran Kelas Per Kelas",
        parent: "activity",
        hidden: true,
        access: ['sia-lesson-class-acivity'],
        component: (data) => <LessonClassForm mass={true} data={data} />,
      },
      // lesson-class-student
      {
        name: "sia-activity-lesson-class-student",
        label: "Pelajaran Siswa",
        path: "/sia/activity/lesson-class-student",
        title: "Aktivitas Pelajaran Siswa",
        parent: "activity",
        access: ['sia-lesson-class-student-activity'],
        component: (data) => <LessonClassStudentForm data={data} />,
      },
      // {
      //   name: "sia-activity-lesson-class-student",
      //   label: "Tambah Pelajaran Siswa",
      //   path: "/sia/activity/lesson-class-student/new",
      //   title: "Tambah Aktivitas Pelajaran Siswa",
      //   parent: "activity",
      //   hidden: true,
      //   access: ['sia-lesson-class-student-activity'],
      //   component: (data) => <LessonClassStudentForm data={data} />,
      // },
      // {
      //   name: "sia-activity-lesson-class-student",
      //   label: "Edit Pelajaran Siswa",
      //   path: "/sia/activity/lesson-class-student/edit/:id",
      //   title: "Edit Aktivitas Pelajaran Siswa",
      //   parent: "activity",
      //   hidden: true,
      //   access: ['sia-lesson-class-student-activity'],
      //   component: (data) => <LessonClassStudentForm data={data} />,
      // },
      {
        name: "sia-activity-lesson-class-student",
        label: "Pelajaran Siswa Per Kelas",
        path: "/sia/activity/lesson-class-student/perlesson",
        title: "Aktivitas Pelajaran Siswa Per Pelajaran",
        parent: "activity",
        hidden: true,
        access: ['sia-lesson-class-student-activity'],
        component: (data) => <LessonClassStudentForm mass={true} data={data} />,
      },
      {
        name: "sia-activity-rapor",
        label: "Rapor",
        path: "/sia/activity/rapor",
        title: "Aktivitas Rapor Siswa",
        parent: "activity",
        access: ['sia-rapor'],
        component: (data) => <RaporActivity data={data} />,
      },
      {
        name: "sia-activity-rapor",
        label: "Rapor",
        path: "/sia/activity/rapor/:id",
        title: "Update Rapor Siswa",
        parent: "activity",
        hidden: true,
        access: ['sia-manage-rapor'],
        component: (data) => <RaporActivity data={data} />,
      },
      {
        name: "sia-activity-graduation",
        label: "Kelulusan",
        path: "/sia/activity/kelulusan",
        title: "Aktivitas Kelulusan Siswa",
        parent: "activity",
        access: ['sia-graduation', 'sia-manage-graduation'],
        component: (data) => <GraduationActivity data={data} />,
      },

    ],

  },


  {
    name: "master",
    label: "Master",
    path: "/master",
    icon: "fa-exchange-alt",
    access: ['master-user', 'master-role'],
    subs: [
      {
        name: "users",
        label: "Pengguna",
        path: "/users",
        title: "Pengguna",
        parent: "master",
        access: ['master-user'],
        component: (data) => <UserMaster data={data} />,
      },
      {
        name: "users",
        label: "Tambah Pengguna",
        path: "/users/new",
        title: "Tambah Pengguna",
        parent: "master",
        hidden: true,
        access: ['add-master-user'],
        component: (data) => <UserMasterForm data={data} />,
      },
      {
        name: "users",
        label: "Edit Pengguna",
        path: "/users/edit/:id",
        title: "Edit Pengguna",
        parent: "master",
        hidden: true,
        access: ['edit-master-user', 'edit-master-user-other'],
        component: (data) => <UserMasterForm data={data} />,
      },
      {
        name: "user-roles",
        label: "Role Pengguna",
        path: "/user-roles",
        title: "Role Pengguna",
        parent: "master",
        access: ['master-role'],
        component: (data) => <RoleMaster data={data} />,
      },
      {
        name: "user-roles",
        label: "Tambah Role Pengguna",
        path: "/user-roles/new",
        title: "Tambah Role Pengguna",
        parent: "master",
        hidden: true,
        access: ['add-master-role'],
        component: (data) => <RoleMasterForm data={data} />,
      },
      {
        name: "user-roles",
        label: "Edit Role Pengguna",
        path: "/user-roles/edit/:id",
        title: "Edit Role Pengguna",
        parent: "master",
        hidden: true,
        access: ['edit-master-role'],
        component: (data) => <RoleMasterForm data={data} />,
      },
      // {
      //   name: "user-privilages",
      //   label: "Akses Pengguna",
      //   path: "/user-privilages",
      //   title: "Akses Pengguna",
      //   parent: "users",
      //   component: (data) => <Dashboard data={data} />,
      // },
    ]
  },
]