import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetLesson = (payload) => {
    return {
        type: actionTypes.GET_LESSON,
        data: payload,
    };
};

export const getLessonOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/master/lesson/` + data);
        dispatch(setGetLesson(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetLessons = (payload) => {
    return {
        type: actionTypes.GET_LESSONS,
        data: payload,
    };
};

export const getLessonAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/master/lesson`, { params });
        dispatch(setGetLessons(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setLessonCreate = (payload) => {
    return {
        type: actionTypes.LESSON_CREATE,
        data: payload,
    };
}

export const postCreateLesson = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/lesson`, data);
        dispatch(setOnloading(null))
        dispatch(setLessonCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setLessonUpdate = (payload) => {
    return {
        type: actionTypes.LESSON_UPDATE,
        data: payload,
    };
}

export const putUpdateLesson = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/master/lesson/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setLessonUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setLessonDelete = (payload) => {
    return {
        type: actionTypes.LESSON_DELETE,
        data: payload,
    };
}

export const deleteLesson = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/master/lesson/${id}`);
        dispatch(setOnloading(null))
        dispatch(setLessonDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostImportCsv = (payload) => {
    return {
        type: actionTypes.POST_IMPORT_CSV,
        data: payload,
    };
};


export const postImportCsv = (data) => async (dispatch) => {
    try {

        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/lesson-import`, data);
        console.log("aaww", result)
        dispatch(setPostImportCsv(result));
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
