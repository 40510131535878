import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import { getLessonClassStudentByClassYears, getLessonClassStudentOne, getLessonClassStudentOptForm, postCreateLessonClassStudent, putUpdateLessonClassStudent, setGetLessonClassStudent, setGetLessonClassStudentByClassYears, setLessonClassStudentCreate, setLessonClassStudentUpdate } from '../../../../../redux/actions/sia-activity/lesson-class-student'
import { AuthWrapper } from '../../../../AuthWrapper'
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next'
import { notify } from '../../../../../constants/utility'

const LessonClassStudentForm = (props) => {
    const mass = true
    const { name, text, path, title, parent } = props.data
    const { loading, lessonClassStudent, lessonClassStudentCreate, lessonClassStudentUpdate, lessonClassStudentOptForm,
        lessonClassStudentByClassYear
    } = useSelector((state) => state.lessonClassStudent);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const [lessonClassOption, setLessonClassOption] = useState([])

    const [semesterOption, setSemesterOption] = useState([])
    const [classroomOption, setClassroomOption] = useState([])
    const [studentLessonOption, setStudentLessonOption] = useState([])
    const [teacherOption, setTeacherOption] = useState([])

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetLessonClassStudent(null))
        dispatch(getLessonClassStudentOptForm())
        dispatch(setGetLessonClassStudentByClassYears(null))
    }, [])

    const { id } = useParams()
    useEffect(() => {
        if (id) {
            dispatch(getLessonClassStudentOne(id))
        }
    }, [id])

    useEffect(() => {
        // setEduyearOption([{ value: lessonClassStudentOptForm?.result?.eduyears?.id, label: lessonClassStudentOptForm?.result?.eduyears?.name }])
        // setSemesterOption([{ value: lessonClassStudentOptForm?.result?.semesters?.id, label: lessonClassStudentOptForm?.result?.semesters?.name }])

        // let classrooms = lessonClassStudentOptForm?.result?.classrooms.map(item => {
        //     return { value: item.id, label: item.name }
        // })
        // setClassroomOption(classrooms)

        // let studentLessons = lessonClassStudentOptForm?.result?.studentLessons.map(item => {
        //     return { value: item.id, label: item.name }
        // })
        // setStudentLessonOption(studentLessons)

        let lesson_classes = lessonClassStudentOptForm?.result?.map(item => {
            // console.log(item)
            return { value: item.id, label: `${item['lesson.name']} - ${item['classroom.name']} - ${item['eduyear.name']} - ${item['semester.name']} - ${item['teacher.name']}` }
        })
        setLessonClassOption(lesson_classes)
        // console.log('rayyankirana',)

    }, [lessonClassStudentOptForm])

    useEffect(() => {
        setSemesterOption([{ value: lessonClassStudent?.result?.semester?.id, label: lessonClassStudent?.result?.semester?.name }])
        if (lessonClassStudent) {
            setValue('eduyear_id', { value: lessonClassStudent?.result?.eduyear?.id, label: lessonClassStudent?.result?.eduyear?.name })
            setValue('semester_id', { value: lessonClassStudent?.result?.semester?.id, label: lessonClassStudent?.result?.semester?.name })
        }

        // let studentLesson = studentLessonOption?.filter(item => item.value === lessonClassStudent?.result?.studentLesson?.id)
        // setValue('student_id', studentLesson?.[0] || '')

        // let teacher = teacherOption?.filter(item => item.value === lessonClassStudent?.result?.teacher?.id)
        // setValue('teacher_id', teacher?.[0] || '')

    }, [lessonClassStudent])

    useEffect(() => {
        if (classroomOption) {
            let classroom = classroomOption.filter(item => item.value === lessonClassStudent?.result?.classroom?.id)
            setValue('classroom_id', classroom?.[0] || '')
            console.log('awwx', classroom,)
        }
    }, [classroomOption, lessonClassStudent])

    useEffect(() => {
        if (teacherOption) {
            let teacher = teacherOption.filter(item => item.value === lessonClassStudent?.result?.teacher?.id)
            setValue('teacher_id', teacher?.[0] || '')
            console.log('awwx', teacher,)
        }
    }, [teacherOption, lessonClassStudent])

    useEffect(() => {
        if (studentLessonOption) {
            let studentLesson = studentLessonOption.filter(item => item.value === lessonClassStudent?.result?.studentLesson?.id)
            setValue('student_id', studentLesson?.[0] || '')
            console.log('awwx', studentLesson,)
        }
    }, [studentLessonOption, lessonClassStudent])

    useEffect(() => {
        if (lessonClassStudentCreate) {
            notify(lessonClassStudentCreate.message, lessonClassStudentCreate.success ? 'success' : 'error')

            if (!mass) navigate("/sia/activity/lesson-class-student/edit/" + lessonClassStudentCreate.result?.id, { replace: true })
            else {
                const params = {
                    lesson_class_id: watch("lesson_class_id").value,
                }
                dispatch(getLessonClassStudentByClassYears(params))
            }
        }

        return () => {
            dispatch(setLessonClassStudentCreate(null))
        }
    }, [lessonClassStudentCreate])

    useEffect(() => {
        if (lessonClassStudentUpdate) {
            notify(lessonClassStudentUpdate.message, lessonClassStudentUpdate.success ? 'success' : 'error')

            dispatch(getLessonClassStudentOne(id))
            dispatch(setLessonClassStudentUpdate(null))
        }

    }, [lessonClassStudentUpdate])

    const submitForm = (data) => {
        if (loading === true) return;
        let newData = {}
        newData.lesson_class_id = data.lesson_class_id.value;
        newData.students = []
        if (data.student_id !== '') {
            if (!Array.isArray(data.student_id)) newData.students = [{ student_id: data.student_id.value, }]
            else newData.students = data.student_id.map(item => item.value)
        }

        if (mass === true) {
            newData.students = peserta.map(item => {
                // console.log(item)
                return {
                    id: item.id ? item.id : null,
                    student_id: item['student.id'],
                    is_delete: item.is_delete || null
                }
            })

        }
        console.log('newData', newData, mass, peserta)

        if (id) dispatch(putUpdateLessonClassStudent(id, newData))
        else dispatch(postCreateLessonClassStudent(newData))
    }

    const calonPesertaColumns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'student.name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
    ]

    const pesertaColumns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'student.name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
        },
        {
            dataField: 'student.id', text: '',
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deletedx' : ''}` },
            formatter: (cel, row) => {
                return <span className="btn btn-danger btn-sm" onClick={() => hapusPeserta(row['student.id'])}>{!row.is_delete ? 'Hapus' : 'Batal Hapus'}</span>
            }
        },
    ]

    const [showModal, setShowModal] = useState(false)
    const [calonSelected, setCalonSelected] = useState([])
    const [peserta, setPeserta] = useState([])
    useEffect(() => {
        setPeserta([])
        setPeserta(lessonClassStudentByClassYear?.result?.peserta || [])
    }, [lessonClassStudentByClassYear])

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) setCalonSelected(prev => [...prev, row])
        else setCalonSelected(calonSelected.filter(x => x.id !== row.id))
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) setCalonSelected(rows)
        else setCalonSelected([])
    }

    const tambahkanPeserta = () => {
        setShowModal(false)
        let temp = peserta
        setPeserta([])
        calonSelected.map((item, key) => {
            setPeserta(prev => [...prev, {
                'id': null,
                'student.id': item['student.id'],
                'student.name': item['student.name'],
            }])
        })
        temp.map(item => setPeserta(prev => [...prev, item]))

    }

    const hapusPeserta = (id) => {

        const newPeserta = peserta.map(item => {
            console.log('xxx', item['student.id'], id)
            if (item['student.id'] === id) {
                item['is_delete'] = item['is_delete'] === true ? false : true
            }
            return item
        })
        setPeserta(newPeserta)
    }

    useEffect(() => {
        console.log("calon ", calonSelected)
    }, [calonSelected])

    const [pesertaIds, setPesertaIds] = useState([])
    useEffect(() => {
        console.log("peserta", peserta)
        const ids = peserta.map(item => item['student.id'])
        setPesertaIds(ids)
    }, [peserta])

    const [lessonClassSelected, setLessonClassSelected] = useState(null)
    useEffect(() => {
        if (watch("lesson_class_id") && mass) {
            const params = {
                lesson_class_id: watch("lesson_class_id").value,
            }
            dispatch(getLessonClassStudentByClassYears(params))
            setLessonClassSelected(lessonClassStudentOptForm?.result?.filter(item => item.id == watch("lesson_class_id").value)?.[0])
        }
    }, [watch("lesson_class_id")])

    useEffect(() => {
        console.log('lessonClassSelected', lessonClassSelected)
    }, [lessonClassSelected])

    const onButtonTambahClick = () => {
        setCalonSelected([])
        setShowModal(true)
    }
    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-12">
                                <Controller
                                    name="lesson_class_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Mata Pelajaran Kelas"
                                            type="select"
                                            placeholder="Mata Pelajaran Kelas"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={lessonClassOption}
                                        />
                                    }
                                />


                                {!mass ?
                                    <>
                                        <Controller
                                            name="student_id"
                                            control={control}
                                            rules={{ required: 'Harus diisi' }}
                                            render={({ field }) =>
                                                <TextInput
                                                    layout="horizontal"
                                                    label="Mata Pelajaran"
                                                    type="select"
                                                    // name="code"
                                                    placeholder="Mata Pelajaran"
                                                    register={register}
                                                    field={field}
                                                    errors={formState.errors}
                                                    options={studentLessonOption}
                                                />
                                            }
                                        />
                                    </> : ""
                                }


                            </div>
                        </div>
                        {mass ?
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-secondary">
                                            Siswa
                                        </h6>
                                        <span onClick={() => onButtonTambahClick()} className="btn btn-sm btn-info">Tambah</span>
                                    </div>
                                    <hr />

                                </div>
                                <div className="col-md-12">
                                    <BootstrapTable
                                        className="bt-table"
                                        bootstrap4
                                        hover
                                        bordered
                                        striped
                                        condensed
                                        noDataIndication={() => { return ('Tidak ada peserta') }}
                                        keyField="student.id"
                                        data={peserta}
                                        columns={pesertaColumns}
                                    />
                                </div>


                                <Modal
                                    {...props}
                                    show={showModal}
                                    size={'lg'}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    onHide={() => setShowModal(false)}
                                >
                                    <Modal.Header className='text-primary'>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            Tambah Siswa Kelas {lessonClassSelected?.['classroom.name']}
                                        </Modal.Title>
                                        <span className='btn' onClick={() => setShowModal(false)}>&times;</span>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BootstrapTable
                                            className="bt-table"
                                            bootstrap4
                                            hover
                                            bordered
                                            striped
                                            condensed
                                            noDataIndication={() => { return ('Tidak siswa pada kelas ini') }}
                                            keyField="id"
                                            data={lessonClassStudentByClassYear?.result?.calon_peserta?.filter(item => !pesertaIds.includes(item['student.id'])) || []}
                                            columns={calonPesertaColumns}
                                            selectRow={
                                                {
                                                    mode: 'checkbox',
                                                    clickToSelect: true,
                                                    onSelect: handleOnSelect,
                                                    onSelectAll: handleOnSelectAll
                                                }
                                            }
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button
                                            onClick={() => tambahkanPeserta()}
                                            className="btn btn-primary btn-sm"
                                            disabled={calonSelected.length === 0}>Tambahkan {calonSelected.length} Mata Pelajaran</button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                            : ""
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(LessonClassStudentForm)
