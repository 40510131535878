import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
    loading: null,
    general: null,
    generalUpdate: null,
};


const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.GENERAL_UPDATE:
            return updateObject(state, { generalUpdate: data })
        case actionTypes.GENERAL:
            return updateObject(state, { general: data })
        case actionTypes.ON_LOADING:
            return updateObject(state, { loading: data })
        default:
            return state;
    }
};

export default reducer;
