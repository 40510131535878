import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from '../../../AuthWrapper';
import * as actions from '../../../../redux/actions';
import PostForm from '../../../../components/PostForm';
import PageHeading from '../../../../components/PageHeading';
import CardBasic from '../../../../components/Cards/Basic';
import { getPostOne, postCreatePost, putUpdatePost, setGetPost, setPostCreate, setPostUpdate } from '../../../../redux/actions/post';
// import { ProgressBar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import Progressbar from '../../../../components/Progressbar';
import { notify } from '../../../../constants/utility';

const AgendasForm = (props) => {
    const { name, label, path, title, parent } = props.data
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [dispatch])

    const { loading } = useSelector((state) => state.post);

    const fieldOptions = {
        type: 'agenda',
        hide_visibility: true,
        hide_comment_status: true,
    }

    const { id } = useParams()
    const { post } = useSelector(state => state.post)

    const [initValue, setInitValue] = useState({})

    const handleSubmitForm = (data) => {
        data['meta'] = []
        for (var key in data) {
            if (key.includes('mtdt')) {
                data.meta.push({ name: key, value: data[key] })
                data[key] = null
            }
        }
        data.status = data.status.value
        if (loading === true) return;
        if (!id) dispatch(postCreatePost(data))
        else dispatch(putUpdatePost(id, data))
    }

    const { postCreate, postUpdate } = useSelector((state) => state.post);
    useEffect(() => {
        if (postCreate) {
            notify(postCreate.message, postCreate.success ? 'success' : 'error')
            navigate("/agendas/edit/" + postCreate.result?.id, { replace: true })
        }

    }, [postCreate])

    useEffect(() => {
        if (postUpdate) {
            notify(postUpdate.message, postUpdate.success ? 'success' : 'error')

            dispatch(getPostOne(id))
            dispatch(setPostUpdate(null))
        }

    }, [postUpdate])

    useEffect(() => {
        let initObject = {
            title: post?.result?.title,
            content: post?.result?.content || '',
            tags: post?.result?.tags || '',
            image: post?.result?.image || '',
            status: post?.result?.status || 'publish',
            visibility: post?.result?.visibility || 'public',
            comment_status: post?.result?.comment_status || 'close',
            type: post?.result?.type || 'agenda',
            excerpt: post?.result?.excerpt || '',
            agenda_date: post?.result?.agenda_date || '',
        }

        if (post?.result?.post_meta.length > 0) {
            post.result.post_meta.map(item => {
                initObject[item.name] = !isNaN(item.value) ? parseInt(item.value) : item.value
            })
        }
        setInitValue(initObject)
        // console.log('alsocodes', initValue, post)
    }, [post])

    useEffect(() => {
        if (id) {
            dispatch(getPostOne(id))
            dispatch(setPostCreate(null))
        }
        return () => {
            dispatch(setGetPost(null))
        }
    }, [id])


    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} />
            <PostForm
                loading={loading}
                initValue={initValue}
                handleSubmitForm={handleSubmitForm}
                fieldOptions={fieldOptions} />
        </div>
    )
}

export default AuthWrapper(AgendasForm)
