import { updateObject } from "../../../../constants/utility";
import * as actionTypes from "../../../../constants/actionTypes";

const initialState = {
    loading: null,
    roleCreate: null,
    roleUpdate: null,
    roleDelete: null,
    role: null,
    roles: null,
    accesses: null,
    importCsv: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const roleCreate = (state, data) => {
    return updateObject(state, {
        roleCreate: data,
    });
}

const getRole = (state, data) => {
    return updateObject(state, {
        role: data,
    });
}

const getRoles = (state, data) => {
    return updateObject(state, {
        roles: data,
    });
}

const getAccesses = (state, data) => {
    return updateObject(state, {
        accesses: data,
    });
}

const roleUpdate = (state, data) => {
    return updateObject(state, {
        roleUpdate: data,
    });
}

const roleDelete = (state, data) => {
    return updateObject(state, {
        roleDelete: data,
    });
}

const setImportCsv = (state, data) => {
    return updateObject(state, {
        importCsv: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.GET_ACCESSES:
            return getAccesses(state, data);
        case actionTypes.POST_IMPORT_CSV:
            return setImportCsv(state, data);
        case actionTypes.ROLE_DELETE:
            return roleDelete(state, data);
        case actionTypes.ROLE_UPDATE:
            return roleUpdate(state, data);
        case actionTypes.GET_ROLES:
            return getRoles(state, data);
        case actionTypes.GET_ROLE:
            return getRole(state, data);
        case actionTypes.ROLE_CREATE:
            return roleCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
