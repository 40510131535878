import * as actionTypes from '../../../constants/actionTypes';
import http from "../../../services/NetworkService";

export const setOnloading = (payload) => { return { type: actionTypes.ON_LOADING, data: payload } };

export const setGetGeneral = (payload) => { return { type: actionTypes.GENERAL, data: payload } }
export const getGeneral = () => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/setting/general`);
        dispatch(setGetGeneral(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostGeneralUpdate = (payload) => { return { type: actionTypes.GENERAL_UPDATE, data: payload } }
export const postGeneralUpdate = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/setting/general`, data);
        dispatch(setPostGeneralUpdate(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

