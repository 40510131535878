import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from '../../AuthWrapper';
import * as actions from '../../../redux/actions';
import { Link } from "react-router-dom";

import CardBasic from '../../../components/Cards/Basic';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Searchbar from '../../../components/Searchbar';
import ModalConfirm from '../../../components/ModalConfirm';
import { formatCurrency, formatDate, image_path, notify } from '../../../constants/utility';
import PageHeading from '../../../components/PageHeading';
import { getPostAll, deletePost, setPostDelete } from '../../../redux/actions/post';
import { Button } from 'react-bootstrap';
import Progressbar from '../../../components/Progressbar';

const Preface = (props) => {
    const { name, text, path, title, parent } = props.data
    const dispatch = useDispatch();

    const [search, setSearch] = useState("")
    const [filterred, setFilterred] = useState({})
    const [sort, setSort] = useState(null)
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(5)
    const [totalSize, setTotalSize] = useState(0)
    const [data, setData] = useState([])

    const { posts, postDelete } = useSelector(state => state.post)
    useEffect(() => {
        if (posts) {
            setData(posts.result?.rows)
            setTotalSize(posts.result?.total_count)
        }

    }, [posts])

    useEffect(() => {
        if (postDelete) {
            notify(postDelete.message, postDelete.success ? 'success' : 'error')
            setModalShow(false)
        }
        return () => {
            dispatch(setPostDelete(null))
        }
    }, [postDelete])

    const columns = [
        {
            dataField: 'id',
            text: '',
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (val, row) => {
                // return formatDate(val, false, false, true)
                return (
                    <>
                        <Link to={`/prefaces/edit/${val}`} className="btn btn-warning btn-sm m-1"><i className="fas fa-edit"></i> edit</Link>
                        {/* <Link to={`/prefaces/view/${val}`} className="btn btn-info btn-sm m-1"><i className="fas fa-search"></i> lihat</Link> */}
                        <span onClick={() => onDelete(row)} className="btn btn-danger btn-sm m-1"><i className="fas fa-trash"></i> hapus</span>
                    </>
                )
            }
        },
        {
            dataField: 'created_at',
            text: 'Tanggal',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (val, row) => {
                return formatDate(val, false, false, false)
            }
        },
        {
            dataField: 'image',
            text: 'Thumbnail',
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (val, row) => {
                return (<img className="img-thumbnail" width="120px" src={`${image_path}300-${val}`} alt={row.title} />)
            }
        },
        {
            dataField: 'title',
            text: 'Judul',
            sort: true,
            filterable: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            }
        },
        {
            dataField: 'author.name',
            text: 'Penulis',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            }
        }
    ];

    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [])

    useEffect(() => {

        const params = {
            search: search,
            page_size: sizePerPage,
            page: page,
            filter: {},
            sort: sort,
            type: 'preface'
        }

        if (filterred) {
            for (var x in filterred) {
                params.filter[x] = filterred[x]
            }
        }

        dispatch(getPostAll(params))
    }, [filterred, search, sort, page, sizePerPage, postDelete])

    const { loading } = useSelector((state) => state.post);

    const handleTableChange = (type, { sortField, sortOrder, data, page, sizePerPage }) => {
        console.log("oceoce", type, page, sizePerPage)
        if (type === 'sort') {
            setSort({
                orderby: sortField,
                order: sortOrder
            })
        }

        if (type === 'pagination') {
            setPage(page)
            setSizePerPage(sizePerPage)
        }
    }

    const [modalShow, setModalShow] = useState(false)
    const [modalOption, setModalOption] = useState({})
    const onDelete = (data) => {

        setModalShow(true)
        setModalOption({
            title: 'Konfirmasi hapus',
            content: (
                <div>
                    <p>Anda yakin akan menghapus <strong>{data?.title}</strong> ?</p>

                    <hr mb={2} mt={2} />
                    <Button className='float-left' size='sm' variant={`outline-secondary`} onClick={() => setModalShow(false)}>Batal</Button>
                    <Button className='float-right' variant={`danger`} size='sm'
                        onClick={() => dispatch(deletePost(data.id))}>Hapus</Button>
                </div>
            ),
            color: 'danger',
            size: 'md',
            data: data,
        })

    }

    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} />
            <div className="row animate-col-size-change">
                <div className={`col-md-12`}>
                    <CardBasic nopadding>
                        <div className="d-flex p-4">
                            <Link to="new" className="mr-2"><span className="btn btn-primary-f btn-md">Tambah</span></Link>
                            <div className="flex-grow-1">
                                <Searchbar
                                    placeholder="Pencarian"
                                    onEnter={(data) => setSearch(data)} />
                            </div>

                        </div>


                        <RemotePagination
                            data={data}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={totalSize}
                            columns={columns}
                            onTableChange={handleTableChange}
                        />
                    </CardBasic>
                </div>
            </div>
            <ModalConfirm
                show={modalShow}
                modalOption={modalOption}
                onHide={() => setModalShow(false)}
            />
        </div>
    )
}

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize, columns, onRowClick }) => {
    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];
    return (
        <div className="table-fixied custom-scrollbar table-responsive my-table">
            <BootstrapTable
                remote
                className="bt-table"
                bootstrap4
                hover
                bordered
                striped
                condensed
                noDataIndication={indication}
                bordered={false}
                keyField="id"
                defaultSorted={defaultSorted}
                data={data}
                columns={columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={onTableChange}
            />
        </div>
    )
}

export default AuthWrapper(Preface)
