import * as actionTypes from '../../../constants/actionTypes';
import http from "../../../services/NetworkService";


export const setOnloading = (payload) => { return { type: actionTypes.ON_LOADING, data: payload } };

export const setGalleryUploadImage = (payload) => { return { type: actionTypes.UPLOAD_IMAGE, data: payload } };
export const galleryUploadImage = (data) => async (dispatch) => {
    try {
        const result = await http.post(`/gallery/upload-image`, data);
        console.log('alsocodes http', result)
        dispatch(setGalleryUploadImage(result));
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostGalleryCreate = (payload) => { return { type: actionTypes.GALLERY_CREATE, data: payload } }
export const postGalleryCreate = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/gallery`, data);
        dispatch(setOnloading(null))
        dispatch(setPostGalleryCreate(result))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPutGalleryUpdate = (payload) => { return { type: actionTypes.GALLERY_UPDATE, data: payload } }
export const putGalleryUpdate = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/gallery/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setPutGalleryUpdate(result))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setDelGalleryDelete = (payload) => { return { type: actionTypes.GALLERY_DELETE, data: payload } }
export const delGalleryDelete = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/gallery/${id}`);
        dispatch(setOnloading(null))
        dispatch(setDelGalleryDelete(result))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetGalleries = (payload) => { return { type: actionTypes.GALLERIES, data: payload } }
export const getGalleries = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/gallery`, { params });
        dispatch(setGetGalleries(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetGallery = (payload) => { return { type: actionTypes.GALLERY, data: payload } }
export const getGallery = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/gallery/${id}`);
        dispatch(setGetGallery(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


// album
export const setAlbumCreate = (payload) => { return { type: actionTypes.ALBUM_CREATE, data: payload } }
export const albumCreate = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/album`, data);
        dispatch(setOnloading(null))
        dispatch(setAlbumCreate(result))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setAlbumUpdate = (payload) => { return { type: actionTypes.ALBUM_UPDATE, data: payload } }
export const albumUpdate = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/album/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setAlbumUpdate(result))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setDelAlbumDelete = (payload) => { return { type: actionTypes.ALBUM_DELETE, data: payload } }
export const delAlbumDelete = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/album/${id}`);
        dispatch(setOnloading(null))
        dispatch(setDelAlbumDelete(result))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetAlbums = (payload) => { return { type: actionTypes.ALBUMS, data: payload } }
export const getAlbums = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/album`, { params });
        dispatch(setGetAlbums(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetAlbum = (payload) => { return { type: actionTypes.ALBUM, data: payload } }
export const getAlbum = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http.get(`/album/${id}`);
        dispatch(setGetAlbum(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}