import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

//Navigation
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import * as actions from '../../redux/actions';
import { AuthWrapper } from '../AuthWrapper';

import CardInfo from '../../components/Cards/Info';
import ChartDonut from '../../components/Charts/Donut';
import ChartLine from '../../components/Charts/Line';
import PageHeading from '../../components/PageHeading';
import CardBasic from '../../components/Cards/Basic';

const Dashboard = (props) => {
    const { name, label, path, title, parent } = props.data
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [dispatch])

    return (
        <div>
            <PageHeading title="Dashboard" />
            <CardBasic>
                <div style={{ minHeight: "300px" }}>
                    <h5 className="text-center py-4">Selamat Datang di Dashboard CMS SMPN33 Surabaya</h5>
                </div>
            </CardBasic>
        </div>
    )
}

export default AuthWrapper(Dashboard);
// export default Dashboard;