import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
    loading: null,
    menus: null,
    menu: null,
    menuCreate: null,
    menuUpdate: null,
    menuDelete: null,
    structureMenus: null,
    structureMenuUpdate: null,
    contentOpts: null
};


const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.CONTENT_OPTS:
            return updateObject(state, { contentOpts: data })
        case actionTypes.MENU:
            return updateObject(state, { menu: data })
        case actionTypes.STRUCTURE_MENU_UPDATE:
            return updateObject(state, { structureMenuUpdate: data })
        case actionTypes.STRUCTURE_MENUS:
            return updateObject(state, { structureMenus: data })
        case actionTypes.MENU_DELETE:
            return updateObject(state, { menuDelete: data })
        case actionTypes.MENU_UPDATE:
            return updateObject(state, { menuUpdate: data })
        case actionTypes.MENU_CREATE:
            return updateObject(state, { menuCreate: data })
        case actionTypes.MENUS:
            return updateObject(state, { menus: data })
        case actionTypes.ON_LOADING:
            return updateObject(state, { loading: data })
        default:
            return state;
    }
};

export default reducer;
