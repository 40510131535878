import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from '../../AuthWrapper';
import * as actions from '../../../redux/actions';
import { Link } from "react-router-dom";

import CardBasic from '../../../components/Cards/Basic';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Searchbar from '../../../components/Searchbar';
import ModalConfirm from '../../../components/ModalConfirm';
import { formatCurrency, formatDate } from '../../../constants/utility';
import PageHeading from '../../../components/PageHeading';
import Progressbar from '../../../components/Progressbar';
import MainMenu from './MainMenu';

const WebMenu = (props) => {
    const { name, text, path, title, parent } = props.data
    const dispatch = useDispatch();

    const [search, setSearch] = useState("")
    const [sort, setSort] = useState(null)
    const [data, setData] = useState([
        { id: 'main', name: 'Main Menu', 'description': 'Main menu navigasi website' },
        { id: 'top', name: 'Top Menu', 'description': 'Top navigasi menu website' },
        { id: 'bottom-1', name: 'Bottom Menu 1', 'description': 'Bottom left navigasi menu website' },
        { id: 'bottom-2', name: 'Bottom Menu 2', 'description': 'Bottom right navigasi menu website' },
    ])


    const columns = [
        {
            dataField: 'id',
            text: '',
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (val, row) => {
                // return formatDate(val, false, false, true)
                return (
                    <>
                        <Link to={`/menus/${val}`} >
                            <button className="btn btn-warning btn-sm m-1"><i className="fas fa-edit"></i> edit</button>
                        </Link>
                    </>
                )
            }
        },
        {
            dataField: 'name',
            text: 'Nama',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
        },
        {
            dataField: 'description',
            text: 'Keterangan',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (c, i) => { return c.substring(0, 100) }
        },

    ];

    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [])

    const { loading } = useSelector((state) => state.webMenu);
    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} />
            <div className="row animate-col-size-change">
                <div className={`col-md-12`}>
                    {/* <MainMenu /> */}
                    <BootstrapTable
                        className="bt-table"
                        bootstrap4
                        hover
                        bordered
                        striped
                        condensed
                        keyField="id"
                        // defaultSorted={defaultSorted}
                        data={data}
                        columns={columns}
                    // onTableChange={onTableChange}
                    />
                </div>
            </div>
        </div>
    )
}

export default AuthWrapper(WebMenu)
