import store from "../redux/store";
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// export const api_url = 'http://localhost:3007'
// export const api_url = 'https://devapi.smpn33-sby.sch.id'
export const api_url = 'https://eipiai.smpn33-sby.sch.id'
export const image_path = api_url + '/public/images/'

//update object immutably
export const updateObject = (oldState, updatedProperties) => {
    return {
        ...oldState,
        ...updatedProperties,
    };
};

// Get FormData values
export const getFormData = (formElement) => {
    const formData = new FormData(formElement);
    const values = {};
    for (const [key, value] of formData) {
        values[key] = value;
    }
    return values;
};

/** Format currency number */
export const formatCurrency = (number) => {
    const text = new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
    }).format(number || 0);

    return text.replace(/\xa0/g, " ").replace(/\u202f/g, " ");
};

export const formatDateOnly = (date, hideTime = false, short = false) => {
    let dateObj = Date.parse(date);

    if (!dateObj) {
        dateObj = new Date(Date.now());
    }

    return new Intl.DateTimeFormat("id", {
        weekday: !short ? "long" : undefined,
        day: "numeric",
        month: !short ? "long" : "short",
        year: "numeric"
    }).format(dateObj);
};

/** Format date locale */
export const formatDate = (date, hideTime = false, short = false) => {
    let dateObj = Date.parse(date);

    if (!dateObj) {
        dateObj = new Date(Date.now());
    }

    return new Intl.DateTimeFormat("id", {
        weekday: !short ? "long" : undefined,
        day: "numeric",
        month: !short ? "long" : "short",
        year: "numeric",
        hour: !hideTime ? "numeric" : undefined,
        minute: !hideTime ? "numeric" : undefined,
    }).format(dateObj);
};

export const formatTime = (date) => {
    const dateObj = Date.parse(date);

    if (!dateObj) {
        return "-";
    }

    return new Intl.DateTimeFormat("id", {
        hour: "numeric",
        minute: "numeric",
    }).format(dateObj);
};

export const getAccessToken = () => {
    const authState = store.getState().auth;
    let token = authState.data?.access_token;
    if (token) {
        token = `Bearer ${token}`;
    }

    return token
}

export const capEachWord = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
}

export const has_access = (access, owned_access, is_all = false) => {
    let count = 0
    if (access) {
        if (access.includes("*")) count++ // wilcard without access owned by user
        if (!Array.isArray(access)) access = [access]
        access.map(item => {
            if (owned_access?.includes(item)) count++
        })
    }
    // console.log('count', access, owned_access, count)
    return is_all ? count === access.length : count > 0
}

export const downloadFileAction = (file) => {
    const link = document.createElement('a');
    link.href = file;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

toast.configure()
export const notify = (content, type, onClose = null) => {
    // toast("Wow so easy!");
    const config = {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Slide,
        onClose: onClose,
    }
    switch (type) {
        case 'success':
            toast.success(content, config);
            break;
        case 'warning':
            toast.warning(content, config);
            break;
        case 'info':
            toast.info(content, config);
            break;
        case 'error':
            toast.error(content, config);
            break;

        default:
            toast(content, config);
    }


}

export const fileToBase64 = (file) => {
    return Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
}