import React from 'react'
import { Modal } from 'react-bootstrap'

const ModalConfirm = (props) => {
    const {
        title, content, size, color, data
    } = props.modalOption

    return (
        <Modal
            {...props}
            size={size}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className='text-danger'>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
                <span className='btn' onClick={props.onHide}>&times;</span>
            </Modal.Header>
            <Modal.Body>
                {/* <h4>Centered Modal</h4> */}
                {content}
            </Modal.Body>
            {/* <Modal.Footer>
            </Modal.Footer> */}
        </Modal>
    )
}

export default ModalConfirm