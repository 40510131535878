import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";
import { persistAuth } from "./redux/actions/login";

//Pages
import SignIn from "./pages/SignIn";
import NotFound from "./pages/NotFound";
import Forbidden from "./pages/Forbidden";
import { menus } from "./settings";
import { has_access } from "./constants/utility";

const Router = () => {
  const dispatch = useDispatch();
  const { persisting, loggedIn, data: userData } = useSelector((state) => state.auth);
  // const { title } = useSelector((state) => state.route);
  useEffect(() => {
    dispatch(persistAuth());
  }, [dispatch]);

  if (persisting) {
    return null;
  }

  let routes = [];
  menus.map((item) => {
    if (item.component) routes.push(item);
    if (item.subs) {
      item.subs.map((sub) => {
        if (sub.component) routes.push(sub);
      });
    }
  });

  routes.push({
    name: "404notfound",
    label: "404 Notfound",
    path: "*",
    title: "404 Notfound",
    access: ["*"],
    component: (data) => <NotFound data={data} />,
  });

  return (
    <BrowserRouter>
      {!loggedIn ? (
        <Routes>
          <Route exact path="/signin" element={<SignIn />} />
          <Route path="*" element={<Navigate replace to="/signin" />} />
        </Routes>
      ) : (
        <Routes>
          {routes.map((menu, key) => {
            if (!menu.component || !menu.access) return ""
            if (!has_access(menu.access, userData.user?.role_access)) {
              // console.log('ssx', userData)
              if (userData.user?.user_type === 'teacher' || userData.user?.user_type === 'student' && menu.path === '/')
                return <Route exact path="*" element={<Navigate replace to="/sia" />} />
              else
                return <Route exact path="*" element={<Forbidden />} />
            }
            return <Route key={key} exact path={menu.path} element={menu.component(menu)} />
          })}
          <Route exact path="/signin" element={<Navigate replace to="/" />} />
          {/* <Routes path="*" element={<NotFound/>} /> */}
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default Router;
