import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    graduation: null,
    graduations: null,
    graduationUpdate: null,
    graduationDelete: null,
    bulkGraduationUpload: null,
    formOpt: null,
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

const graduation = (state, data) => {
    return updateObject(state, {
        graduation: data
    })
}

const graduations = (state, data) => {
    return updateObject(state, {
        graduations: data
    })
}

const formOpt = (state, data) => {
    return updateObject(state, {
        formOpt: data
    })
}

const graduationUpdate = (state, data) => {
    return updateObject(state, {
        graduationUpdate: data
    })
}

const graduationDelete = (state, data) => {
    return updateObject(state, {
        graduationDelete: data
    })
}

const bulkGraduationUpload = (state, data) => {
    return updateObject(state, {
        bulkGraduationUpload: data
    })
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.FORM_OPT:
            return formOpt(state, data);
        case actionTypes.BULK_GRADUATION_UPLOAD:
            return bulkGraduationUpload(state, data);
        case actionTypes.GRADUATION_DELETE:
            return graduationDelete(state, data);
        case actionTypes.GRADUATION_UPDATE:
            return graduationUpdate(state, data);
        case actionTypes.GET_GRADUATIONS:
            return graduations(state, data);
        case actionTypes.GET_GRADUATION:
            return graduation(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
