import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/actions';
import { Modal } from 'react-bootstrap'
import { image_path, notify } from '../../../../constants/utility'
import {
    postGalleryCreate, putGalleryUpdate, galleryUploadImage,
    setPutGalleryUpdate, setPostGalleryCreate, setGalleryUploadImage, getGalleries, getGallery
} from '../../../../redux/actions/gallery'
import TextInput from '../../../../components/TextInput'
import { useForm } from 'react-hook-form';

const GalleryForm = (props) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const {
        id,
        action_title
    } = props.data

    const handleSubmitForm = (data) => {
        if (!id) dispatch(postGalleryCreate(data))
        else dispatch(putGalleryUpdate(id, data))
    }

    const { galleryCreate, galleryUpdate, uploadImage, gallery } = useSelector((state) => state.gallery);
    useEffect(() => {
        if (galleryCreate) {
            notify(galleryCreate.message, galleryCreate.success ? 'success' : 'error')

            props.onHide()
        }
        return () => {
            dispatch(setPostGalleryCreate(null))
        }

    }, [galleryCreate])

    useEffect(() => {
        if (galleryUpdate) {
            notify(galleryUpdate.message, galleryUpdate.success ? 'success' : 'error')

            props.onHide()
            dispatch(getGalleries({}))
        }
        return () => {
            dispatch(setPutGalleryUpdate(null))
        }

    }, [galleryUpdate])

    useEffect(() => {
        setValue('title', gallery?.title)
        setValue('description', gallery?.description)
        setValue('image', gallery?.image)
        setValue('type_gallery', gallery?.type || 'image')
    }, [gallery])

    useEffect(() => {
        if (watch('upload')?.length > 0) {
            let formData = new FormData();
            formData.append('gallery', watch('upload')[0])
            console.log('acc', uploadImage)
            if (uploadImage) formData.append('old_img', uploadImage.result.filename)
            dispatch(galleryUploadImage(formData));
        }
    }, [watch('upload')])

    useEffect(() => {
        if (uploadImage) setValue('image', uploadImage.result?.filename)
        return () => {
            setGalleryUploadImage(null)
        }
    }, [uploadImage])

    useEffect(() => {
        console.log('acc', watch('type_gallery'))
        console.log('acci', watch('image'))
    }, [watch('type_gallery'), watch('image')])

    return (
        <Modal
            {...props}
            size={'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {action_title}
                </Modal.Title>
                <span className='btn' onClick={props.onHide}>&times;</span>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <div className="row">
                        <div className="col-md-4">
                            <TextInput
                                layout="vertical"
                                label="Jenis"
                                type="radio"
                                name="type_gallery"
                                register={register}
                                defaultValue={watch("type_gallery")}
                                errors={formState.errors}
                                options={
                                    [
                                        { value: 'image', label: 'Gambar' },
                                        { value: 'video', label: 'Youtube' },
                                    ]
                                }
                            />

                            {watch('type_gallery') === 'image' ?
                                <TextInput
                                    layout="vertical"
                                    label="File image"
                                    type="file"
                                    name="upload"
                                    register={register}
                                    errors={formState.errors}
                                />
                                : ''}
                            <TextInput
                                hidden={watch('type_gallery') === 'image'}
                                layout="vertical"
                                label="ID Video Youtube"
                                type="text"
                                name="image"
                                register={register}
                                errors={formState.errors}
                            />
                            {watch('image') && watch('type_gallery') === 'image' ?
                                <img src={image_path + watch('image')} className="img-fluid img-thumbnail" />
                                : ''}
                            {watch('image') && watch('type_gallery') === 'video' ?
                                <img src={`https://img.youtube.com/vi/${watch('image')}/default.jpg`} className="img-fluid img-thumbnail" />
                                : ''}

                            <TextInput
                                hidden
                                layout="vertical"
                                type="number"
                                name="order"
                                register={register}
                                errors={formState.errors}
                            />
                        </div>
                        <div className="col-md-8">
                            <TextInput
                                validations={{ required: true }}
                                layout="vertical"
                                label="Judul"
                                type="text"
                                name="title"
                                placeholder="Judul gallery"
                                register={register}
                                errors={formState.errors}
                            />

                            <TextInput
                                layout="vertical"
                                label="description"
                                type="textarea"
                                name="description"
                                placeholder="Keterangan"
                                register={register}
                                errors={formState.errors}
                            />
                        </div>
                    </div>
                    <hr />
                    <button type="submit" className="btn btn-primary btn-md">Simpan</button>
                </form>
            </Modal.Body>
            {/* <Modal.Footer>
            </Modal.Footer> */}
        </Modal>
    )
}

export default GalleryForm
