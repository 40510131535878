import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from '../../AuthWrapper';
import * as actions from '../../../redux/actions';
import { Link } from "react-router-dom";

import CardBasic from '../../../components/Cards/Basic';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import Searchbar from '../../../components/Searchbar';
import ModalConfirm from '../../../components/ModalConfirm';
import { formatCurrency, formatDate, image_path, notify } from '../../../constants/utility';
import PageHeading from '../../../components/PageHeading';
import { getGalleries, delGalleryDelete, setDelGalleryDelete, setGetGallery } from '../../../redux/actions/gallery';
import { Button, Tab, Tabs } from 'react-bootstrap';
import Progressbar from '../../../components/Progressbar';
import GalleryForm from './Form';
import Album from './Album';

const Gallery = (props) => {
    const { name, text, path, title, parent } = props.data
    const dispatch = useDispatch();

    const [search, setSearch] = useState("")
    const [filterred, setFilterred] = useState({})
    const [sort, setSort] = useState(null)
    const [page, setPage] = useState(1)
    const [sizePerPage, setSizePerPage] = useState(5)
    const [totalSize, setTotalSize] = useState(0)
    const [data, setData] = useState([])

    const { galleries, galleryDelete, galleryCreate, galleryUpdate } = useSelector(state => state.gallery)
    useEffect(() => {
        if (galleries) {
            setData(galleries.result?.rows)
            setTotalSize(galleries.result?.total_count)
        }

    }, [galleries])

    useEffect(() => {
        if (galleryDelete) {
            notify(galleryDelete.message, galleryDelete.success ? 'success' : 'error')

            setModalShowConfirm(false)
        }
        return () => {
            dispatch(setDelGalleryDelete(null))
        }
    }, [galleryDelete])

    const columns = [
        // {
        //     dataField: 'id',
        //     text: 'T',
        //     headerStyle: (colum, colIndex) => {
        //         return { width: '40px' };
        //     },
        //     headerClasses: (column, colIndex) => {
        //         return 'py-4 px-2'
        //     },
        //     classes: (column, colIndex) => {
        //         return 'p-2'
        //     },
        //     formatter: (val) => {
        //         return <input type="checkbox" value={val} />
        //     }
        // },
        {
            dataField: 'id',
            text: '',
            headerStyle: (colum, colIndex) => {
                return { width: '100px' };
            },
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (val, row) => {
                // return formatDate(val, false, false, true)
                return (
                    <>
                        <button onClick={() => editGallery(row)} className="btn btn-warning btn-sm m-1"><i className="fas fa-edit"></i> edit</button>
                        {/* <Link to={`/gallery/view/${val}`} className="btn btn-info btn-sm m-1"><i className="fas fa-search"></i> lihat</Link> */}
                        <span onClick={() => onDelete(row)} className="btn btn-danger btn-sm m-1"><i className="fas fa-trash"></i> hapus</span>
                    </>
                )
            }
        },
        {
            dataField: 'created_at',
            text: 'Tanggal',
            sort: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (val, row) => {
                return formatDate(val, false, false, false)
            }
        },
        {
            dataField: 'image',
            text: 'Thumbnail',
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (val, row) => {
                if (row.type === 'image')
                    return (<img className="img-thumbnail" width="120px" src={`${image_path}400-${val}`} alt={row.title} />)
                else return (<img className="img-thumbnail" width="120px" src={`https://img.youtube.com/vi/${val}/default.jpg`} alt={row.title} />)
            }
        },
        {
            dataField: 'title',
            text: 'Judul',
            sort: true,
            filterable: true,
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            }
        },
        {
            dataField: 'description',
            text: 'Keterangan',
            headerClasses: (column, colIndex) => {
                return 'py-4 px-2'
            },
            classes: (column, colIndex) => {
                return 'p-2'
            },
            formatter: (c, i) => { return c.substring(0, 100) }
        },

    ];

    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [])

    useEffect(() => {
        const params = {
            search: search,
            page_size: sizePerPage,
            page: page,
            filter: {},
            sort: sort
        }

        dispatch(getGalleries(params))
    }, [filterred, search, sort, page, sizePerPage, galleryDelete, galleryCreate, galleryUpdate])

    const { loading } = useSelector((state) => state.gallery);

    const handleTableChange = (type, { sortField, sortOrder, data, page, sizePerPage }) => {
        console.log("oceoce", type, page, sizePerPage)
        if (type === 'sort') {
            setSort({
                orderby: sortField,
                order: sortOrder
            })
        }

        if (type === 'pagination') {
            setPage(page)
            setSizePerPage(sizePerPage)
        }
    }

    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})

    const [modalShowGallery, setModalShowGallery] = useState(false)
    const [modalData, setModalData] = useState({})

    const tambahGallery = () => {
        dispatch(setGetGallery(null))
        setModalData({
            id: null,
            action_title: 'Tambah Gallery'
        })
        setModalShowGallery(true)
    }

    const editGallery = (item) => {
        dispatch(setGetGallery(item))
        setModalData({
            id: item.id,
            action_title: 'Edit Gallery'
        })
        setModalShowGallery(true)
    }

    const onDelete = (data) => {

        setModalShowConfirm(true)
        setModalOption({
            title: 'Konfirmasi hapus',
            content: (
                <div>
                    <p>Anda yakin akan menghapus <strong>{data?.title}</strong> ?</p>

                    <hr mb={2} mt={2} />
                    <Button className='float-left' size='sm' variant={`outline-secondary`} onClick={() => setModalShowConfirm(false)}>Batal</Button>
                    <Button className='float-right' variant={`danger`} size='sm'
                        onClick={() => dispatch(delGalleryDelete(data.id))}>Hapus</Button>
                </div>
            ),
            color: 'danger',
            size: 'md',
            data: data,
        })

    }

    const [tabKey, setTabKey] = useState('gallery');

    const onHideGallery = () => {
        setModalShowGallery(false)
        dispatch(setGetGallery(null))
        // cm
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} />
            <div className="row animate-col-size-change">
                <div className={`col-md-12`}>
                    <CardBasic nopadding>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={tabKey}
                            onSelect={(k) => setTabKey(k)}
                            className="mb-3"
                        >
                            <Tab eventKey="gallery" title="Gallery" className="pb-4">
                                <div className="d-flex p-4">
                                    <div>
                                        <button onClick={() => tambahGallery()} className="btn btn-primary-f btn-md mr-2">Tambah</button>

                                    </div>
                                    <div className="flex-grow-1">
                                        <Searchbar
                                            placeholder="Pencarian"
                                            onEnter={(data) => setSearch(data)} />
                                    </div>

                                </div>


                                <RemotePagination
                                    data={data}
                                    page={page}
                                    sizePerPage={sizePerPage}
                                    totalSize={totalSize}
                                    columns={columns}
                                    onTableChange={handleTableChange}
                                />
                                <GalleryForm
                                    show={modalShowGallery}
                                    data={modalData}
                                    onHide={() => onHideGallery()}
                                />
                            </Tab>
                            {/* <Tab eventKey="album" title="Album">
                                <Album />
                            </Tab> */}

                        </Tabs>

                    </CardBasic>
                </div>
            </div>
            <ModalConfirm
                show={modalShowConfirm}
                modalOption={modalOption}
                onHide={() => setModalShowConfirm(false)}
            />
        </div>
    )
}

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize, columns, onRowClick }) => {
    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];
    return (
        <div className="table-fixied custom-scrollbar table-responsive my-table">
            <BootstrapTable
                remote
                className="bt-table"
                bootstrap4
                hover
                bordered
                striped
                condensed
                noDataIndication={indication}
                bordered={false}
                keyField="id"
                defaultSorted={defaultSorted}
                data={data}
                columns={columns}
                pagination={paginationFactory({ page, sizePerPage, totalSize })}
                onTableChange={onTableChange}
            />
        </div>
    )
}

export default AuthWrapper(Gallery)
