import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../components/Cards/Basic'
import ModalConfirm from '../../../components/ModalConfirm';
import PageHeading from '../../../components/PageHeading';
import Progressbar from '../../../components/Progressbar';
import TextInput from '../../../components/TextInput';
import { setMenuActive } from '../../../redux/actions';
import { deleteSlide, getSlideAll, postSlideOrdering, setGetSlide } from '../../../redux/actions/slide';
import { AuthWrapper } from '../../AuthWrapper';
import SlideForm from './Form';
import { Button } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { image_path } from '../../../constants/utility';

const Slide = (props) => {
    const { name, text, path, title, parent } = props.data
    const [modalShow, setModalShow] = useState(false)
    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})
    const [modalData, setModalData] = useState({})
    const dispatch = useDispatch()
    const { loading, slides, slideDelete, slideOrdering, slideUpdate, slideCreate } = useSelector((state) => state.slide);

    const [arrSlide, setArrSlide] = useState([])

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [])

    useEffect(() => {
        if (slides) setArrSlide(slides.result)
    }, [slides])

    const tambahSlide = () => {
        dispatch(setGetSlide(null))
        setModalData({
            id: null,
            action_title: 'Tambah Slide'
        })
        setModalShow(true)
    }

    const editSlide = (item) => {
        dispatch(setGetSlide(item))
        setModalData({
            id: item.id,
            action_title: 'Edit Slide'
        })
        setModalShow(true)
    }

    useEffect(() => {
        dispatch(getSlideAll({}))
    }, [])

    useEffect(() => {
        dispatch(getSlideAll({}))
        setModalShowConfirm(false)
    }, [slideDelete])

    useEffect(() => {
        dispatch(getSlideAll({}))
    }, [slideOrdering, slideCreate, slideUpdate])

    const onHide = () => {
        setModalShow(false)
        dispatch(setGetSlide(null))
    }

    const onDelete = (data) => {

        setModalShowConfirm(true)
        setModalOption({
            title: 'Konfirmasi hapus',
            content: (
                <div>
                    <p>Anda yakin akan menghapus <strong>{data?.title}</strong> ?</p>

                    <hr mb={2} mt={2} />
                    <Button className='float-left' size='sm' variant={`outline-secondary`} onClick={() => setModalShowConfirm(false)}>Batal</Button>
                    <Button className='float-right' variant={`danger`} size='sm'
                        onClick={() => dispatch(deleteSlide(data.id))}>Hapus</Button>
                </div>
            ),
            color: 'danger',
            size: 'md',
            data: data,
        })

    }

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from(arrSlide);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setArrSlide(items)
        let newOrders = []
        items.map((item, key) => {
            newOrders.push({ id: item.id, order: key })
        })

        dispatch(postSlideOrdering({ orders: newOrders }))
        // console.log('acc', newOrders)
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} />
            <div className="row animate-col-size-change">
                <div className={`col-md-12`}>
                    <CardBasic>
                        <div className="overflow-hidden">
                            <span onClick={() => tambahSlide()} className="btn btn-se btn-info">Tambah Slide</span>
                        </div>
                        <hr />
                        <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId={'dropable1'} key={'dropable1'}>
                                {(provided) => (
                                    <div
                                        className="ItemGrid"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>

                                        {arrSlide.map((item, index) => {
                                            let drgID = "" + item.id
                                            return (
                                                // <CardFn item={item} />
                                                <Draggable key={item.id} draggableId={drgID} index={index}>
                                                    {(provided) => (
                                                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                            <div className="card mb-2">
                                                                <div className="card-body">
                                                                    <div className="mb-1 overflow-hidden">
                                                                        <label>Slide #{index + 1}</label>
                                                                        <span onClick={() => editSlide(item)} className="btn btn-warning btn-sm float-right ml-1"><i className="fas fa-fw fa-edit"></i> Edit</span>
                                                                        <span onClick={() => onDelete(item)} className="btn btn-danger btn-sm float-right ml-1"><i className="fas fa-fw fa-trash"></i> Delete</span>
                                                                    </div>
                                                                    <hr />
                                                                    <div className='d-flex'>
                                                                        <img
                                                                            width="250"
                                                                            src={`${image_path}600-${item.image}`}
                                                                            className='img-thumbnail' />
                                                                        <div className="ml-4">
                                                                            <h5 className="card-title">{item.title}</h5>
                                                                            <p className="card-text">{item.description}</p>
                                                                            <a href="#" className="card-link">{item.link}</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })
                                        }
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>

                    </CardBasic>
                    <SlideForm
                        show={modalShow}
                        data={modalData}
                        onHide={() => onHide()}
                    />
                </div>
            </div >
            <ModalConfirm
                show={modalShowConfirm}
                modalOption={modalOption}
                onHide={() => setModalShowConfirm(false)}
            />
        </div >
    )
}

export default AuthWrapper(Slide)
