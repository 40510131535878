import React from 'react'
import { Link } from 'react-router-dom'

const CardBasic = (props) => {
  const {
    title, nopadding, hidden, goBack
  } = props


  return (
    <div className="card shadow mb-4" hidden={hidden}>
      {title ?
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
            {goBack ? <Link to={goBack}><span className="btn btn-primary btn-sm mr-3" style={{ width: "50px" }}><i className="fas fa-arrow-left"></i></span></Link> : ""}
            {title}
          </h6>
        </div>
        : ""}
      <div className={`card-body ${nopadding ? 'p-0' : ''}`}>
        {props.children}
      </div>
    </div>
  )
}

export default CardBasic