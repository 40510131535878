import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    teacherCreate: null,
    teacherUpdate: null,
    teacherDelete: null,
    teacher: null,
    teachers: null,
    importCsv: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const teacherCreate = (state, data) => {
    return updateObject(state, {
        teacherCreate: data,
    });
}

const getTeacher = (state, data) => {
    return updateObject(state, {
        teacher: data,
    });
}

const getTeachers = (state, data) => {
    return updateObject(state, {
        teachers: data,
    });
}

const teacherUpdate = (state, data) => {
    return updateObject(state, {
        teacherUpdate: data,
    });
}

const teacherDelete = (state, data) => {
    return updateObject(state, {
        teacherDelete: data,
    });
}

const setImportCsv = (state, data) => {
    return updateObject(state, {
        importCsv: data,
    });
}

const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.POST_IMPORT_CSV:
            return setImportCsv(state, data);
        case actionTypes.TEACHER_DELETE:
            return teacherDelete(state, data);
        case actionTypes.TEACHER_UPDATE:
            return teacherUpdate(state, data);
        case actionTypes.GET_TEACHERS:
            return getTeachers(state, data);
        case actionTypes.GET_TEACHER:
            return getTeacher(state, data);
        case actionTypes.TEACHER_CREATE:
            return teacherCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
