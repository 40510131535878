import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetRole = (payload) => {
    return {
        type: actionTypes.GET_ROLE,
        data: payload,
    };
};

export const getRoleOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/master/role/` + data);
        dispatch(setGetRole(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetRoles = (payload) => {
    return {
        type: actionTypes.GET_ROLES,
        data: payload,
    };
};

export const getRoleAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/master/role`, { params });
        dispatch(setGetRoles(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setRoleCreate = (payload) => {
    return {
        type: actionTypes.ROLE_CREATE,
        data: payload,
    };
}

export const postCreateRole = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/master/role`, data);
        dispatch(setOnloading(null))
        dispatch(setRoleCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setRoleUpdate = (payload) => {
    return {
        type: actionTypes.ROLE_UPDATE,
        data: payload,
    };
}

export const putUpdateRole = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/master/role/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setRoleUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setRoleDelete = (payload) => {
    return {
        type: actionTypes.ROLE_DELETE,
        data: payload,
    };
}

export const deleteRole = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/master/role/${id}`);
        dispatch(setOnloading(null))
        dispatch(setRoleDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostImportCsv = (payload) => {
    return {
        type: actionTypes.POST_IMPORT_CSV,
        data: payload,
    };
};


export const postImportCsv = (data) => async (dispatch) => {
    try {

        dispatch(setOnloading(true))
        const result = await http.post(`/master/role-import`, data);
        console.log("aaww", result)
        dispatch(setPostImportCsv(result));
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetAccesses = (payload) => {
    return {
        type: actionTypes.GET_ACCESSES,
        data: payload,
    };
};

export const getAccessAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/master/role/access`);
        dispatch(setGetAccesses(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}