import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../../redux/actions";

const Topbar = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.auth);

  const clickMenuOpen = () => {
    dispatch(actions.setExpandSidebar());
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      {/* <!-- Sidebar Toggle (Topbar) --> */}
      <button
        hidden={data?.user?.user_type !== 'administrator'}
        onClick={() => {
          clickMenuOpen();
        }}
        id="sidebarToggleTop"
        className="btn btn-link d-md-none rounded-circle mr-3"
      >
        {/* className="btn btn-link d-md-none rounded-circle mr-3" */}
        <i className="fa fa-bars"></i>
      </button>
      <h3 className="site-title">SMP Negeri 33 Surabaya</h3>

      {/* <!-- Topbar Search --> */}
      {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button">
              <i className="fas fa-search fa-sm"></i>
            </button>
          </div>
        </div>
      </form> */}

      {/* <!-- Topbar Navbar --> */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="userDropdownX"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="mr-2 d-none d-lg-inline text-gray-600 small">
              {data?.nama}
            </span>
            <img
              className="img-profile rounded-circle"
              src={data?.user?.photo || "https://source.unsplash.com/QAB-WJcbgJk/60x60"}
            />
          </a>
          {/* <!-- Dropdown - User Information --> */}
          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdownX"
          >
            <Link className="dropdown-item" to={"/account/profile"}>
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </Link>
            <Link className="dropdown-item" to={"/account/change-password"}>
              <i className="fas fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
              Ubah Password
            </Link>
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item"
              href="#"
              data-toggle="modal"
              data-target="#logoutModal"
              onClick={() => dispatch(actions.logout())}
            >
              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Topbar;
