import * as actionTypes from '../../../constants/actionTypes';
import http from "../../../services/NetworkService";


// export const updateAccountProfile = (id, data) => async (dispatch) => {

export const setPostUploadImage = (payload) => {
    return {
        type: actionTypes.UPLOAD_IMAGE,
        data: payload,
    };
};

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetPost = (payload) => {
    return {
        type: actionTypes.GET_POST,
        data: payload,
    };
};

export const getPostOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/post/` + data);
        dispatch(setGetPost(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetPosts = (payload) => {
    return {
        type: actionTypes.GET_POSTS,
        data: payload,
    };
};

export const getPostAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/post/all/` + data.type, { params });
        dispatch(setGetPosts(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const postUploadImage = (data) => async (dispatch) => {
    try {
        const result = await http.post(`/post/upload-image`, data);
        console.log('alsocodes http', result)
        dispatch(setPostUploadImage(result));
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostCreate = (payload) => {
    return {
        type: actionTypes.POST_CREATE,
        data: payload,
    };
}

export const postCreatePost = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/post`, data);
        dispatch(setOnloading(null))
        dispatch(setPostCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostUpdate = (payload) => {
    return {
        type: actionTypes.POST_UPDATE,
        data: payload,
    };
}

export const putUpdatePost = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/post/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setPostUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setPostDelete = (payload) => {
    return {
        type: actionTypes.POST_DELETE,
        data: payload,
    };
}

export const deletePost = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/post/${id}`);
        dispatch(setOnloading(null))
        dispatch(setPostDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

