import * as actionTypes from '../../../constants/actionTypes';
import http from "../../../services/NetworkService";

export const setOnloading = (payload) => { return { type: actionTypes.ON_LOADING, data: payload } };

export const setGetMenu = (payload) => { return { type: actionTypes.MENU, data: payload } }
export const getMenu = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/setting/menu/${id}`);
        dispatch(setGetMenu(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetMenus = (payload) => { return { type: actionTypes.MENUS, data: payload } }
export const getMenus = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/setting/menu`, {
            headers: {
                type: id
            }
        });
        dispatch(setGetMenus(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostMenuCreate = (payload) => { return { type: actionTypes.MENU_CREATE, data: payload } }
export const postMenuCreate = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/setting/menu`, data);
        dispatch(setPostMenuCreate(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPutMenuUpdate = (payload) => { return { type: actionTypes.MENU_UPDATE, data: payload } }
export const putMenuUpdate = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/setting/menu/${id}`, data);
        dispatch(setPutMenuUpdate(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setDeleteMenuDelete = (payload) => { return { type: actionTypes.MENU_DELETE, data: payload } }
export const deleteMenuDelete = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/setting/menu/${id}`);
        dispatch(setDeleteMenuDelete(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetStructureMenus = (payload) => { return { type: actionTypes.STRUCTURE_MENUS, data: payload } }
export const getStructureMenus = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/setting/menu-structure`, {
            headers: {
                type: id
            }
        });
        dispatch(setGetStructureMenus(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostStructureMenus = (payload) => { return { type: actionTypes.STRUCTURE_MENU_UPDATE, data: payload } }
export const postStructureMenus = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/setting/menu-structure`, data);
        dispatch(setPostStructureMenus(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetContentOpts = (payload) => { return { type: actionTypes.CONTENT_OPTS, data: payload } }
export const getContentOpts = () => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/setting/menu/content-opt`);
        dispatch(setGetContentOpts(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}