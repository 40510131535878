import { Editor } from '@tinymce/tinymce-react';

// TinyMCE so the global var exists
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce';

// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/help';

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

import React, { useEffect, useState } from 'react'
import { api_url, getAccessToken } from '../../constants/utility';

const TinyMceEditor = (props) => {

    const handleEditorChange = (editor) => props.onChange(editor);

    useEffect(() => {
        // console.log('awowow', props)
    }, [props])

    const accessToken = getAccessToken()

    const image_upload_handler = (blobInfo, success, failure, progress) => {
        var xhr, formData;


        xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', `${api_url}/post/upload-image-content`);
        xhr.setRequestHeader('Authorization', accessToken)

        xhr.upload.onprogress = function (e) {
            progress(e.loaded / e.total * 100);
        };

        xhr.onload = function () {
            var json;

            if (xhr.status === 403) {
                failure('HTTP Error: ' + xhr.status, { remove: true });
                return;
            }

            if (xhr.status < 200 || xhr.status >= 300) {
                failure('HTTP Error: ' + xhr.status);
                return;
            }

            json = JSON.parse(xhr.responseText);

            // if (!json || typeof json.location != 'string') {
            //     failure('Invalid JSON: ' + xhr.responseText);
            //     return;
            // }

            success(json.result.location);
        };

        xhr.onerror = function () {
            failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
        };

        formData = new FormData();
        formData.append('img', blobInfo.blob(), blobInfo.filename());

        xhr.send(formData);

    }

    return (
        <div>

            <Editor

                value={props.value}
                onEditorChange={handleEditorChange}
                init={{
                    min_height: 400,
                    selector: "textarea",
                    menubar: false,
                    plugins: "link image code",
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | link image | code',
                    toolbar_location: 'top',
                    skin: 'outside',
                    content_css: false,
                    content_style: [contentCss, contentUiCss].join('\n'),
                    image_title: true,
                    automatic_uploads: true,
                    file_picker_types: 'image',
                    images_upload_handler: image_upload_handler,
                    file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');
                        input.onchange = function () {
                            var file = this.files[0];

                            var reader = new FileReader();
                            reader.onload = function () {
                                /*
                                Note: Now we need to register the blob in TinyMCEs image blob
                                registry. In the next release this part hopefully won't be
                                necessary, as we are looking to handle it internally.
                                */
                                var id = 'blobid' + (new Date()).getTime();
                                var blobCache = tinymce.activeEditor.editorUpload.blobCache;
                                var base64 = reader.result.split(',')[1];
                                var blobInfo = blobCache.create(id, file, base64);
                                blobCache.add(blobInfo);

                                /* call the callback and populate the Title field with the file name */
                                cb(blobInfo.blobUri(), { title: file.name });
                            };
                            reader.readAsDataURL(file);
                        };

                        input.click();
                    },
                }}
            />
        </div>
    )
}

export default TinyMceEditor
