import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";
import axios from "axios";
import { api_url } from "../../../../constants/utility";
import store from "../../../../redux/store";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetStudent = (payload) => {
    return {
        type: actionTypes.GET_STUDENT,
        data: payload,
    };
};

export const getStudentOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/master/student/` + data);
        dispatch(setGetStudent(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetStudents = (payload) => {
    return {
        type: actionTypes.GET_STUDENTS,
        data: payload,
    };
};

export const getStudentAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/master/student`, { params });
        dispatch(setGetStudents(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setStudentCreate = (payload) => {
    return {
        type: actionTypes.STUDENT_CREATE,
        data: payload,
    };
}

export const postCreateStudent = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/student`, data);
        dispatch(setOnloading(null))
        dispatch(setStudentCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setStudentUpdate = (payload) => {
    return {
        type: actionTypes.STUDENT_UPDATE,
        data: payload,
    };
}

export const putUpdateStudent = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/master/student/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setStudentUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setStudentDelete = (payload) => {
    return {
        type: actionTypes.STUDENT_DELETE,
        data: payload,
    };
}

export const deleteStudent = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/master/student/${id}`);
        dispatch(setOnloading(null))
        dispatch(setStudentDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostImportCsv = (payload) => {
    return {
        type: actionTypes.POST_IMPORT_CSV,
        data: payload,
    };
};


export const postImportCsv = (data) => async (dispatch) => {
    try {

        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/student-import`, data);
        console.log("aaww", result)
        dispatch(setPostImportCsv(result));
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
