import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import { getLessonClassByClassYears, getLessonClassOne, getLessonClassOptForm, postCreateLessonClass, putUpdateLessonClass, setGetLessonClass, setLessonClassCreate, setLessonClassUpdate } from '../../../../../redux/actions/sia-activity/lesson-class'
import { AuthWrapper } from '../../../../AuthWrapper'
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next'
import { notify } from '../../../../../constants/utility'

const LessonClassForm = (props) => {
    const { mass } = props
    const { name, text, path, title, parent } = props.data
    const { loading, lessonClass, lessonClassCreate, lessonClassUpdate, lessonClassOptForm,
        lessonClassByClassYear
    } = useSelector((state) => state.lessonClass);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const [eduyearOption, setEduyearOption] = useState([])
    const [semesterOption, setSemesterOption] = useState([])
    const [classroomOption, setClassroomOption] = useState([])
    const [lessonOption, setLessonOption] = useState([])
    const [teacherOption, setTeacherOption] = useState([])

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetLessonClass(null))
        dispatch(getLessonClassOptForm())
    }, [])

    const { id } = useParams()
    useEffect(() => {
        if (id) {
            dispatch(getLessonClassOne(id))
        }
    }, [id])

    useEffect(() => {
        // setEduyearOption([{ value: lessonClassOptForm?.result?.eduyears?.id, label: lessonClassOptForm?.result?.eduyears?.name }])
        // setSemesterOption([{ value: lessonClassOptForm?.result?.semesters?.id, label: lessonClassOptForm?.result?.semesters?.name }])

        setEduyearOption(lessonClassOptForm?.result?.eduyears?.map(item => { return { value: item.id, label: item.name } }, []))
        setSemesterOption(lessonClassOptForm?.result?.semesters?.map(item => { return { value: item.id, label: item.name } }, []))

        let classrooms = lessonClassOptForm?.result?.classrooms.map(item => {
            return { value: item.id, label: item.name }
        })
        setClassroomOption(classrooms)

        let lessons = lessonClassOptForm?.result?.lessons.map(item => {
            return { value: item.id, label: item.name }
        })
        setLessonOption(lessons)

        let teachers = lessonClassOptForm?.result?.teachers.map(item => {
            return { value: item.id, label: item.name }
        })
        setTeacherOption(teachers)

    }, [lessonClassOptForm])

    useEffect(() => {
        // setEduyearOption([{ value: lessonClass?.result?.eduyear?.id, label: lessonClass?.result?.eduyear?.name }])
        // setSemesterOption([{ value: lessonClass?.result?.semester?.id, label: lessonClass?.result?.semester?.name }])
        if (lessonClass) {
            setValue('eduyear_id', { value: lessonClass?.result?.eduyear?.id, label: lessonClass?.result?.eduyear?.name })
            setValue('semester_id', { value: lessonClass?.result?.semester?.id, label: lessonClass?.result?.semester?.name })
        }

        // let lesson = lessonOption?.filter(item => item.value === lessonClass?.result?.lesson?.id)
        // setValue('lesson_id', lesson?.[0] || '')

        // let teacher = teacherOption?.filter(item => item.value === lessonClass?.result?.teacher?.id)
        // setValue('teacher_id', teacher?.[0] || '')

    }, [lessonClass])

    useEffect(() => {
        if (classroomOption) {
            let classroom = classroomOption.filter(item => item.value === lessonClass?.result?.classroom?.id)
            setValue('classroom_id', classroom?.[0] || '')
            console.log('awwx', classroom,)
        }
    }, [classroomOption, lessonClass])

    useEffect(() => {
        if (teacherOption) {
            let teacher = teacherOption.filter(item => item.value === lessonClass?.result?.teacher?.id)
            setValue('teacher_id', teacher?.[0] || '')
            console.log('awwx', teacher,)
        }
    }, [teacherOption, lessonClass])

    useEffect(() => {
        if (lessonOption) {
            let lesson = lessonOption.filter(item => item.value === lessonClass?.result?.lesson?.id)
            setValue('lesson_id', lesson?.[0] || '')
            console.log('awwx', lesson,)
        }
    }, [lessonOption, lessonClass])

    useEffect(() => {
        if (lessonClassCreate) {
            notify(lessonClassCreate.message, lessonClassCreate.success ? 'success' : 'error')

            if (!mass) navigate("/sia/activity/lesson-class/edit/" + lessonClassCreate.result?.id, { replace: true })
            else {
                const params = {
                    classroom_id: watch("classroom_id").value,
                    eduyear_id: watch("eduyear_id").value,
                    semester_id: watch("semester_id").value,
                }
                dispatch(getLessonClassByClassYears(params))
            }
        }

        return () => {
            dispatch(setLessonClassCreate(null))
        }
    }, [lessonClassCreate])

    useEffect(() => {
        if (lessonClassUpdate) {
            notify(lessonClassUpdate.message, lessonClassUpdate.success ? 'success' : 'error')

            dispatch(getLessonClassOne(id))
            dispatch(setLessonClassUpdate(null))
        }

    }, [lessonClassUpdate])

    const submitForm = (data) => {
        // console.log(data)
        // return
        if (loading === true) return;
        let newData = {}
        newData.classroom_id = data.classroom_id.value;
        newData.eduyear_id = data.eduyear_id.value;
        newData.semester_id = data.semester_id.value;
        newData.add_all_student = data.add_all_student
        newData.lesson_teachers = []
        if (Array.isArray(data.lesson_id)) {
            data.lesson_id.map((item, key) => {
                newData.lesson_teachers.push({
                    id: data.id[key],
                    is_delete: data.is_delete[key] === 'false' ? false : true,
                    lesson_id: item.value,
                    teacher_id: data.teacher_id[key].value
                })
            })
        } else {
            newData.lesson_teachers.push({ lesson_id: data.lesson_id.value, teacher_id: data.teacher_id.value })
        }
        console.log(newData)

        if (id) dispatch(putUpdateLessonClass(id, newData))
        else dispatch(postCreateLessonClass(newData))
    }

    const calonPesertaColumns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
    ]

    const [showModal, setShowModal] = useState(false)
    const [calonSelected, setCalonSelected] = useState([])
    const [peserta, setPeserta] = useState([])
    useEffect(() => {
        setPeserta([])
        setPeserta(lessonClassByClassYear?.result?.peserta || [])
        console.log('iam fired', peserta)
    }, [lessonClassByClassYear])

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) setCalonSelected(prev => [...prev, row])
        else setCalonSelected(calonSelected.filter(x => x.id !== row.id))
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) setCalonSelected(rows)
        else setCalonSelected([])
    }

    const tambahkanPeserta = () => {
        setShowModal(false)
        let temp = peserta
        setPeserta([])
        calonSelected.map((item, key) => {
            setPeserta(prev => [...prev, {
                'id': null,
                'lesson.id': item.id,
                'lesson.name': item.name,
            }])
        })
        temp.map(item => setPeserta(prev => [...prev, item]))

    }

    const hapusPeserta = (id) => {

        const newPeserta = peserta.map(item => {
            console.log('xxx', item['lesson.id'], id)
            if (item['lesson.id'] === id) {
                item['is_delete'] = item['is_delete'] === true ? false : true
            }
            return item
        })
        setPeserta(newPeserta)
    }

    useEffect(() => {
        console.log("calon ", calonSelected)
    }, [calonSelected])

    const [pesertaIds, setPesertaIds] = useState([])
    useEffect(() => {
        let ids = peserta?.map(item => item['lesson.id'])
        setPesertaIds(ids)
        console.log("peserta", ids, peserta)
        for (var i = 0; i < peserta.length; i++) {
            console.log(`lesson_id.${i}`)
            setValue(`id.${i}`, peserta[i]['id'] || 0)
            setValue(`is_delete.${i}`, peserta[i]['is_delete'] || 'false')
            setValue(`lesson_id.${i}`, { value: peserta[i]['lesson.id'], label: peserta[i]['lesson.name'] })
            setValue(`teacher_id.${i}`, peserta[i]['teacher.id'] ? { value: peserta[i]['teacher.id'], label: peserta[i]['teacher.name'] } : '')
        }
    }, [peserta])

    useEffect(() => {
        if ("awowowo", watch("eduyear_id") && watch("classroom_id") && watch("semester_id") && mass) {
            const params = {
                classroom_id: watch("classroom_id").value,
                eduyear_id: watch("eduyear_id").value,
                semester_id: watch("semester_id").value,
            }
            dispatch(getLessonClassByClassYears(params))
        }
    }, [watch("eduyear_id"), watch("classroom_id"), watch("semester_id")])

    const onButtonTambahClick = () => {
        setCalonSelected([])
        setShowModal(true)
    }
    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <Controller
                                    name="eduyear_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Tahun Ajaran"
                                            type="select"
                                            // name="code"
                                            placeholder="Tahun Ajaran"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={eduyearOption}
                                        />
                                    }
                                />

                                <Controller
                                    name="semester_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Semester"
                                            type="select"
                                            // name="code"
                                            placeholder="Semester"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={semesterOption}
                                        />
                                    }
                                />
                                <Controller
                                    name="classroom_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Kelas & Rombel"
                                            type="select"
                                            name="classroom_id"
                                            placeholder="Kelas & Rombel"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={classroomOption}
                                        />
                                    }
                                />

                                {!mass ?
                                    <>
                                        <Controller
                                            name="lesson_id"
                                            control={control}
                                            rules={{ required: 'Harus diisi' }}
                                            render={({ field }) =>
                                                <TextInput
                                                    layout="horizontal"
                                                    label="Mata Pelajaran"
                                                    type="select"
                                                    // name="code"
                                                    placeholder="Mata Pelajaran"
                                                    register={register}
                                                    field={field}
                                                    errors={formState.errors}
                                                    options={lessonOption}
                                                />
                                            }
                                        />

                                        <Controller
                                            name="teacher_id"
                                            control={control}
                                            rules={{ required: 'Harus diisi' }}
                                            render={({ field }) =>
                                                <TextInput
                                                    layout="horizontal"
                                                    label="Guru Pengampu"
                                                    type="select"
                                                    // name="code"
                                                    placeholder="Guru Pengampu"
                                                    register={register}
                                                    field={field}
                                                    errors={formState.errors}
                                                    options={teacherOption}
                                                />
                                            }
                                        />
                                    </> : ""
                                }
                                {!id ?
                                    <TextInput
                                        label="Semua Siswa"
                                        layout="horizontal"
                                        type="checkbox"
                                        defaultValue={watch("add_all_student")}
                                        name={`add_all_student`}
                                        placeholder={`Tambahkan Pelajaran Ke Semua Peserta Kelas`}
                                        register={register}
                                        errors={formState.errors}
                                    /> : ""}

                            </div>
                        </div>
                        {mass ?
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <h6 className="m-0 font-weight-bold text-secondary">
                                            Mata Pelajaran & Guru
                                        </h6>
                                        <span onClick={() => onButtonTambahClick()} className="btn btn-sm btn-info">Tambah</span>
                                    </div>
                                    <hr />

                                </div>
                                <div className="col-md-12">
                                    <table className="table table-striped table-form">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "10%" }}>No</th>
                                                <th style={{ width: "45%" }}>Mata Pelajaran</th>
                                                <th>Guru Pengampu</th>
                                                <th style={{ width: "12%" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {peserta.length === 0 ?
                                                <tr><td colspan="4">Tidak ada data</td></tr>
                                                :

                                                peserta.map((item, key) => {
                                                    // console.log("item", item)
                                                    // console.log("wowowowo", pesertaIds.includes(item['lesson.id']))
                                                    // if (pesertaIds.includes(item['lesson.id'])) return ("")
                                                    // else
                                                    return (
                                                        <tr key={key}>
                                                            <td className={item.is_delete ? 'row-deleted' : ''}>{key + 1}
                                                                <TextInput
                                                                    hidden
                                                                    layout="vertical"
                                                                    type="text"
                                                                    name={`id.${key}`}
                                                                    register={register}
                                                                    errors={formState.errors}
                                                                />
                                                                <TextInput
                                                                    hidden
                                                                    layout="vertical"
                                                                    type="text"
                                                                    name={`is_delete.${key}`}
                                                                    register={register}
                                                                    errors={formState.errors}
                                                                />
                                                            </td>
                                                            <td className={item.is_delete ? 'row-deleted' : ''}>
                                                                <Controller
                                                                    name={`lesson_id.${key}`}
                                                                    control={control}
                                                                    rules={{ required: 'Harus diisi' }}
                                                                    render={({ field }) =>
                                                                        <TextInput
                                                                            layout="vertical"
                                                                            type="select"
                                                                            // name="code"
                                                                            placeholder="Mata Pelajaran"
                                                                            register={register}
                                                                            field={field}
                                                                            errors={formState.errors}

                                                                            options={[
                                                                                { value: item['lesson.id'], label: item['lesson.name'] }
                                                                            ]}
                                                                        />
                                                                    }
                                                                />
                                                            </td>
                                                            <td className={item.is_delete ? 'row-deleted' : ''}>
                                                                <Controller
                                                                    name={`teacher_id.${key}`}
                                                                    control={control}
                                                                    rules={{ required: 'Harus diisi' }}
                                                                    render={({ field }) =>
                                                                        <TextInput
                                                                            layout="vertical"
                                                                            type="select"
                                                                            // name="code"
                                                                            placeholder="Guru Pengampu"
                                                                            register={register}
                                                                            field={field}
                                                                            errors={formState.errors}
                                                                            options={teacherOption}
                                                                        />
                                                                    }
                                                                />
                                                            </td>
                                                            <td>
                                                                <span
                                                                    onClick={() => hapusPeserta(item['lesson.id'])}
                                                                    className="btn btn-danger btn-sm">
                                                                    {item.is_delete ? 'Batal Hapus' : 'Hapus'}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>


                                <Modal
                                    {...props}
                                    show={showModal}
                                    size={'lg'}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    onHide={() => setShowModal(false)}
                                >
                                    <Modal.Header className='text-primary'>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            Tambah Mata Pelajaran
                                        </Modal.Title>
                                        <span className='btn' onClick={() => setShowModal(false)}>&times;</span>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <BootstrapTable
                                            className="bt-table"
                                            bootstrap4
                                            hover
                                            bordered
                                            striped
                                            condensed
                                            noDataIndication={() => { return ('Tidak ada peserta') }}
                                            keyField="id"
                                            data={lessonClassByClassYear?.result?.calon_peserta?.filter(item => !pesertaIds.includes(item.id)) || []}
                                            columns={calonPesertaColumns}
                                            selectRow={
                                                {
                                                    mode: 'checkbox',
                                                    clickToSelect: true,
                                                    onSelect: handleOnSelect,
                                                    onSelectAll: handleOnSelectAll
                                                }
                                            }
                                        />
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <button
                                            onClick={() => tambahkanPeserta()}
                                            className="btn btn-primary btn-sm"
                                            disabled={calonSelected.length === 0}>Tambahkan {calonSelected.length} Mata Pelajaran</button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                            : ""
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(LessonClassForm)
