import * as actionTypes from "../../../constants/actionTypes";
import { updateObject } from "../../../constants/utility";

const initialState = {
    loading: null,
    siaDashboard: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}

const getSiaDashboard = (state, data) => {
    return updateObject(state, {
        siaDashboard: data,
    });
}


const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.GET_SIA_DASHBOARD:
            return getSiaDashboard(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
