import { combineReducers } from "redux";

// external imports
// import { MenuOpenReducer } from "./MenuOpenReducer";
// import informasiReducer from "./informasi";
// import accountReducer from "./account";
// import reportReducer from "./report";
// import transactionReducer from "./transaction";


import authReducer from "./auth";
import alertReducer from "./alert";
import menuReducer from "./menu";
import postReducer from "./post";
import slideReducer from "./slide";
import galleryReducer from "./gallery";
import generalReducer from "./general";
import webMenuReducer from "./web-menu";
import lessonReducer from "./sia-master/lesson";
import classroomReducer from "./sia-master/classroom";
import eduyearReducer from "./sia-master/eduyear";
import studentReducer from "./sia-master/student";
import teacherReducer from "./sia-master/teacher";
import siaSettingReducer from "./sia-master/setting";
import lessonClassReducer from "./sia-activity/lesson-class";
import studentClassReducer from "./sia-activity/student-class";
import lessonClassStudentReducer from "./sia-activity/lesson-class-student";
import teacherClassLessonReducer from "./sia-activity/teacher-class-lesson";
import studentClassLessonReducer from "./sia-activity/student-class-lesson";
import siaDashboardReducer from "./sia-dashboard";
import siaRaporReducer from "./sia-activity/rapor";
import siaGraduationReducer from "./sia-activity/graduation";

import userReducer from "./master/user";
import roleReducer from "./master/role";
import accountReducer from "./account";

const reducers = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  menu: menuReducer,
  post: postReducer,
  slide: slideReducer,
  gallery: galleryReducer,
  general: generalReducer,
  webMenu: webMenuReducer,
  lesson: lessonReducer,
  classroom: classroomReducer,
  eduyear: eduyearReducer,
  student: studentReducer,
  teacher: teacherReducer,
  siaSettings: siaSettingReducer,
  lessonClass: lessonClassReducer,
  studentClass: studentClassReducer,
  lessonClassStudent: lessonClassStudentReducer,
  teacherClassLesson: teacherClassLessonReducer,
  studentClassLesson: studentClassLessonReducer,
  siaDashboard: siaDashboardReducer,
  siaRapor: siaRaporReducer,
  siaGraduation: siaGraduationReducer,

  user: userReducer,
  role: roleReducer,
  account: accountReducer,
});

export default reducers;
