import * as actionTypes from '../../../constants/actionTypes';
import { notify } from '../../../constants/utility';
import http from "../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const download = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))

        const result = await http({
            url: `/download-attachment?file=${data}`,
            method: "GET",
            params: '',
            responseType: "blob"
        });

        if (result) {
            const blobUrl = window.URL.createObjectURL(new Blob([result]));
            const link = document.createElement("a");
            link.href = blobUrl;
            link.setAttribute("download", data);
            document.body.appendChild(link);

            // Download file
            link.click();
            setTimeout(() => {
                link.remove()
                notify("Download berhasil", 'success')
                dispatch(setOnloading(null))
            }, 1000);
        }

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}