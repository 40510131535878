import { Button } from 'bootstrap'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { notify } from '../../../../../constants/utility'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import { getLessonOne, postCreateLesson, putUpdateLesson, setGetLesson, setLessonCreate, setLessonUpdate } from '../../../../../redux/actions/sia-master/lesson'
import { AuthWrapper } from '../../../../AuthWrapper'

const LessonMasterForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, lesson, lessonCreate, lessonUpdate } = useSelector((state) => state.lesson);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetLesson(null))
    }, [])

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            dispatch(getLessonOne(id))
        }
    }, [id])

    useEffect(() => {
        setValue('code', lesson?.result?.code || '')
        setValue('name', lesson?.result?.name || '')
    }, [lesson])

    useEffect(() => {
        if (lessonCreate) {
            notify(lessonCreate.message, lessonCreate.success ? 'success' : 'error')
            navigate("/sia/master/lesson/edit/" + lessonCreate.result?.id, { replace: true })
        }

        return () => {
            dispatch(setLessonCreate(null))
        }
    }, [lessonCreate])

    useEffect(() => {
        if (lessonUpdate) {
            notify(lessonUpdate.message, lessonUpdate.success ? 'success' : 'error')
            dispatch(getLessonOne(id))
            dispatch(setLessonUpdate(null))
        }

    }, [lessonUpdate])

    const submitForm = (data) => {
        if (loading === true) return;
        if (id) dispatch(putUpdateLesson(id, data))
        else dispatch(postCreateLesson(data))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Kode Pelajaran"
                                    type="text"
                                    name="code"
                                    placeholder="Kode Pelajaran"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama Pelajaran"
                                    type="text"
                                    name="name"
                                    placeholder="Nama Pelajaran"
                                    register={register}
                                    errors={formState.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(LessonMasterForm)
