import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthWrapper } from '../../../AuthWrapper';
import * as actions from '../../../../redux/actions';
import PostForm from '../../../../components/PostForm';
import PageHeading from '../../../../components/PageHeading';
import CardBasic from '../../../../components/Cards/Basic';
import { getPostOne, postCreatePost, putUpdatePost, setGetPost, setPostCreate, setPostUpdate } from '../../../../redux/actions/post';
// import { ProgressBar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router';
import Progressbar from '../../../../components/Progressbar';
import { notify } from '../../../../constants/utility';

const PageForm = (props) => {
    const { name, label, path, title, parent } = props.data
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(actions.setMenuActive({ menuActive: parent || name, subMenuActive: name }))
    }, [dispatch])

    const { loading } = useSelector((state) => state.post);

    const fieldOptions = {
        type: 'page',
        hide_visibility: true,
        hide_comment_status: true,
        // hide_image_upload: true,
        hide_excerpt: true,
        hide_tags: true
    }

    const { id } = useParams()
    const { post } = useSelector(state => state.post)

    const [initValue, setInitValue] = useState({})

    const handleSubmitForm = (data) => {
        if (loading === true) return;
        if (data.status) data.status = data.status.value
        if (!id) dispatch(postCreatePost(data))
        else dispatch(putUpdatePost(id, data))
    }

    const { postCreate, postUpdate } = useSelector((state) => state.post);
    useEffect(() => {
        if (postCreate) {
            navigate("/pages/edit/" + postCreate.result?.id, { replace: true })
        }

    }, [postCreate])

    useEffect(() => {
        if (postUpdate) {
            notify(postUpdate.message, postUpdate.success ? 'success' : 'error')

            dispatch(getPostOne(id))
            dispatch(setPostUpdate(null))
        }

    }, [postUpdate])

    useEffect(() => {
        setInitValue({
            id: post?.result?.id || '',
            title: post?.result?.title || '',
            content: post?.result?.content || '',
            tags: post?.result?.tags || '',
            image: post?.result?.image || '',
            status: post?.result?.status || 'publish',
            visibility: post?.result?.visibility || 'public',
            comment_status: post?.result?.comment_status || 'close',
            type: post?.result?.type || 'page',
            excerpt: post?.result?.excerpt || '',
        })
        // console.log('alsocodes', initValue, post)
    }, [post])

    useEffect(() => {
        if (id) {
            dispatch(getPostOne(id))
            dispatch(setPostCreate(null))
        }
        return () => {
            dispatch(setGetPost(null))
        }
    }, [id])


    return (
        <div>
            <Progressbar loading={loading} />
            <PageHeading title={title} hasBack={true} />
            <PostForm
                loading={loading}
                initValue={initValue}
                handleSubmitForm={handleSubmitForm}
                fieldOptions={fieldOptions} />
        </div>
    )
}

export default AuthWrapper(PageForm)
