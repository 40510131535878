import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetClassroom = (payload) => {
    return {
        type: actionTypes.GET_CLASSROOM,
        data: payload,
    };
};

export const getClassroomOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/sia/master/classroom/` + data);
        dispatch(setGetClassroom(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}

export const setGetClassrooms = (payload) => {
    return {
        type: actionTypes.GET_CLASSROOMS,
        data: payload,
    };
};

export const getClassroomAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/sia/master/classroom`, { params });
        dispatch(setGetClassrooms(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}

export const setClassroomCreate = (payload) => {
    return {
        type: actionTypes.CLASSROOM_CREATE,
        data: payload,
    };
}

export const postCreateClassroom = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/classroom`, data);
        dispatch(setOnloading(null))
        dispatch(setClassroomCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}

export const setClassroomUpdate = (payload) => {
    return {
        type: actionTypes.CLASSROOM_UPDATE,
        data: payload,
    };
}

export const putUpdateClassroom = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/sia/master/classroom/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setClassroomUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}


export const setClassroomDelete = (payload) => {
    return {
        type: actionTypes.CLASSROOM_DELETE,
        data: payload,
    };
}

export const deleteClassroom = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/sia/master/classroom/${id}`);
        dispatch(setOnloading(null))
        dispatch(setClassroomDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null));
    }
}

export const setPostImportCsv = (payload) => {
    return {
        type: actionTypes.POST_IMPORT_CSV,
        data: payload,
    };
};


export const postImportCsv = (data) => async (dispatch) => {
    try {

        dispatch(setOnloading(true))
        const result = await http.post(`/sia/master/classroom-import`, data);
        console.log("aaww", result)
        dispatch(setPostImportCsv(result));
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
