import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CardBasic from '../../../../components/Cards/Basic'
import ModalConfirm from '../../../../components/ModalConfirm';
import Progressbar from '../../../../components/Progressbar';
import { collapseSidebar, setMenuActive } from '../../../../redux/actions';
import { AuthWrapper } from '../../../AuthWrapper';
import { Button, Modal } from 'react-bootstrap';
import SiaHeader from '../../../../components/SiaHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { Link } from "react-router-dom";
import TextInput from '../../../../components/TextInput';
import { Controller, useForm } from 'react-hook-form';
import { fileToBase64, notify } from '../../../../constants/utility';
import { getFormOpt, getGraduations, postBulkGraduationUpload, putGraduation, setGetGraduation, setPostBulkGraduationUpload, setPutGraduation } from '../../../../redux/actions/sia-activity/graduation';
import { download } from '../../../../redux/actions/download';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const GraduationActivity = (props) => {
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()
    const { name, title } = props.data
    const [modalShowConfirm, setModalShowConfirm] = useState(false)
    const [modalOption, setModalOption] = useState({})

    const dispatch = useDispatch()

    const { loading, formOpt, graduationUpdate, graduations, bulkGraduationUpload } = useSelector((state) => state.siaGraduation);
    const [semester, setSemester] = useState(null)

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(getFormOpt())
        dispatch(setGetGraduation(null))
    }, [])

    const [classroomOption, setClassroomOption] = useState([{ value: 0, label: 'Semua Kelas Rombel' }])
    useEffect(() => {
        if (formOpt?.result?.classrooms) {
            formOpt.result.classrooms.map(item => {
                const opt = { value: item.id, label: item.name }
                setClassroomOption(prev => [...prev, opt])
            })
        }
    }, [formOpt]);

    useEffect(() => {
        if (watch("file")?.length > 0) {
            // console.log('iam fire', watch("file"))
            var reader = new FileReader();
            reader.readAsDataURL(watch("file")[0]);
            reader.onload = function () {
                setPdfUrl(reader.result)
            };
            reader.onerror = function (error) {
                console.log('Error: ', error);
            };
        }
    }, [watch("file")]);


    useEffect(() => {
        if (bulkGraduationUpload) {
            notify(bulkGraduationUpload.message, bulkGraduationUpload.success ? 'success' : 'error')
            dispatch(getGraduations(graduationParam))
        }
        return () => {
            dispatch(setPostBulkGraduationUpload(null))
        }
    }, [bulkGraduationUpload])

    useEffect(() => {
        if (graduationUpdate) {
            notify(graduationUpdate.message, graduationUpdate.success ? 'success' : 'error')
            dispatch(getGraduations(graduationParam))
            setModalShowPreview(false)
            setDetailData(null)
            setPdfUrl(null)
        }

        return () => {
            dispatch(setPutGraduation(null))
        };
    }, [graduationUpdate]);


    const columns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'nis', text: 'NIS', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'nisn', text: 'NISN', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },

        {
            dataField: 'name', text: 'Siswa', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },

        {
            dataField: 'graduation.final_score', text: 'Nilai Akhir', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },

        {
            dataField: 'graduation.file', text: 'Status',
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i) => {
                return !c ? 'Belum upload surat kelulusan' : c
                // dispatch(download(c))
            }
        },

        {
            dataField: 'id', text: '',
            headerStyle: (colum, colIndex) => { return { width: '100px' }; },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (val, row) => {
                return (
                    <>
                        <span className="btn btn-secondary btn-sm"
                            onClick={() => {
                                setModalShowPreview(true)
                                setDetailData(row)
                                setPdfUrl(row.graduation?.file_url)
                                setValue('final_score', row.graduation?.final_score || "")
                            }}>
                            <i className="fas fa-search"></i>
                        </span>
                    </>
                )
            }
        },
    ];

    const [graduationParam, setGraduationParam] = useState(null);

    useEffect(() => {
        if (!watch('eduyear_id')) return;
        const data = {
            eduyear_id: watch('eduyear_id').value,
            classroom_id: watch('classroom_id')?.value === 0 ? null : watch('classroom_id')?.value || null
        }
        dispatch(getGraduations(data))
        setGraduationParam(data)

    }, [watch('eduyear_id'), watch('classroom_id')])

    const indication = () => {
        return 'No data'
    }

    const defaultSorted = [{
        dataField: 'created_at',
        order: 'desc'
    }];

    const [pdfUrl, setPdfUrl] = useState(null)
    const [modalShowPreview, setModalShowPreview] = useState(false)
    const [detailData, setDetailData] = useState(null)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const graduationMasalInput = useRef();

    const clickGraduationMasal = () => {
        graduationMasalInput.current.click();
    }

    const onGraduationMasalChange = (e) => {
        // console.log(e.target.files[0].name)
        if (e.target.files.length > 0) {
            let formData = new FormData();
            formData.append('file', e.target.files[0])
            dispatch(postBulkGraduationUpload(formData));
            graduationMasalInput.current.value = ""
        }
    }

    const uploadGraduationOne = (data) => {
        // console.log(data)
        // console.log(detailData)
        let formData = new FormData();
        formData.append("student_id", detailData.id);
        formData.append("final_score", data.final_score);
        formData.append("eduyear_id", data.eduyear_id.value);
        formData.append("file", data.file[0]);
        // console.log(formData)
        dispatch(putGraduation(formData))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding>
                <div className="p-3">
                    {/* <form onSubmit={handleSubmit(submitForm)}> */}
                    <div className="row">
                        <div className="col-md-8">
                            <Controller
                                name="eduyear_id"
                                control={control}
                                rules={{ required: 'Harus diisi' }}
                                render={({ field }) =>
                                    <TextInput
                                        layout="horizontal"
                                        label="Tahun Ajaran"
                                        type="select"
                                        placeholder="Tahun Ajaran"
                                        register={register}
                                        field={field}
                                        errors={formState.errors}
                                        options={formOpt?.result?.eduyears?.map(item => { return { value: item.id, label: item.name } }, [])}
                                    />
                                }
                            />

                            <Controller
                                name="classroom_id"
                                control={control}
                                render={({ field }) =>
                                    <TextInput
                                        layout="horizontal"
                                        label="Kelas & Rombel"
                                        type="select"
                                        placeholder="Kelas & Rombel"
                                        register={register}
                                        field={field}
                                        errors={formState.errors}
                                        options={classroomOption}
                                    />
                                }
                            />
                        </div>
                        {/* <div className="col-md-4">
                                <button type="submit" className="btn btn-primary btn-md">Lihat Graduation</button>
                            </div> */}
                    </div>

                    {/* </form> */}

                </div>
                {graduations ?
                    <>
                        <hr />
                        <div className="overflow-hidden p-3">
                            <button type="button" className="float-right btn btn-info btn-md" onClick={() => clickGraduationMasal()}>Upload Kelulusan Masal</button>
                            <input hidden ref={graduationMasalInput} type="file" accept=".zip" onChange={(e) => onGraduationMasalChange(e)} />
                        </div>
                        <div className="table-fixied custom-scrollbar table-responsive my-table">
                            <BootstrapTable
                                className="bt-table"
                                bootstrap4
                                hover
                                bordered
                                striped
                                condensed
                                noDataIndication={indication}
                                bordered={false}
                                keyField="id"
                                defaultSorted={defaultSorted}
                                data={graduations?.result || []}
                                columns={columns}
                            // selectRow={
                            //     {
                            //         mode: 'checkbox',
                            //         onSelect: handleOnSelect,
                            //         onSelectAll: handleOnSelectAll
                            //     }
                            // }
                            // pagination={paginationFactory({ page, sizePerPage, totalSize })}
                            />
                        </div>
                    </>
                    : ""
                }
            </CardBasic>

            <ModalConfirm
                show={modalShowConfirm}
                modalOption={modalOption}
                onHide={() => setModalShowConfirm(false)}
            />

            <Modal
                {...props}
                clos
                show={modalShowPreview}
                size={`lg`}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => {
                    setModalShowPreview(false)
                    setDetailData(null)
                    setPdfUrl(null)
                }}
            >
                <Modal.Header className='text-orimary'>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Upload Kelulusan
                    </Modal.Title>
                    <span className='btn' onClick={() => {
                        setModalShowPreview(false)
                        setDetailData(null)
                        setPdfUrl(null)
                    }}>&times;</span>
                </Modal.Header>
                <Modal.Body>
                    <div className="fixed-modal custom-scrollbar">
                        <form onSubmit={handleSubmit(uploadGraduationOne)} >
                            <div className="row">
                                <div className="col-md-7">
                                    <TextInput
                                        label={"File SK Lulus"}
                                        validations={{ required: 'Harus diisi' }}
                                        layout="vertical"
                                        name="file"
                                        type="file"
                                        accept=".pdf"
                                        placeholder="File Graduation"
                                        register={register}
                                        errors={formState.errors}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <TextInput
                                        label={"Nilai Akhir"}
                                        validations={{ required: 'Harus diisi' }}
                                        layout="vertical"
                                        name="final_score"
                                        type="text"
                                        placeholder="Nilai Akhir"
                                        register={register}
                                        errors={formState.errors}
                                    />
                                </div>
                                <div className="col-md-2">
                                    <button style={{ marginTop: "30px" }} className="btn btn-primary btn-md">Upload</button>
                                </div>
                                <div className="col-md-2">
                                    {detailData?.graduation?.file ? <button
                                        onClick={() => {
                                            dispatch(download(detailData.graduation.file))
                                            setModalShowPreview(false)
                                            setDetailData(null)
                                            setPdfUrl(null)
                                        }}
                                        type="button" className="btn btn-info btn-md">Download</button> : ""}
                                </div>
                            </div>
                        </form>
                        {pdfUrl ?
                            <Document
                                options={{ workerSrc: "pdf.worker.js" }}
                                file={pdfUrl}
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page pageNumber={pageNumber} />
                            </Document>
                            : ""}
                    </div>

                </Modal.Body>

            </Modal>

        </div >
    )
}



export default AuthWrapper(GraduationActivity)
