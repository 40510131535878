import { Button } from 'bootstrap'
import { map } from 'jquery'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../components/Cards/Basic'
import Progressbar from '../../../../components/Progressbar'
import SiaHeader from '../../../../components/SiaHeader'
import TextInput from '../../../../components/TextInput'
import { notify } from '../../../../constants/utility'
import { collapseSidebar, setMenuActive } from '../../../../redux/actions'
import { getSettings, setGetSettings, postSetSetting } from '../../../../redux/actions/sia-master/setting'
import { AuthWrapper } from '../../../AuthWrapper'

const SiaSettingForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, settings, setSettings } = useSelector((state) => state.siaSettings);

    const dispatch = useDispatch()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(getSettings())
    }, [])


    const [yearOption, setYearOption] = useState([])
    const [semesterOption, setSemesterOption] = useState([])
    useEffect(() => {

        let year = {}
        let semester = {}
        if (settings?.result?.eduyears) {
            for (var i = 0; i < settings.result?.eduyears.length; i++) {
                let item = settings.result.eduyears[i]
                let y = { value: item.id, label: item.name }
                setYearOption(prev => [...prev, y])
                if (item.status === 'active') year = y
            }
        }
        if (settings?.result?.semesters) {
            for (var i = 0; i < settings.result?.semesters.length; i++) {
                let item = settings.result.semesters[i]
                let y = { value: item.id, label: item.name }
                setSemesterOption(prev => [...prev, y])
                if (item.status === 'active') semester = y
            }
        }

        setValue('eduyear_id', year)
        setValue('semester_id', semester)

    }, [settings])

    useEffect(() => {
        if (setSettings) {
            notify(setSettings.message, setSettings.success ? 'success' : 'error')
        }

    }, [setSettings])

    const submitForm = (data) => {
        data.eduyear_id = data.eduyear_id.value
        data.semester_id = data.semester_id.value
        if (loading === true) return;
        dispatch(postSetSetting(data))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <Controller
                                    name="eduyear_id"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            validations={{ required: 'Harus diisi' }}
                                            layout="horizontal"
                                            label="Tahun Ajaran Aktif"
                                            type="select"
                                            placeholder="Tahun Ajaran Aktif"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={yearOption}
                                        />
                                    }
                                />
                                <Controller
                                    name="semester_id"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            validations={{ required: 'Harus diisi' }}
                                            layout="horizontal"
                                            label="Semester Aktif"
                                            type="select"
                                            placeholder="Semester Aktif"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={semesterOption}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(SiaSettingForm)
