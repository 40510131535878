import {
  COLLAPSE_SIDEBAR,
  SET_EXPAND_SIDEBAR,
  SET_MENU_ACTIVE,
} from "../../../constants/actionTypes";

const initialState = {
  expandSidebar: true,
  menuActive: "dashboard",
  subMenuActive: "",
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {

    case COLLAPSE_SIDEBAR:
      return {
        ...state,
        expandSidebar: false,
      };


    case SET_EXPAND_SIDEBAR:
      return {
        ...state,
        expandSidebar: !state.expandSidebar,
      };

    case SET_MENU_ACTIVE:
      const { data } = action;
      return {
        ...state,
        menuActive: data.menuActive,
        subMenuActive: data.subMenuActive,
      };

    default:
      return state;
  }
};

export default reducer;
