import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/actions';
import { Modal } from 'react-bootstrap'
import { image_path, notify } from '../../../../constants/utility'
import {
    postCreateSlide, putUpdateSlide, slideUploadImage,
    getSlideOne, getSlideAll, setSlideUpdate, setSlideCreate, setSlideUploadImage
} from '../../../../redux/actions/slide'
import TextInput from '../../../../components/TextInput'
import { useForm } from 'react-hook-form';

const SlideForm = (props) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const {
        id,
        action_title
    } = props.data

    const handleSubmitForm = (data) => {
        if (!id) dispatch(postCreateSlide(data))
        else dispatch(putUpdateSlide(id, data))
    }

    const { slideCreate, slideUpdate, uploadImage, slide } = useSelector((state) => state.slide);
    useEffect(() => {
        if (slideCreate) {
            notify(slideCreate.message, slideCreate.success ? 'success' : 'error')
            props.onHide()
        }
        return () => {
            dispatch(setSlideCreate(null))
        }

    }, [slideCreate])

    useEffect(() => {
        if (slideUpdate) {
            notify(slideUpdate.message, slideUpdate.success ? 'success' : 'error')
            props.onHide()
            dispatch(getSlideAll({}))
        }
        return () => {
            dispatch(setSlideUpdate(null))
        }

    }, [slideUpdate])

    useEffect(() => {
        setValue('title', slide?.title)
        setValue('description', slide?.description)
        setValue('link', slide?.link)
        setValue('image', slide?.image)
        setValue('order', slide?.order)
    }, [slide])

    useEffect(() => {
        if (watch('upload')?.length > 0) {
            console.log(watch('upload'))
            let formData = new FormData();
            formData.append('slide', watch('upload')[0])
            console.log('acc', uploadImage)
            if (uploadImage) formData.append('old_img', uploadImage.result.filename)
            dispatch(slideUploadImage(formData));
        }
    }, [watch('upload')])

    useEffect(() => {
        if (uploadImage) setValue('image', uploadImage.result?.filename)
        return () => {
            setSlideUploadImage(null)
        }
    }, [uploadImage])

    return (
        <Modal
            {...props}
            size={'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {action_title}
                </Modal.Title>
                <span className='btn' onClick={props.onHide}>&times;</span>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <div className="row">
                        <div className="col-md-4">
                            <TextInput
                                layout="vertical"
                                label="File image"
                                type="file"
                                name="upload"
                                register={register}
                                errors={formState.errors}
                            />
                            {watch('image') ?
                                <img src={image_path + watch('image')} className="img-fluid img-thumbnail" />
                                : ''}
                            <TextInput
                                hidden
                                layout="vertical"
                                type="text"
                                name="image"
                                register={register}
                                errors={formState.errors}
                            />
                            <TextInput
                                hidden
                                layout="vertical"
                                type="number"
                                name="order"
                                register={register}
                                errors={formState.errors}
                            />
                        </div>
                        <div className="col-md-8">
                            <TextInput
                                validations={{ required: true }}
                                layout="vertical"
                                label="Judul"
                                type="text"
                                name="title"
                                placeholder="Judul slide"
                                register={register}
                                errors={formState.errors}
                            />
                            <TextInput
                                layout="vertical"
                                label="Link"
                                type="text"
                                name="link"
                                placeholder="Link"
                                register={register}
                                errors={formState.errors}
                            />
                            <TextInput
                                layout="vertical"
                                label="description"
                                type="textarea"
                                name="description"
                                placeholder="Keterangan"
                                register={register}
                                errors={formState.errors}
                            />
                        </div>
                    </div>
                    <hr />
                    <button type="submit" className="btn btn-primary btn-md">Simpan</button>
                </form>
            </Modal.Body>
            {/* <Modal.Footer>
            </Modal.Footer> */}
        </Modal>
    )
}

export default SlideForm
