import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { has_access, image_path } from '../../constants/utility'
import { postUploadImage, setPostUploadImage } from '../../redux/actions/post'
import CardBasic from '../Cards/Basic'
import TextInput from '../TextInput'
import WYSIWYGEditor from '../WYSIWYGEditer'
import TinyMceEditor from '../TinyMceEditor'
//https://codesandbox.io/s/0p6zjoy7x0?file=/index.js:160-774
const PostForm = (props) => {
    const dispatch = useDispatch();
    const {
        handleSubmitForm,
        fieldOptions,
        initValue,
        loading,
        postMeta
    } = props

    const {
        hide_excerpt,
        hide_status,
        hide_visibility,
        hide_comment_status,
        hide_image_upload,
        hide_tags,
        type
    } = fieldOptions

    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const { uploadImage } = useSelector((state) => state.post);
    const { data: userData } = useSelector((state) => state.auth);
    useEffect(() => {
        if (watch('img').length > 0) {
            let formData = new FormData();
            formData.append('img', watch('img')[0])
            if (uploadImage) formData.append('old_img', uploadImage.result.filename)
            dispatch(postUploadImage(formData));
        }
        // console.log('alsocodes', watch('img'))
    }, [watch('img')])

    useEffect(() => {
        if (uploadImage) setValue('image', uploadImage.result?.filename)
        return () => {
            setPostUploadImage(null)
        }
    }, [uploadImage])

    const statusOption = [
        { value: "draft", label: "Draft" },
        { value: "publish", label: "Publish" },
    ]

    const visibilityOption = [
        { value: "public", label: "Public" },
        { value: "private", label: "Private" },
    ]

    const commentOption = [
        { value: "open", label: "Dizinkan" },
        { value: "close", label: "Tidak dizinkan" },
    ]

    useEffect(() => {
        for (var key in initValue) {
            if (key === 'status') {
                statusOption.map(item => {
                    console.log('status', item, initValue[key])
                    if (item.value === initValue[key]) setValue(key, item)
                })
            } else {
                setValue(key, initValue[key])
            }
        }

    }, [initValue])

    // console.log('edited', has_access('edit-content', userData?.user?.role_access));
    // console.log('userData', userData.user)

    return (
        <div>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <div className="row">
                    <div className="col-md-8 col-sm-12">
                        <CardBasic >
                            <TextInput
                                validations={{ required: 'Harus diisi' }}
                                layout="vertical"
                                label="Judul"
                                type="text"
                                name="title"
                                placeholder="Judul"
                                register={register}
                                errors={formState.errors}
                            />

                            <TextInput
                                hidden={hide_excerpt}
                                layout="vertical"
                                label="Ringkasan"
                                type="textarea"
                                name="excerpt"
                                placeholder="Ringkasan"
                                register={register}
                                errors={formState.errors}
                            />

                            <div className='mb-3'>
                                <Controller
                                    render={({ field }) =>
                                        // <WYSIWYGEditor label="Isi" {...field} />
                                        <TinyMceEditor {...field} />
                                    }
                                    name="content"
                                    control={control}
                                />
                            </div>
                            <TextInput
                                hidden={hide_tags}
                                layout="vertical"
                                label="Keyword"
                                type="textarea"
                                name="tags"
                                placeholder="Keyword"
                                register={register}
                                errors={formState.errors}
                            />
                        </CardBasic>
                    </div>
                    <div className="col-md-4 col-sm-12">

                        <CardBasic title="Gambar" hidden={hide_image_upload}>
                            <TextInput
                                layout="vertical"
                                label="Gambar"
                                type="file"
                                name="img"
                                placeholder="Gambar"
                                register={register}
                                errors={formState.errors}
                            />
                            {watch('image') ?
                                <img src={image_path + watch('image')} className="img-fluid" />
                                : ''}
                            <TextInput
                                hidden
                                layout="vertical"
                                type="text"
                                name="image"
                                register={register}
                                errors={formState.errors}
                            />
                        </CardBasic>

                        <CardBasic title="Publikasi">
                            <Controller
                                name="status"
                                control={control}
                                rules={{ required: 'Harus diisi' }}
                                render={({ field }) =>
                                    <TextInput
                                        layout="vertical"
                                        label="Status"
                                        type="select"
                                        placeholder="Status"
                                        register={register}
                                        field={field}
                                        errors={formState.errors}
                                        options={statusOption}
                                    />
                                }
                            />
                            <TextInput
                                hidden={hide_visibility}
                                layout="vertical"
                                label="Visibilitas"
                                type="select"
                                name="visibility"
                                placeholder="Visibilitas"
                                register={register}
                                errors={formState.errors}
                                options={visibilityOption}
                            />
                            <TextInput
                                hidden={hide_comment_status}
                                layout="vertical"
                                label="Komentar"
                                type="select"
                                name="comment_status"
                                placeholder="Komentar"
                                register={register}
                                errors={formState.errors}
                                options={commentOption}
                            />
                            <TextInput
                                hidden
                                layout="vertical"
                                label="Tipe"
                                type="text"
                                name="type"
                                register={register}
                                errors={formState.errors}
                            />
                            <hr />
                            {
                                // console.log('acc', postMeta)
                                !postMeta ? "" :
                                    postMeta?.map(meta => {
                                        return (
                                            <TextInput
                                                defaultValue={meta.value}
                                                key={meta.name}
                                                layout="vertical"
                                                label={meta.label}
                                                type={meta.type}
                                                name={meta.name}
                                                register={register}
                                                errors={formState.errors}
                                                validations={meta.validations}
                                            />
                                        )
                                    })
                            }
                            {type === 'agenda' ?
                                <TextInput
                                    layout="vertical"
                                    label="Tanggal Agenda"
                                    type="date"
                                    name="agenda_date"
                                    register={register}
                                    errors={formState.errors}
                                    validations={{ required: true }}
                                /> : ""
                            }
                            <hr />

                            {initValue?.id ?
                                <button
                                    // disabled={loading || !has_access('edit-content', userData?.user?.role_access)}
                                    // type="submit"
                                    className="btn btn-primary-f btn-user btn-block"
                                >
                                    <i className={`fas ` + (!loading ? `` : `fa-spinner fa-spin`)}></i>
                                    &nbsp; {'UPDATE'}
                                </button> :
                                <button
                                    // disabled={loading || !has_access('add-content', userData?.user?.role_access)}
                                    // type="submit"
                                    className="btn btn-primary-f btn-user btn-block"
                                >
                                    <i className={`fas ` + (!loading ? `` : `fa-spinner fa-spin`)}></i>
                                    &nbsp; {'SIMPAN'}
                                </button>
                            }
                        </CardBasic>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PostForm