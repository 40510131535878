import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useSearchParams } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import {
    getStudentClassByClassYears,
    getStudentClassOne, getStudentClassOptForm, postCreateStudentClass, putUpdateStudentClass,
    setGetStudentClass, setGetStudentClassByClassYears, setStudentClassCreate, setStudentClassUpdate,
    postStudentClassStatusUpdate, setPostStudentClassStatusUpdate
} from '../../../../../redux/actions/sia-activity/student-class'
import { AuthWrapper } from '../../../../AuthWrapper'
import { Modal } from 'react-bootstrap';
import { notify } from '../../../../../constants/utility'

const StudentClassForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, studentClass,
        studentClassCreate,
        studentClassUpdate, studentClassOptForm,
        studentClassByClassYear,
        studentClassStatusUpdate
    } = useSelector((state) => state.studentClass);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    // if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const [eduyearOption, setEduyearOption] = useState([])
    const [studentOption, setStudentOption] = useState([])
    const [classroomOption, setClassroomOption] = useState([])
    const [classroomOptionNaik, setClassroomOptionNaik] = useState([])
    const [searchParams] = useSearchParams();
    const { id } = useParams()
    const mass = 1

    useEffect(() => {
        if (watch('classroom_id')) {
            // console.log('awwx', watch('classroom_id'))
            const code = watch('classroom_id').code
            const nextClass = code === 'VII' ? 'VIII' : code === 'VIII' ? 'IX' : ''

            const opts = classroomOption?.filter(item => item.code === nextClass)
            if (opts.length > 0) setClassroomOptionNaik(opts)
            else setClassroomOptionNaik([{ value: 0, label: 'Lulus' }])
        }
    }, [watch('classroom_id')]);

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetStudentClass(null))
        dispatch(getStudentClassOptForm())
        console.log('awowow', mass === 1)
        dispatch(setGetStudentClassByClassYears(null))
        setPeserta([])
    }, [])

    useEffect(() => {
        if (id) {
            dispatch(getStudentClassOne(id))
        }
    }, [id])

    useEffect(() => {
        // setEduyearOption([{ value: studentClassOptForm?.result?.eduyears?.id, label: studentClassOptForm?.result?.eduyears?.name }])
        const eduyears = studentClassOptForm?.result?.eduyears.map(item => {
            return { value: item.id, label: item.name }
        })
        const classrooms = studentClassOptForm?.result?.classrooms.map(item => {
            return { value: item.id, label: item.name, code: item.code }
        })
        setEduyearOption(eduyears)
        setClassroomOption(classrooms)

        let students = studentClassOptForm?.result?.students.map(item => {
            return { value: item.id, label: item.name }
        })
        setStudentOption(students)
        // setValue('classroom_id', classrooms?.[0])
        // setValue('eduyear_id', students?.[0])
        setCalonSelected([])
    }, [studentClassOptForm])

    useEffect(() => {
        setEduyearOption([{ value: studentClass?.result?.eduyear?.id, label: studentClass?.result?.eduyear?.name }])
        if (studentClass) {
            setValue('eduyear_id', { value: studentClass?.result?.eduyear?.id, label: studentClass?.result?.eduyear?.name })
        }

        // let student = studentOption?.filter(item => item.value === studentClass?.result?.student?.id)
        // setValue('student_id', student?.[0] || '')

        // let teacher = teacherOption?.filter(item => item.value === studentClass?.result?.teacher?.id)
        // setValue('teacher_id', teacher?.[0] || '') 
        setCalonSelected([])
    }, [studentClass])

    useEffect(() => {
        if (classroomOption) {
            let classroom = classroomOption.filter(item => item.value === studentClass?.result?.classroom?.id)
            setValue('classroom_id', classroom?.[0] || '')
        }
    }, [classroomOption, studentClass])


    useEffect(() => {
        if (studentOption) {
            let student = studentOption.filter(item => item.value === studentClass?.result?.student?.id)
            setValue('student_id', student?.[0] || '')
            console.log('awwx', student,)
        }
    }, [studentOption, studentClass])

    useEffect(() => {
        if (studentClassCreate) {
            notify(studentClassCreate.message, studentClassCreate.success ? 'success' : 'error')

            // if (studentClassCreate.result?.id) navigate("/sia/activity/student-class/edit/" + studentClassCreate.result?.id, { replace: true })
            // else 1// dispatch ulang
            if (mass === 1) {
                const params = {
                    classroom_id: watch("classroom_id").value,
                    eduyear_id: watch("eduyear_id").value,
                }
                dispatch(getStudentClassByClassYears(params))
            }
            setSelectedPeserta([])
            setSelectedPesertaDone([])
            setSelectedPesertaUnDone([])
            setCalonSelected([])
        }

        return () => {
            dispatch(setStudentClassCreate(null))
        }
    }, [studentClassCreate])

    useEffect(() => {
        if (studentClassStatusUpdate) {
            notify(studentClassStatusUpdate.message, studentClassStatusUpdate.success ? 'success' : 'error')
            if (mass === 1) {
                const params = {
                    classroom_id: watch("classroom_id").value,
                    eduyear_id: watch("eduyear_id").value,
                }
                dispatch(getStudentClassByClassYears(params))
            }
            setSelectedPeserta([])
            setSelectedPesertaDone([])
            setSelectedPesertaUnDone([])
            setCalonSelected([])
        }
        return () => {
            dispatch(setPostStudentClassStatusUpdate(null))
        }
    }, [studentClassStatusUpdate])

    useEffect(() => {
        if (studentClassUpdate) {
            notify(studentClassUpdate.message, studentClassUpdate.success ? 'success' : 'error')
            dispatch(getStudentClassOne(id))
            dispatch(setStudentClassUpdate(null))
            setSelectedPeserta([])
            setSelectedPesertaDone([])
            setSelectedPesertaUnDone([])
            setCalonSelected([])
        }

    }, [studentClassUpdate])

    useEffect(() => {
        if ("awowowo", watch("eduyear_id") && watch("classroom_id")) {
            const params = {
                classroom_id: watch("classroom_id").value,
                eduyear_id: watch("eduyear_id").value,
            }
            dispatch(getStudentClassByClassYears(params))
        }
    }, [watch("eduyear_id"), watch("classroom_id")])

    const submitForm = (data) => {
        let newData = {}
        newData.classroom_id = data.classroom_id.value
        newData.eduyear_id = data.eduyear_id.value
        if (data.student_id !== '') {
            if (!Array.isArray(data.student_id)) newData.students = [{ student_id: data.student_id.value, }]
            else newData.students = data.student_id.map(item => item.value)
        }

        if (mass === 1) {
            newData.students = peserta.map(item => {
                return {
                    id: item.id ? item.id : null,
                    student_id: item.student?.id,
                    is_delete: item.is_delete || null
                }
            })

        }
        // console.log(newData)
        if (loading === true) return;
        if (id) dispatch(putUpdateStudentClass(id, newData))
        else dispatch(postCreateStudentClass(newData))
    }

    const [peserta, setPeserta] = useState([])
    useEffect(() => {
        setPeserta([])
        setPeserta(studentClassByClassYear?.result?.peserta || [])
        console.log('iam fired', peserta)
        setSelectedPeserta([])
        setSelectedPesertaDone([])
        setSelectedPesertaUnDone([])
    }, [studentClassByClassYear])

    const pesertaColumns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'student.name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
        },
        {
            dataField: 'student.nis', text: 'NIS', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
        },
        {
            dataField: 'student.nisn', text: 'NISN', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
        },
        {
            dataField: 'status', text: 'Status', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
            formatter: (c, i) => {
                return c === 'active' ?
                    <span className="badge badge-light">Aktif Kelas</span>
                    : c === 'passed' ?
                        <span className="badge badge-success">Naik Kelas</span> :
                        c === 'repeat' ? <span className="badge badge-warning">Tinggal Kelas</span> : ''
            }
        },

        {
            dataField: 'student_class_rapors', text: 'Status Rapor', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deleted' : ''}` },
            formatter: (c, i) => {
                return <>
                    {c?.length > 0 ? c.map(item => <span className="badge badge-success">{`Semester ${item.semester_id} `} <i className="fas fa-check"></i></span>) : ''}
                </>
            }
        },
        {
            dataField: 'student.id', text: 'Aksi',
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return `p-2 ${i.is_delete ? 'row-deletedx' : ''}` },
            formatter: (cel, row) => {
                return row.status === 'active' && row.student_class_rapors?.length === 0 ?
                    <span
                        className="btn btn-danger btn-sm"
                        onClick={() => hapusPeserta(cel)}>{!row.is_delete ? 'Hapus' : 'Batal Hapus'}
                    </span>
                    : row.student_class_rapors?.length === 2 && row.status === 'active' ?
                        <>
                            {/* <span className="btn btn-success btn-sm"
                                onClick={() => {
                                    setSelectedPesertaDone([{ status: 'passed', student_class_id: row.id, student_id: cel }])
                                    setShowModalNaik(true)
                                }
                                    //updateStatusClass([{ status: 'passed', student_class_id: row.id, student_id: cel }])
                                }><i className="fas fa-check"></i> Naik Kelas </span>{" "}
                            <span className="btn btn-warning btn-sm" onClick={() => updateStatusClass([{ status: 'repeat', student_class_id: row.id, student_id: cel }])}><i className="fas fa-ban"></i> Tinggal Kelas </span> */}
                        </>
                        : ''
            }
        },
    ]

    const calonPesertaColumns = [
        {
            dataField: '', text: 'No',
            headerStyle: (c, i) => { return { width: '80px' } },
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
            formatter: (c, i, k) => { return (k + 1) }

        },
        {
            dataField: 'name', text: 'Nama', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
        {
            dataField: 'student_classes.classroom.code', text: 'Kelas Sebelumnya', sort: true,
            headerClasses: (c, i) => { return 'py-2 px-2' },
            classes: (c, i) => { return 'p-2' },
        },
    ]

    const [showModal, setShowModal] = useState(false)
    const [calonSelected, setCalonSelected] = useState([])
    const [showModalNaik, setShowModalNaik] = useState(false)

    const handleOnSelect = (row, isSelect) => {
        if (isSelect) setCalonSelected(prev => [...prev, row])
        else setCalonSelected(calonSelected.filter(x => x.id !== row.id))
    }

    const handleOnSelectAll = (isSelect, rows) => {
        if (isSelect) setCalonSelected(rows)
        else setCalonSelected([])
    }

    const [selectedPeserta, setSelectedPeserta] = useState([])
    const [selectedPesertaDone, setSelectedPesertaDone] = useState([])
    const [selectedPesertaUnDone, setSelectedPesertaUnDone] = useState([])
    const handleOnSelectPeserta = (row, isSelect) => {
        if (row.status !== 'active') {
            notify("Tidak bisa dipilih", 'error')
            return false
        }
        if (isSelect) setSelectedPeserta(prev => [...prev, row])
        else setSelectedPeserta(selectedPeserta.filter(x => x.student.id !== row.student.id))
    }

    const handleOnSelectAllPeserta = (isSelect, rows) => {
        if (isSelect) setSelectedPeserta(rows.filter(item => item.status === 'active'))
        else setSelectedPeserta([])
    }

    useEffect(() => {
        if (selectedPeserta) {
            setSelectedPesertaDone(selectedPeserta.filter(item => item.student_class_rapors?.length === 2))
            setSelectedPesertaUnDone(selectedPeserta.filter(item => item.student_class_rapors?.length === 0))
        }
    }, [selectedPeserta]);


    const tambahkanPeserta = () => {
        setShowModal(false)
        let temp = peserta
        setPeserta([])
        calonSelected.map(item => {
            const obj = {
                id: null,
                student: {
                    id: item.id,
                    name: item.name,
                    nis: item.nis,
                    nisn: item.nisn
                }
            }
            setPeserta(prev => [...prev, obj])
        })
        temp.map(item => setPeserta(prev => [...prev, item]))

    }

    const hapusPeserta = (id) => {

        const newPeserta = peserta.map(item => {
            console.log('xxx', item['student.id'], id)
            if (item?.student?.id === id) {
                item['is_delete'] = item['is_delete'] === true ? false : true
            }
            return item
        })
        setPeserta(newPeserta)
    }
    const [toggleDelete, setToggleDelete] = useState(false)
    const hapusPesertaMassal = (deleteds) => {
        const ids = deleteds.map(item => item?.student?.id, [])
        const newPeserta = peserta.map(item => {
            console.log('xxx', item['student.id'], id)
            if (ids.includes(item?.student?.id)) {
                // item['is_delete'] = item['is_delete'] === true ? false : true
                item['is_delete'] = !toggleDelete
            }
            return item
        })
        setPeserta(newPeserta)
        setToggleDelete(!toggleDelete)
    }

    // useEffect(() => {
    //     console.log("calon ", calonSelected)
    // }, [calonSelected])

    // studentClassByClassYear?.result?.calon_peserta?.filter(item => !pesertaIds?.includes(item.id)

    const [pesertaIds, setPesertaIds] = useState([])
    useEffect(() => {
        console.log("peserta", peserta)
        if (peserta) setPesertaIds(peserta.map(item => item?.student?.id, []))
    }, [peserta])

    const [calonPeserta, setCalonPeserta] = useState([])
    useEffect(() => {
        if (studentClassByClassYear) {
            setCalonPeserta(studentClassByClassYear.result?.calon_peserta?.filter(item => !pesertaIds.includes(item.id), []))
        }
    }, [studentClassByClassYear]);

    useEffect(() => {
        if (pesertaIds) {
            // console.log('axxew', pesertaIds)
            // console.log('axxew', calonPeserta)
            setCalonPeserta(studentClassByClassYear?.result?.calon_peserta?.filter(item => !pesertaIds.includes(item.id), []))
        }
    }, [pesertaIds]);


    const updateStatusClass = (data) => {
        console.log("zxxx", data)
        console.log("zxxx", selectedPesertaDone)
        const newData = {
            classroom_id: data?.classroom_id_naik?.value,
            students: selectedPesertaDone?.map(item => {
                return {
                    status: 'passed',
                    student_id: item.student.id,
                    student_class_id: item.id
                }
            })
        }
        console.log("zxxx", newData)

        dispatch(postStudentClassStatusUpdate(newData))
        setShowModalNaik(false)
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <Controller
                                    name="eduyear_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Tahun Ajaran"
                                            type="select"
                                            // name="code"
                                            placeholder="Tahun Ajaran"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={eduyearOption}
                                        />
                                    }
                                />

                                <Controller
                                    name="classroom_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Kelas & Rombel"
                                            type="select"
                                            name="classroom_id"
                                            placeholder="Kelas & Rombel"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={classroomOption}
                                        />
                                    }
                                />
                                {mass !== 1 ?
                                    <Controller
                                        name="student_id"
                                        control={control}
                                        rules={{ required: 'Harus diisi' }}
                                        render={({ field }) =>
                                            <TextInput
                                                layout="horizontal"
                                                label="Siswa"
                                                type="select"
                                                // name="code"
                                                placeholder="Siswa"
                                                register={register}
                                                field={field}
                                                errors={formState.errors}
                                                options={studentOption}
                                            />
                                        }
                                    /> : ""
                                }

                            </div>
                            <div className="col-md-12">
                                {mass == 1 ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between">
                                                    <h6 className="m-0 font-weight-bold text-secondary">
                                                        Peserta Kelas
                                                    </h6>
                                                    <span onClick={() => {
                                                        setShowModal(true)
                                                        setCalonSelected([])
                                                    }} className="btn btn-sm btn-info">Tambah</span>
                                                </div>
                                                <hr />
                                                <div className="mb-2" style={{ minHeight: "40px" }}>
                                                    {selectedPeserta.length > 0 && selectedPeserta.length === selectedPesertaDone.length ?
                                                        <>
                                                            <button type="button"
                                                                onClick={() =>
                                                                    setShowModalNaik(true)
                                                                    // updateStatusClass(
                                                                    //     selectedPesertaDone.map(item => {
                                                                    //         return {
                                                                    //             status: 'passed',
                                                                    //             student_id: item?.student?.id,
                                                                    //             student_class_id: item?.id
                                                                    //         }
                                                                    //     }))
                                                                }
                                                                className="btn btn-primary btn-success btn-sm">
                                                                <i className="fas fa-check"></i>{" Proses Naik Kelas"}
                                                            </button>{" "}
                                                            <button type="button"
                                                                onClick={() => {
                                                                    const students = selectedPesertaDone.map(item => {
                                                                        return {
                                                                            status: 'repeat',
                                                                            student_id: item?.student?.id,
                                                                            student_class_id: item?.id
                                                                        }
                                                                    })
                                                                    dispatch(postStudentClassStatusUpdate({ students: students }))
                                                                }}
                                                                className="btn btn-primary btn-warning btn-sm">
                                                                <i className="fas fa-ban"></i>{" Proses Tinggal Kelas"}
                                                            </button>
                                                        </>
                                                        : ''}

                                                    {selectedPeserta.length > 0 && selectedPeserta.length === selectedPesertaUnDone.length ?
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-sm"
                                                            onClick={() => hapusPesertaMassal(selectedPesertaUnDone)}>{toggleDelete ? 'Batal Hapus' : 'Hapus Peserta'}</button>
                                                        : ''}
                                                    {/* {selectedPeserta.length + " " + selectedPesertaDone.length} */}
                                                </div>
                                                <BootstrapTable
                                                    className="bt-table"
                                                    bootstrap4
                                                    hover
                                                    bordered
                                                    striped
                                                    condensed
                                                    noDataIndication={() => { return ('Tidak ada peserta') }}
                                                    keyField="student.id"
                                                    data={peserta}
                                                    columns={pesertaColumns}
                                                    selectRow={
                                                        {
                                                            mode: 'checkbox',
                                                            // selected: [],
                                                            onSelect: handleOnSelectPeserta,
                                                            onSelectAll: handleOnSelectAllPeserta
                                                        }
                                                    }
                                                />
                                            </div>

                                            <Modal
                                                {...props}
                                                show={showModal}
                                                size={'lg'}
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                                onHide={() => setShowModal(false)}
                                            >
                                                <Modal.Header className='text-primary'>
                                                    <Modal.Title id="contained-modal-title-vcenter">
                                                        Tambah Peserta
                                                    </Modal.Title>
                                                    <span className='btn' onClick={() => setShowModal(false)}>&times;</span>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <BootstrapTable
                                                        className="bt-table"
                                                        bootstrap4
                                                        hover
                                                        bordered
                                                        striped
                                                        condensed
                                                        noDataIndication={() => { return ('Tidak ada peserta') }}
                                                        keyField="id"
                                                        data={calonPeserta || []}
                                                        columns={calonPesertaColumns}
                                                        selectRow={
                                                            {
                                                                mode: 'checkbox',
                                                                clickToSelect: true,
                                                                onSelect: handleOnSelect,
                                                                onSelectAll: handleOnSelectAll
                                                            }
                                                        }
                                                    />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <button
                                                        onClick={() => tambahkanPeserta()}
                                                        className="btn btn-primary btn-sm"
                                                        disabled={calonSelected.length === 0}>Tambahkan {calonSelected.length} Peserta</button>
                                                </Modal.Footer>
                                            </Modal>
                                        </div>
                                    </>
                                    : ""}

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                    <Modal
                        {...props}
                        show={showModalNaik}
                        size={'lg'}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        onHide={() => setShowModalNaik(false)}
                    >
                        <Modal.Header className='text-primary'>
                            <Modal.Title id="contained-modal-title-vcenter">
                                Proses Lulus/Naik Kelas
                            </Modal.Title>
                            <span className='btn' onClick={() => setShowModalNaik(false)}>&times;</span>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleSubmit(updateStatusClass)}>
                                <Controller
                                    name="classroom_id_naik"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Kelas & Rombel"
                                            type="select"
                                            name="classroom_id_naik"
                                            placeholder="Kelas & Rombel"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={classroomOptionNaik}
                                        />
                                    }
                                />
                                <div className="row">
                                    <div className="col-md-4 font-weight-bold">Siswa ({selectedPesertaDone?.length})</div>
                                    <div className="col-md-8">
                                        {selectedPesertaDone?.map(item => {
                                            return (
                                                <span className="badge badge-info m-1">{item.student?.name}</span>
                                            )
                                        })}
                                    </div>
                                </div>
                                <hr />
                                <button type="submit" className="btn btn-primary btn-sm">
                                    Proses Naik Kelas
                                </button>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(StudentClassForm)
