import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../components/Cards/Basic'
import Progressbar from '../../../../components/Progressbar'
import SiaHeader from '../../../../components/SiaHeader'
import TextInput from '../../../../components/TextInput'
import { setMenuActive } from '../../../../redux/actions'
import { getUserOne, postCreateUser, putUpdateUser, setGetUser, setUserCreate, setUserUpdate } from '../../../../redux/actions/master/user'
import { getRoleAll } from '../../../../redux/actions/master/role'
import { AuthWrapper } from '../../../AuthWrapper'
import { notify } from '../../../../constants/utility'

const UserMasterForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, user, userCreate, userUpdate } = useSelector((state) => state.user);
    const { roles } = useSelector((state) => state.role);
    const { data: userData } = useSelector((state) => state.auth);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: parent || name, subMenuActive: name }))
        dispatch(setGetUser(null))
        dispatch(getRoleAll({ page_size: 100 }))
    }, [])

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            dispatch(getUserOne(id))
        }
    }, [id])

    const [typeOption, setTypeOption] = useState([
        { value: 'administrator', label: 'Administrator' },
        { value: 'teacher', label: 'Guru' },
        { value: 'student', label: 'Siswa' },
    ])

    const [roleOption, setRoleOption] = useState([])
    useEffect(() => {
        setRoleOption(roles?.result?.rows?.map(item => { return { value: item.id, label: item.name } }) || [])
    }, [roles])

    useEffect(() => {
        setValue('username', user?.result?.username || '')
        setValue('name', user?.result?.name || '')
        setValue('email', user?.result?.email || '')
        setValue('nisn', user?.result?.nisn || '')
        setValue('nip', user?.result?.nip || '')
        setValue('user_type', null)
        setValue('role_id', null)
        if (user?.result?.user_type) {
            typeOption.map(item => {
                if (item.value === user?.result?.user_type) {
                    setValue('user_type', item)
                }
            })
        }
        if (roleOption) {
            roleOption.map(item => {
                if (item.value === user?.result?.role?.id) {
                    setValue('role_id', item)
                }
            })
        }
    }, [user, roleOption])


    useEffect(() => {
        if (userCreate) {
            notify(userCreate.message, userCreate.success ? 'success' : 'error')
            navigate("/users/edit/" + userCreate.result?.id, { replace: true })
        }

        return () => {
            dispatch(setUserCreate(null))
        }
    }, [userCreate])

    useEffect(() => {
        if (userUpdate) {
            notify(userUpdate.message, userUpdate.success ? 'success' : 'error')

            dispatch(getUserOne(id))
            dispatch(setUserUpdate(null))
        }

    }, [userUpdate])

    const submitForm = (data) => {
        // console.log(data)
        data.role_id = data.role_id.value
        data.user_type = data.user_type.value
        if (loading === true) return;
        if (id) dispatch(putUpdateUser(id, data))
        else dispatch(postCreateUser(data))
    }

    // console.log('user_type', watch('user_type'))

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <Controller
                                    name="user_type"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Tipe Pengguna"
                                            type="select"
                                            // name="code"
                                            placeholder="Tipe Pengguna"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={typeOption}
                                        />
                                    }
                                />
                                <Controller
                                    name="role_id"
                                    control={control}
                                    rules={{ required: 'Harus diisi' }}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Role Pengguna"
                                            type="select"
                                            // name="code"
                                            placeholder="Role Pengguna"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={roleOption}
                                        />
                                    }
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="Username"
                                    type="text"
                                    name="username"
                                    placeholder="Username"
                                    register={register}
                                    errors={formState.errors}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama"
                                    type="text"
                                    name="name"
                                    placeholder="Nama"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Email"
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    register={register}
                                    errors={formState.errors}
                                />
                                {watch('user_type')?.value === 'student' ?
                                    <TextInput

                                        validations={{ required: 'Harus diisi' }}
                                        layout="horizontal"
                                        label="NISN"
                                        type="text"
                                        name="nisn"
                                        placeholder="NISN"
                                        register={register}
                                        errors={formState.errors}
                                    /> : ""}
                                {watch('user_type')?.value === 'teacher' ?
                                    <TextInput
                                        validations={{ required: 'Harus diisi' }}
                                        layout="horizontal"
                                        label="NIP"
                                        type="text"
                                        name="nip"
                                        placeholder="NIP"
                                        register={register}
                                        errors={formState.errors}
                                    /> : ""}

                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(UserMasterForm)
