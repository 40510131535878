import * as actionTypes from "../../../../constants/actionTypes";
import { updateObject } from "../../../../constants/utility";

const initialState = {
    loading: null,
    studentClassCreate: null,
    studentClassUpdate: null,
    studentClassDelete: null,
    studentClass: null,
    studentClasses: null,
    studentClassOptForm: null,
    studentClassByClassYear: null,
    studentClassByClassYearCreate: null,
    studentClassStatusUpdate: null,
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const studentClassCreate = (state, data) => {
    return updateObject(state, {
        studentClassCreate: data,
    });
}

const getStudent = (state, data) => {
    return updateObject(state, {
        studentClass: data,
    });
}

const getStudents = (state, data) => {
    return updateObject(state, {
        studentClasses: data,
    });
}

const studentClassUpdate = (state, data) => {
    return updateObject(state, {
        studentClassUpdate: data,
    });
}

const studentClassDelete = (state, data) => {
    return updateObject(state, {
        studentClassDelete: data,
    });
}

const setGetStudentClassOptForm = (state, data) => {
    return updateObject(state, {
        studentClassOptForm: data,
    });
}

const setStudentClassByClassYear = (state, data) => {
    return updateObject(state, {
        studentClassByClassYear: data,
    });
}

const setStudentClassByClassYearCreate = (state, data) => {
    return updateObject(state, {
        studentClassByClassYearCreate: data,
    });
}

const setStudentClassStatusUpdate = (state, data) => {
    return updateObject(state, {
        studentClassStatusUpdate: data,
    });
}


const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.STUDENT_CLASS_STATUS_UPDATE:
            return setStudentClassStatusUpdate(state, data);
        case actionTypes.POST_STUDENT_CLASS_BY_CLASS_YEAR:
            return setStudentClassByClassYearCreate(state, data);
        case actionTypes.GET_STUDENT_CLASS_BY_CLASS_YEAR:
            return setStudentClassByClassYear(state, data);
        case actionTypes.GET_STUDENT_CLASS_OPTFORM:
            return setGetStudentClassOptForm(state, data);
        case actionTypes.STUDENT_CLASS_DELETE:
            return studentClassDelete(state, data);
        case actionTypes.STUDENT_CLASS_UPDATE:
            return studentClassUpdate(state, data);
        case actionTypes.GET_STUDENT_CLASSES:
            return getStudents(state, data);
        case actionTypes.GET_STUDENT_CLASS:
            return getStudent(state, data);
        case actionTypes.STUDENT_CLASS_CREATE:
            return studentClassCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
