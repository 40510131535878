import * as actionTypes from '../../../constants/actionTypes';
import http from "../../../services/NetworkService";


// export const updateAccountProfile = (id, data) => async (dispatch) => {

export const setSlideUploadImage = (payload) => {
    return {
        type: actionTypes.UPLOAD_IMAGE,
        data: payload,
    };
};

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetSlide = (payload) => {
    return {
        type: actionTypes.GET_SLIDE,
        data: payload,
    };
};

export const getSlideOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/slide/` + data);
        dispatch(setGetSlide(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetSlides = (payload) => {
    return {
        type: actionTypes.GET_SLIDES,
        data: payload,
    };
};

export const getSlideAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/slide`, { params });
        dispatch(setGetSlides(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const slideUploadImage = (data) => async (dispatch) => {
    try {
        const result = await http.post(`/slide/upload-image`, data);
        console.log('alsocodes http', result)
        dispatch(setSlideUploadImage(result));
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setSlideCreate = (payload) => {
    return {
        type: actionTypes.SLIDE_CREATE,
        data: payload,
    };
}

export const postCreateSlide = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/slide`, data);
        dispatch(setOnloading(null))
        dispatch(setSlideCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setSlideUpdate = (payload) => {
    return {
        type: actionTypes.SLIDE_UPDATE,
        data: payload,
    };
}

export const putUpdateSlide = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/slide/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setSlideUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setSlideDelete = (payload) => {
    return {
        type: actionTypes.SLIDE_DELETE,
        data: payload,
    };
}

export const deleteSlide = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/slide/${id}`);
        dispatch(setOnloading(null))
        dispatch(setSlideDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setSlideOrdering = (payload) => {
    return {
        type: actionTypes.SLIDE_ORDERING,
        data: payload,
    };
}

export const postSlideOrdering = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/slide/ordering`, data);
        dispatch(setOnloading(null))
        dispatch(setSlideOrdering(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
