import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../redux/actions';
import { Modal } from 'react-bootstrap'
import TextInput from '../../../../components/TextInput'
import { Controller, useForm } from 'react-hook-form';
import { getContentOpts, postMenuCreate, putMenuUpdate } from '../../../../redux/actions/web-menu';
import Select from 'react-select'

const MenuForm = (props) => {
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()
    const { contentOpts } = useSelector(state => state.webMenu)
    const [contentOptions, setContentOptions] = useState([{}])
    const {
        id,
        action_title,
        type,
        value
    } = props.data


    useEffect(() => {
        if (contentOpts) setContentOptions(contentOpts.result)
    }, [contentOpts])

    const handleSubmitForm = (data) => {
        if (!id) dispatch(postMenuCreate(data))
        else dispatch(putMenuUpdate(id, data))
    }

    useEffect(() => {
        setValue('name', value?.name || '')
        setValue('is_custom', value ? value.post_id ? false : true : false)
        setValue('search', null)
        setValue('post_id', value?.post_id)
        const to = setTimeout(() => {
            setValue('link', value?.link || '')
        }, 300);
        console.log("xxx value", value)
        return () => {
            clearTimeout(to)
        }
    }, [value])

    useEffect(() => {
        dispatch(getContentOpts())
    }, [])


    const formatOptionLabel = (option) => (
        <div>
            <span className="badge badge-primary">{option.type.toUpperCase()}</span> {option.label}
        </div>
    )

    useEffect(() => {
        if (watch('search')) {
            setValue('link', watch('search').slug)
            setValue('post_id', watch('search').value)
        }
    }, [watch('search')])

    useEffect(() => {
        setValue('post_id', '')
        if (watch('is_custom') === true) setValue('link', '')
    }, [watch('is_custom')])

    return (
        <Modal
            {...props}
            size={'lg'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {action_title}
                </Modal.Title>
                <span className='btn' onClick={props.onHide}>&times;</span>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <TextInput
                        validations={{ required: true }}
                        layout="horizontal"
                        label="Nama"
                        type="text"
                        name="name"
                        placeholder="Nama Menu"
                        register={register}
                        errors={formState.errors}
                    />
                    <TextInput
                        layout="horizontal"
                        label="Custom link"
                        type="switch"
                        name="is_custom"
                        register={register}
                        errors={formState.errors}
                    />
                    {watch('is_custom') === true ? "" :
                        <Controller
                            name={'search'}
                            control={control}
                            render={({ field }) =>

                                <TextInput
                                    layout="horizontal"
                                    name={'search'}
                                    label={'Cari kontent'}
                                    register={register}
                                    errors={formState.errors}
                                    type="select"
                                    field={field}
                                    isMulti={false}

                                    formatOptionLabel={formatOptionLabel}
                                    options={contentOptions}
                                />
                            }
                        />
                    }

                    <TextInput
                        readOnly={watch('is_custom') !== true}
                        layout="horizontal"
                        label="Link"
                        type="text"
                        name="link"
                        placeholder="Link"
                        register={register}
                        errors={formState.errors}
                        validations={{ required: true }}
                    />
                    <TextInput
                        hidden
                        layout="horizontal"
                        label="post_id"
                        type="text"
                        name="post_id"
                        defaultValue={watch('post_id')}
                        register={register}
                        errors={formState.errors}
                    />
                    <TextInput
                        hidden
                        layout="horizontal"
                        label="type"
                        type="text"
                        name="type"
                        defaultValue={type}
                        register={register}
                        errors={formState.errors}
                    />
                    <hr />
                    <button type="submit" className="btn btn-primary btn-md">Simpan</button>
                </form>
            </Modal.Body>
            {/* <Modal.Footer>
            </Modal.Footer> */}
        </Modal>
    )
}

export default MenuForm
