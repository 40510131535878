import { Button } from 'bootstrap'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import CardBasic from '../../../../../components/Cards/Basic'
import Progressbar from '../../../../../components/Progressbar'
import SiaHeader from '../../../../../components/SiaHeader'
import TextInput from '../../../../../components/TextInput'
import { notify } from '../../../../../constants/utility'
import { collapseSidebar, setMenuActive } from '../../../../../redux/actions'
import { getTeacherOne, postCreateTeacher, putUpdateTeacher, setGetTeacher, setTeacherCreate, setTeacherUpdate } from '../../../../../redux/actions/sia-master/teacher'
import { AuthWrapper } from '../../../../AuthWrapper'

const TeacherMasterForm = (props) => {
    const { name, text, path, title, parent } = props.data
    const { loading, teacher, teacherCreate, teacherUpdate } = useSelector((state) => state.teacher);
    let back = path.replace(`/${path.split("/")[path.split("/").length - 1]}`, "")
    if (back.includes('/edit')) back = back.replace('/edit', '')

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { register, handleSubmit, watch, formState, setValue, control } = useForm()

    const [religionOption, setReligionOption] = useState([
        { value: 'islam', label: 'Islam' },
        { value: 'katholik', label: 'Krinten Katholik' },
        { value: 'protestan', label: 'Kristen Protestan' },
        { value: 'hindu', label: 'Hindu' },
        { value: 'budha', label: 'Budha' },
        { value: 'konghucu', label: 'Konghucu' },
    ])

    const [employeeOption, setEmployeeOption] = useState([
        { value: 'III/a', label: 'III/a' },
        { value: 'III/b', label: 'III/b' },
        { value: 'III/c', label: 'III/c' },
        { value: 'III/d', label: 'III/d' },
        { value: 'IV/a', label: 'IV/a' },
        { value: 'IV/b', label: 'IV/b' },
        { value: 'IV/c', label: 'IV/c' },
        { value: 'IV/d', label: 'IV/d' },
        { value: 'IV/e', label: 'IV/e' },
    ])

    const [educationOption, setEducationOption] = useState([
        { value: 'sma', label: 'SMA' },
        { value: 's1', label: 'Sarjana 1' },
        { value: 's2', label: 'Sarjana 2' },
        { value: 's3', label: 'Sarjana 3' },

    ])

    useEffect(() => {
        dispatch(setMenuActive({ menuActive: 'sia', subMenuActive: name }))
        dispatch(collapseSidebar())
        dispatch(setGetTeacher(null))

    }, [])

    const { id } = useParams()

    useEffect(() => {
        if (id) {
            dispatch(getTeacherOne(id))
        }
    }, [id])

    useEffect(() => {
        setValue('name', teacher?.result?.name || '')
        setValue('gender', teacher?.result?.gender || '')
        setValue('place_birth', teacher?.result?.place_birth || '')
        setValue('day_birth', teacher?.result?.day_birth || '')
        setValue('address', teacher?.result?.address || '')
        setValue('email', teacher?.result?.email || '')
        setValue('phone', teacher?.result?.phone || '')
        setValue('nip', teacher?.result?.nip || '')
        setValue('position', teacher?.result?.position || '')
        setValue('entry_year', teacher?.result?.entry_year || '')

        let religion = religionOption.filter(item => item.value === teacher?.result?.religion)
        let employee = employeeOption.filter(item => item.value === teacher?.result?.employee_group)
        let education = educationOption.filter(item => item.value === teacher?.result?.last_education)

        console.log('awowowo', education)
        setValue('religion', religion?.[0] || '')
        setValue('employee_group', employee?.[0] || '')
        setValue('last_education', education?.[0] || '')

    }, [teacher])

    useEffect(() => {
        if (teacherCreate) {
            notify(teacherCreate.message, teacherCreate.success ? 'success' : 'error')
            navigate("/sia/master/teacher/edit/" + teacherCreate.result?.id, { replace: true })
        }

        return () => {
            dispatch(setTeacherCreate(null))
        }
    }, [teacherCreate])

    useEffect(() => {
        if (teacherUpdate) {
            notify(teacherUpdate.message, teacherUpdate.success ? 'success' : 'error')
            dispatch(getTeacherOne(id))
            dispatch(setTeacherUpdate(null))
        }

    }, [teacherUpdate])

    const submitForm = (data) => {
        data.religion = data.religion.value
        data.last_education = data.last_education.value
        data.employee_group = data.employee_group.value
        if (loading === true) return;
        if (id) dispatch(putUpdateTeacher(id, data))
        else dispatch(postCreateTeacher(data))
    }

    return (
        <div>
            <Progressbar loading={loading} />
            <SiaHeader />
            <div className="mt-2"></div>
            <CardBasic title={title} nopadding goBack={back}>
                <div className="p-4">
                    <form onSubmit={handleSubmit(submitForm)} >
                        <div className="row">
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Nama"
                                    type="text"
                                    name="name"
                                    placeholder="Nama"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Jenis Kelamin"
                                    type="radio"
                                    name="gender"
                                    placeholder="Jenis Kelamin"
                                    defaultValue={watch('gender')}
                                    register={register}
                                    options={[
                                        { value: 'L', label: 'Laki-laki' },
                                        { value: 'P', label: 'Perempuan' },
                                    ]}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Tempat Lahir"
                                    type="text"
                                    name="place_birth"
                                    placeholder="Tempat Lahir"
                                    register={register}

                                    errors={formState.errors}
                                />

                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Tanggal Lahir"
                                    type="date"
                                    name="day_birth"
                                    placeholder="Tanggal Lahir"
                                    register={register}
                                    errors={formState.errors}
                                />

                                <Controller
                                    name="religion"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            defaultValue={teacher?.result?.code}
                                            validations={{ required: 'Harus diisi' }}
                                            layout="horizontal"
                                            label="Agama"
                                            type="select"
                                            // name="code"
                                            placeholder="Agama"
                                            register={register}
                                            field={field}
                                            errors={formState.errors}
                                            options={religionOption}
                                        />
                                    }
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Alamat"
                                    type="textarea"
                                    name="address"
                                    placeholder="Alamat lengkap"
                                    register={register}
                                    errors={formState.errors}
                                />

                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Email"
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="No Telpon"
                                    type="text"
                                    name="phone"
                                    placeholder="No Telpon"
                                    register={register}
                                    errors={formState.errors}
                                />

                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="NIP"
                                    type="text"
                                    name="nip"
                                    placeholder="NIP"
                                    register={register}

                                    errors={formState.errors}
                                />
                                <Controller
                                    name="employee_group"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Golongan"
                                            type="select"
                                            field={field}
                                            placeholder="Golongan"
                                            register={register}
                                            options={employeeOption}
                                            errors={formState.errors}
                                        />
                                    }
                                />
                                <TextInput
                                    layout="horizontal"
                                    label="Posisi/Jabatan"
                                    type="text"
                                    name="position"
                                    placeholder="Posisi/Jabatan"
                                    register={register}
                                    errors={formState.errors}
                                />
                                <Controller
                                    name="last_education"
                                    control={control}
                                    render={({ field }) =>
                                        <TextInput
                                            layout="horizontal"
                                            label="Pendidikan"
                                            type="select"
                                            field={field}
                                            placeholder="Pendidikan"
                                            register={register}
                                            options={educationOption}
                                            errors={formState.errors}
                                        />
                                    }
                                />
                                <TextInput
                                    validations={{ required: 'Harus diisi' }}
                                    layout="horizontal"
                                    label="Tanggal Masuk"
                                    type="date"
                                    name="entry_year"
                                    placeholder="Tanggal Masuk"
                                    register={register}
                                    errors={formState.errors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <hr />
                                <button type="submit" className="btn btn-primary btn-md">
                                    Simpan
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </CardBasic>

        </div>
    )
}

export default AuthWrapper(TeacherMasterForm)
