import * as actionTypes from '../../../../constants/actionTypes';
import http from "../../../../services/NetworkService";

export const setOnloading = (payload) => {
    return {
        type: actionTypes.ON_LOADING,
        data: payload,
    };
};

export const setGetUser = (payload) => {
    return {
        type: actionTypes.GET_USER,
        data: payload,
    };
};

export const getUserOne = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.get(`/master/user/` + data);
        dispatch(setGetUser(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setGetUsers = (payload) => {
    return {
        type: actionTypes.GET_USERS,
        data: payload,
    };
};

export const getUserAll = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const params = {
            page: data?.page || 1,
            page_size: data?.page_size || 10,
            search: data?.search,
            orderby: data?.sort?.orderby || null,
            order: data?.sort?.order || null,
        };

        const result = await http.get(`/master/user`, { params });
        dispatch(setGetUsers(result))
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setUserCreate = (payload) => {
    return {
        type: actionTypes.USER_CREATE,
        data: payload,
    };
}

export const postCreateUser = (data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.post(`/master/user`, data);
        dispatch(setOnloading(null))
        dispatch(setUserCreate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setUserUpdate = (payload) => {
    return {
        type: actionTypes.USER_UPDATE,
        data: payload,
    };
}

export const putUpdateUser = (id, data) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.put(`/master/user/${id}`, data);
        dispatch(setOnloading(null))
        dispatch(setUserUpdate(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}


export const setUserDelete = (payload) => {
    return {
        type: actionTypes.USER_DELETE,
        data: payload,
    };
}

export const deleteUser = (id) => async (dispatch) => {
    try {
        dispatch(setOnloading(true))
        const result = await http.delete(`/master/user/${id}`);
        dispatch(setOnloading(null))
        dispatch(setUserDelete(result))

    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPostImportCsv = (payload) => {
    return {
        type: actionTypes.POST_IMPORT_CSV,
        data: payload,
    };
};


export const postImportCsv = (data) => async (dispatch) => {
    try {

        dispatch(setOnloading(true))
        const result = await http.post(`/master/user-import`, data);
        console.log("aaww", result)
        dispatch(setPostImportCsv(result));
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}

export const setPatchResetPassword = (payload) => {
    return {
        type: actionTypes.RESET_PASSWORD,
        data: payload,
    };
};


export const patchResetPassword = (data) => async (dispatch) => {
    try {

        dispatch(setOnloading(true))
        const result = await http.patch(`/master/user-reset-password/${data}`,);
        dispatch(setPatchResetPassword(result));
        dispatch(setOnloading(null))
    } catch (err) {
        console.error(err);
        dispatch(setOnloading(null))
    }
}
