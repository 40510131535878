import { updateObject } from "../../../../constants/utility";
import * as actionTypes from "../../../../constants/actionTypes";

const initialState = {
    loading: null,
    userCreate: null,
    userUpdate: null,
    userDelete: null,
    user: null,
    users: null,
    importCsv: null,
    resetPasswrord: null
};

const loading = (state, data) => {
    return updateObject(state, {
        loading: data
    })
}


const userCreate = (state, data) => {
    return updateObject(state, {
        userCreate: data,
    });
}

const getUser = (state, data) => {
    return updateObject(state, {
        user: data,
    });
}

const getUsers = (state, data) => {
    return updateObject(state, {
        users: data,
    });
}

const userUpdate = (state, data) => {
    return updateObject(state, {
        userUpdate: data,
    });
}

const userDelete = (state, data) => {
    return updateObject(state, {
        userDelete: data,
    });
}

const setImportCsv = (state, data) => {
    return updateObject(state, {
        importCsv: data,
    });
}

const setResetPassword = (state, data) => {
    return updateObject(state, {
        resetPassword: data,
    });
}


const reducer = (state = initialState, action) => {
    const { data } = action;
    switch (action.type) {
        case actionTypes.RESET_PASSWORD:
            return setResetPassword(state, data);
        case actionTypes.POST_IMPORT_CSV:
            return setImportCsv(state, data);
        case actionTypes.USER_DELETE:
            return userDelete(state, data);
        case actionTypes.USER_UPDATE:
            return userUpdate(state, data);
        case actionTypes.GET_USERS:
            return getUsers(state, data);
        case actionTypes.GET_USER:
            return getUser(state, data);
        case actionTypes.USER_CREATE:
            return userCreate(state, data);
        case actionTypes.ON_LOADING:
            return loading(state, data);
        default:
            return state;
    }
};

export default reducer;
